<template>
  <md-card-content>
    <b-alert
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
    <div class="md-layout">
      <div class="md-layout-item md-size-33">
        <md-field>
          <label class="model-select-label">Bank Name</label>
          <model-select
            :options="bankNameList"
            v-model="bankName"
            :isDisabled="!editMode"
          ></model-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Branch Name</label>
          <md-input
            type="text"
            v-model="branchName"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="model-select-label">Account Type</label>
          <model-select
            :options="accountTypeList"
            v-model="accountType"
            :isDisabled="!editMode"
          ></model-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Account Number</label>
          <md-input
            type="text"
            v-model="accountNo"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>IFSC Code</label>
          <md-input
            type="text"
            v-model="ifscCode"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>SWIFT Code</label>
          <md-input
            type="text"
            v-model="swiftCode"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-checkbox
          v-model="active"
          :value="true"
          :unchecked-value="false"
          :disabled="!editMode"
          >Active</md-checkbox
        >
      </div>
      <div
        class="md-layout-item md-small-size-100 md-size-33"
        style="margin-top: 5px"
      >
        <b-row>
          <md-button
            class="md-raised md-info search-btn"
            v-if="showNextButton"
            @click="editButton()"
            >{{ !addFlag ? "edit" : "next" }}</md-button
          >
          <md-button
            style="margin-left: 10px"
            class="md-raised md-info search-btn"
            v-if="showUploadButton"
            @click="uploadButton()"
            >Upload</md-button
          >
          <md-button
            style="margin-left: 10px"
            class="md-raised md-info search-btn"
            v-if="showSaveButton && !addFlag"
            @click="saveButton()"
            >Save</md-button
          >
        </b-row>
      </div>
    </div>
    <b-modal
      v-model="showHistory"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideHistory(false, null)"
    >
      <uploadDocument
        :openKmAuth="openKmAuth"
        @closeOpenKmModal="showHideHistory(false, null)"
        :propMenuId="menuId"
        :PropSubMenuId="subMenuId"
        :wfHdrId="ppId"
        :selectedInvoiceData="selectedInvoiceData"
        @updateStatusFlag="changeUploadFlag"
      ></uploadDocument>
    </b-modal>
  </md-card-content>
</template>
<script>
import { ModelSelect } from "vue-search-select";
import appStrings from "../../../../utility/string.utility";
import store from "../../../../store";
import uploadDocument from "../../../uploadDocument.vue";
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  components: {
    ModelSelect,
    uploadDocument,
  },
  watch: {
    ppBankId: function() {
      this.ppId = this.ppBankId;
    },
  },
  name: "addBank",
  props: ["bankDetails", "ppBankId", "recordType"],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      bankName: {
        value: null,
        text: null,
      },
      bankNameList: [],
      accountType: {
        value: null,
        text: null,
      },
      accountTypeList: [],
      accountNo: null,
      branchName: null,
      ifscCode: null,
      swiftCode: null,
      ppPartyBankId: 0,
      partyBankId: null,
      active: false,
      editMode: false,
      isRecordSaved: false,
      addFlag: false,
      openKmAuth: null,
      showHistory: false,
      ppId: null,
      menuId: 326,
      subMenuId: null,
      selectedInvoiceData: null,
      uploadStatusFlag: false,
      showUploadButton: false,
      showSaveButton: false,
      showNextButton: true,
      taxRecordType: null,
    };
  },
  mounted() {
    this.taxRecordType = this.recordType;
    if (this.bankDetails) {
      this.fillBankDetails(this.bankDetails);
    } else {
      // this.ppId = this.ppBankId;
      this.editMode = true;
      this.addFlag = true;
    }
    this.getBankNameValueSet();
    this.getAccountTypeValueSet();
    this.getSubMenuById();
  },
  methods: {
    fillBankDetails(item) {
      this.accountType = {
        value: item.bank_acc_type_vset,
        text: item.account_type,
      };
      this.accountNo = item.account_num;
      this.branchName = item.bank_branch;
      this.ifscCode = item.ifsc_code;
      this.swiftCode = item.swift_code;
      this.ppPartyBankId = item.pp_party_bank_id;
      this.partyBankId = item.party_bank_id;
      this.bankName = {
        value: item.bank_vset,
        text: item.bank_name,
      };
      this.active = item.active;
      this.ppId = item.pp_party_bank_id;
    },
    editButton() {
      if (this.taxRecordType === "exist" || this.taxRecordType === "new") {
        this.isRecordSaved = true;
        this.addEditBank();
      } else {
        this.editMode = true;
        this.showNextButton = false;
        this.showUploadButton = true;
      }
      // this.isRecordSaved = true;
      // this.addEditBank();
      // if (this.bankDetails) {
      //   this.getPpIdBankByDetails();
      // }
    },
    saveButton() {
      this.isRecordSaved = false;
      this.addEditBank();
    },
    uploadButton() {
      this.getOpenKmAuthenticate();
    },
    addEditBank() {
      const payload = {
        pp_party_bank_id: this.ppPartyBankId,
        party_bank_id: this.partyBankId,
        party_id: store.state.auth.partyId,
        bank_vset: this.bankName.value,
        bank_branch: this.branchName,
        account_num: this.accountNo,
        bank_acc_type_vset: this.accountType.value,
        ifsc_code: this.ifscCode,
        swift_code: this.swiftCode,
        active: this.active,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditBank", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            if (!this.showSaveButton) {
              this.showUploadButton = true;
            }
            this.showNextButton = false;
            if (this.isRecordSaved) {
              this.editMode = true;
            } else {
              this.editMode = false;
            }
            this.isSuccess = true;
            if (!this.editMode) {
              this.showAlert = true;
            } else {
              this.showAlert = false;
            }
            this.responseMsg = response.data.message;
            if (!this.ppPartyBankId) {
              this.ppPartyBankId = this.ppBankId;
            }
            const uploadEnableFlag = this.showSaveButton ? false : true;
            this.$emit("updatedBankResponse", uploadEnableFlag);
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .then(() => {
          if (this.bankDetails) {
            this.getPpIdBankByDetails();
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getBankNameValueSet() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.BANK_LIST,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.bankNameList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getAccountTypeValueSet() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.BANK_ACC_TYPE,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.accountTypeList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getOpenKmAuthenticate() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.subMenuId,
        record_id: this.ppId,
      };
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getPpIdBankByDetails() {
      const payload = {
        party_bank_id: this.bankDetails.party_bank_id,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getPpIdBankByDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.ppId = response.data.data.pp_id;
            this.$emit("deleteBankById", this.ppId);
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    changeUploadFlag(value) {
      this.uploadStatusFlag = value;
      this.showSaveButton = true;
      this.showUploadButton = false;
      this.$emit("saveButtonCloseModal");
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_drawee_bank_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
