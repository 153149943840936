<template>
  <div>
    <div>
      <!-- <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      /> -->
      <router-link
        to="/dashboard"
        v-if="$route.name !== 'Dashboard' || !vendorFlag"
      >
        <div
          class="letsTalkButton"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          title="Return to Dashboard"
        >
          <div id="telephoneIcon">
            <md-icon style="color: white">menu</md-icon>
          </div>
        </div>
      </router-link>
    </div>
    <div
      class="sidebar"
      :data-color="activeColor"
      :data-image="sidebarBackgroundImage"
      :data-background-color="backgroundColor"
      :style="sidebarStyle"
    >
      <div class="logo">
        <a class="simple-text logo-mini" target="_blank">
          <div style="padding: 4px 0">DL</div>
        </a>
        <a class="simple-text logo-normal" target="_blank">
          <template v-if="$route.meta.rtlActive">DELHIVERY</template>
          <template v-else>{{ title }}</template>
        </a>
        <div class="navbar-minimize">
          <md-button
            id="minimizeSidebar"
            class="md-round md-just-icon md-transparent"
            @click="minimizeSidebar"
          >
            <i
              class="material-icons text_align-center visible-on-sidebar-regular"
              >more_vert</i
            >
            <i
              class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
              >view_list</i
            >
          </md-button>
        </div>
      </div>
      <div class="sidebar-wrapper" ref="sidebarScrollArea">
        <slot></slot>
        <md-list
          class="nav"
          v-if="
            !$store.state.auth.isNewVendor ||
              $store.state.auth.isNewVendor == 'false'
          "
        >
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </md-list>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Delhivery",
    },
    rtlTitle: {
      type: String,
      default: "توقيت الإبداعية",
    },
    activeColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = [
          "",
          "purple",
          "azure",
          "green",
          "orange",
          "danger",
          "rose",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarBackgroundImage: {
      type: String,
      default: require("@/assets/img/sidebar-2.jpg"),
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/vue-logo.png"),
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URL}/img/vue-logo.png`,
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$sidebar.toggleMinimize();
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  mounted() {
    this.minimizeSidebar();
    // if (this.$route.name == "Change Password") {
    //   this.vendorFlag = sessionStorage.isNewVendor;
    // }
  },
  methods: {
    vendorFlag() {
      if (this.$route.name == "Change Password") {
        return sessionStorage.isNewVendor;
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`,
      };
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.letsTalkButton {
  width: 15px;
  height: 50px;
  position: fixed;
  top: 75%;
  right: 0;
  background-color: black;
  z-index: 200;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: #003145 4px 4px 4px;
  padding: 10px;
  transition: width 0.7s ease;
}
.letsTalkButton:hover {
  width: 45px;
  cursor: pointer;
}
</style>
