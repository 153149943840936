import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
};

const mutations = {};

const actions = {
  billInfoData(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerBillInfoUrl, payload);
  },
  receiptInfoData(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerReceiptUrl, payload);
  },
  getFmsUnitData(context, payload) {
    return http.getApi(URL_UTILITY.getFmsUnitDetailsGridDataUrl, payload);
  },
  getLeaseUnitData(context, payload) {
    return http.getApi(URL_UTILITY.getLeaseUnitGridDataUrl, payload);
  },
  getCustomerInvoiceDetails(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerInvoiceDetailsUrl, payload);
  },
  getCustomer() {
    return http.getApi(URL_UTILITY.getCustomerUrl);
  },
  generateCustomerData(context, payload) {
    return http.postApi(URL_UTILITY.generateCustomerDataUrl, payload);
  },
  getCustomerRequestId() {
    return http.getApi(URL_UTILITY.getCustomerLedgerUrl + "/requestId");
  },
  getCustomerTemplateId() {
    return http.getApi(URL_UTILITY.getCustomerLedgerUrl + "/templateId");
  },
  getCustomerLedgerHdr(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerLedgerHdrUrl, payload);
  },
  getCustomerLedgerDetails(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerLedgerDetailsUrl, payload);
  },
  getSubmitRequest(context, requestNum) {
    return http.getApi(URL_UTILITY.getSubmitRequestUrl + "/" + requestNum);
  },
  getCustomerDetails(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerDetailsUrl, payload);
  },
  getBillInfoRequestId() {
    return http.getApi(URL_UTILITY.getBillInfoUrl + "/requestId");
  },
  getBillInfoTemplateId() {
    return http.getApi(URL_UTILITY.getBillInfoUrl + "/templateId");
  },
};

const getters = {};

const customer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default customer;
