<template>
  <div style="background-color: lightgrey">
    <md-card>
      <b-alert
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert && !partyDetailsVisible"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}
      </b-alert>
      <md-card-header
        v-if="!partyDetailsVisible && $route.name !== componentName"
        class="md-card-header-text md-layout-item md-card-header-green"
        style="margin-top: 8px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 180px; padding: 3px !important"
        >
          <h5 class="title">Existing Details</h5>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field
              v-if="
                $route.name === 'Dashboard' ||
                  $route.name === componentName ||
                  updatedDetails.approval_status !== null
              "
            >
              <label>Party Type</label>
              <md-input type="text" disabled v-model="partyInfo.party_type">
              </md-input>
            </md-field>
            <md-field v-else>
              <label class="model-select-label">Party Type</label>
              <basic-select
                @select="selectedPartyType($event, 'exist')"
                :selectedOption="partyType"
                :options="partyTypeList"
              >
              </basic-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field
              v-if="
                $route.name === 'Dashboard' ||
                  $route.name === componentName ||
                  updatedDetails.approval_status !== null
              "
            >
              <label>Party Category</label>
              <md-input type="text" disabled v-model="partyInfo.party_cat">
              </md-input>
            </md-field>
            <md-field v-else>
              <label class="model-select-label">Party Category</label>
              <model-select
                v-model="partyCategory"
                :options="partyCategoryList"
              >
              </model-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>Party Name</label>
              <md-input
                type="text"
                v-model="partyInfo.party_name"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>PAN No.</label>
              <md-input
                type="text"
                v-model="partyInfo.pan_no"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field
              v-if="
                $route.name === 'Dashboard' ||
                  $route.name === componentName ||
                  updatedDetails.approval_status !== null
              "
            >
              <label>Salutation</label>
              <md-input type="text" disabled v-model="partyInfo.salutation">
              </md-input>
            </md-field>
            <md-field v-else>
              <label class="model-select-label">Salutation</label>
              <model-select
                :options="salutationList"
                v-model="existSalutation"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></model-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>First Name</label>
              <md-input
                type="text"
                v-model="partyInfo.first_name"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>Middle Name</label>
              <md-input
                type="text"
                v-model="partyInfo.middle_name"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>Last Name</label>
              <md-input
                type="text"
                v-model="partyInfo.last_name"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-25">
            <md-field
              v-if="
                $route.name === 'Dashboard' ||
                  $route.name === componentName ||
                  updatedDetails.approval_status !== null
              "
            >
              <label>DOB</label>
              <md-input
                type="text"
                disabled
                v-model="partyInfo.formatted_date_of_birth"
              >
              </md-input>
            </md-field>

            <md-datepicker
              v-else
              md-immediately
              v-model="partyInfo.date_of_birth"
            >
              <label>DOB</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>Aadhaar </label>
              <md-input
                type="text"
                v-model="partyInfo.aadhar_no"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-field>
              <label>CIN No.</label>
              <md-input
                type="text"
                v-model="partyInfo.cin_no"
                :disabled="
                  $route.name === 'Dashboard' ||
                    $route.name === componentName ||
                    updatedDetails.approval_status !== null
                "
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-checkbox
              v-model="partyInfo.is_foreign"
              :value="true"
              :unchecked-value="false"
              :disabled="
                $route.name === 'Dashboard' ||
                  updatedDetails.approval_status !== null
              "
              class="md-danger"
              >NRI</md-checkbox
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-100">
            <div style="margin-top: -5px">
              <!-- v-if="!updatedDetails.approval_status" -->
              <md-button
                v-if="
                  !partyDetailsVisible &&
                    $route.name !== componentName &&
                    showUpdateButton
                "
                class="md-raised md-danger search-btn"
                @click="updateButton()"
                >{{
                  partyInfo.pp_approval_status === "DRAFT" ? "Submit" : "Update"
                }}</md-button
              >
              <md-button
                style="margin-left: 10px"
                class="md-raised md-danger search-btn"
                v-if="showUploadButton"
                @click="uploadButton()"
                >Upload</md-button
              >
              <md-button
                style="margin-left: 10px"
                class="md-raised md-danger search-btn"
                v-if="showSaveButton"
                @click="saveButton()"
                >Save</md-button
              >
            </div>
          </div>
        </div>
        <b-modal
          v-model="showHistory"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
          size="xl"
          title="Upload Document"
          @close="closeHandleUploadDocument"
        >
          <uploadDocument
            :openKmAuth="openKmAuth"
            @closeOpenKmModal="showHideHistory(false, null)"
            :propMenuId="menuId"
            :PropSubMenuId="subMenuId"
            :wfHdrId="ppId"
            :selectedInvoiceData="selectedInvoiceData"
            @updateStatusFlag="changeUploadFlag"
            :documentFlag="documentFlag"
            @uploadedDocLists="uploadedDocLists"
            @deletePartyDetailsById="setPpIdPartyDetails"
            @saveButtonCloseModal="uploadEnabled = false"
          ></uploadDocument>
        </b-modal>
      </md-card-content>
    </md-card>
    <md-card v-if="!partyDetailsVisible && $route.name !== componentName">
      <md-card-header
        class="md-card-header-text md-layout-item md-card-header-blue"
        style="margin-top: 8px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 180px; padding: 3px !important"
        >
          <h5 class="title">Updated Details</h5>
        </div>
      </md-card-header>
      <div class="md-layout" style="margin-top: 5px">
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field v-if="updatedDetails.pp_approval_status === 'APPROVED'">
            <label>Party Type</label>
            <md-input type="text" disabled v-model="updatedDetails.party_type">
            </md-input>
          </md-field>
          <md-field v-else>
            <label class="model-select-label">Party Type</label>
            <basic-select
              @select="selectedUpdatePartyType($event, 'update')"
              :selectedOption="updatePartyType"
              :options="partyTypeList"
            >
            </basic-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field v-if="updatedDetails.pp_approval_status === 'APPROVED'">
            <label>Party Category</label>
            <md-input type="text" disabled v-model="updatedDetails.party_cat">
            </md-input>
          </md-field>
          <md-field v-else>
            <label class="model-select-label">Party Category</label>
            <model-select
              v-model="updatePartyCategory"
              :options="updatePartyCategoryList"
            >
            </model-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>Party Name</label>
            <md-input
              type="text"
              v-model="updatedDetails.party_name"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>PAN No.</label>
            <md-input type="text" v-model="updatedDetails.pan_no"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field v-if="updatedDetails.pp_approval_status === 'APPROVED'">
            <label>Salutation</label>
            <md-input type="text" disabled v-model="updatedDetails.salutation">
            </md-input>
          </md-field>
          <md-field v-else>
            <label class="model-select-label">Salutation</label>
            <model-select
              :options="salutationList"
              v-model="updateSalutation"
            ></model-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>First Name</label>
            <md-input
              type="text"
              v-model="updatedDetails.first_name"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>Middle Name</label>
            <md-input
              type="text"
              v-model="updatedDetails.middle_name"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>Last Name</label>
            <md-input type="text" v-model="updatedDetails.last_name"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-datepicker v-model="updatedDetails.dob">
            <label>DOB</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>Aadhaar </label>
            <md-input type="text" v-model="updatedDetails.aadhar_no"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>CIN No.</label>
            <md-input type="text" v-model="updatedDetails.cin_no"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-field>
            <label>Approval Status </label>
            <md-input
              type="text"
              v-model="updatedDetails.approval_status"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <md-checkbox
            v-model="updatedDetails.is_foreign"
            :value="true"
            :unchecked-value="false"
            class="md-danger"
            >NRI</md-checkbox
          >
        </div>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <div style="margin-top: 5px" v-if="updatedDetails.approval_status">
            <md-button
              class="md-raised md-danger search-btn"
              v-if="showUpdateDetailButton"
              @click="updatedUpdateButton()"
              >{{
                updatedDetails.pp_approval_status === "DRAFT"
                  ? "Submit"
                  : "Update"
              }}</md-button
            >
            <md-button
              style="margin-left: 10px"
              class="md-raised md-danger search-btn"
              v-if="showUpdateUploadButton"
              @click="updatedUploadButton()"
              >Upload</md-button
            >
            <md-button
              style="margin-left: 10px"
              class="md-raised md-danger search-btn"
              v-if="showUpdateSaveButton"
              @click="updatedSaveButton()"
              >Save</md-button
            >
          </div>
        </div>
      </div>
    </md-card>
  </div>
</template>
<script>
import { BasicSelect, ModelSelect } from "vue-search-select";
import appStrings from "../../../../utility/string.utility";
import commonHelper from "../../../../utility/common.helper.utility";
import uploadDocument from "../../../uploadDocument.vue";
export default {
  components: {
    BasicSelect,
    ModelSelect,
    uploadDocument,
  },
  name: "partyDetails",
  props: ["partyDetailsVisible"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      partyInfo: {
        salutation: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        party_type: null,
        party_type_vset: null,
        party_id: null,
        cin_no: null,
        employee_id: null,
        employee_name: null,
        is_foreign: false,
        date_of_birth: null,
        party_cat: null,
        party_cat_vset: null,
        party_name: null,
        short_name: null,
        pan_no: null,
        aadhar_no: null,
        date_of_incorporation: null,
        salutation_lookup: null,
        salutation_lookup_meaning: null,
        latest_lud: null,
        pp_approval_status: null,
        pp_party_id: null,
        formatted_date_of_birth: null,
      },
      updatedDetails: {
        salutation: null,
        salutation_lookup: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        salutation_lookup_meaning: null,
        party_type_vset: null,
        party_type_vset_meaning: null,
        party_cat_vset: null,
        party_cat_vset_meaning: null,
        pp_rejected_reason: null,
        pp_posting_status: null,
        approval_status: null,
        date_of_incorporation: null,
        party_name: null,
        pan_no: null,
        party_cat: null,
        pp_approval_date: null,
        employee_name: null,
        aadhar_no: null,
        short_name: null,
        dob: null,
        is_foreign: false,
        cin_no: null,
        party_lud: null,
        latest_lud: null,
        vendor_lud: null,
        customer_lud: null,
        pp_party_id: null,
        party_id: null,
      },
      componentName: "Party Details",
      existSalutation: {
        value: null,
        text: null,
      },
      salutationList: [],
      updateSalutation: {
        value: null,
        text: null,
      },
      partyTypeData: [],
      partyType: {
        value: null,
        text: null,
      },
      partyTypeList: [],
      partyCategory: {
        value: null,
        text: null,
      },
      partyCategoryList: [],
      updatePartyTypeData: [],
      updatePartyType: {
        value: null,
        text: null,
      },
      updatePartyTypeList: [],
      updatePartyCategory: {
        value: null,
        text: null,
      },
      updatePartyCategoryList: [],
      existPartyType: null,
      showUploadButton: false,
      showSaveButton: false,
      showUpdateButton: false,
      showUpdateSaveButton: false,
      showUpdateUploadButton: false,
      showUpdateDetailButton: true,
      showHistory: false,
      openKmAuth: null,
      menuId: 326,
      subMenuId: null,
      ppId: null,
      selectedInvoiceData: null,
      uploadStatusFlag: false,
      updateUploadStatusFLag: false,
      documentFlag: false,
      numberOfUpload: 0,
      uploadEnabled: false,
    };
  },
  mounted() {
    this.getPartyTypeValueSet();
    this.getSalutationType();
    this.getSubMenuById();
  },
  methods: {
    getPartyDetails() {
      this.$store
        .dispatch("party/getDetails", "details")
        .then((resp) => {
          if (resp.status === 200) {
            this.partyInfo = resp.data.data.party_details;
            this.partyInfo.formatted_date_of_birth = this.partyInfo.date_of_birth;
            this.partyInfo.date_of_birth = this.partyInfo.date_of_birth
              ? new Date(this.partyInfo.date_of_birth)
              : null;
            this.partyType = {
              value: this.partyInfo.party_type_vset,
              text: this.partyInfo.party_type,
            };
            this.existSalutation = {
              value: this.partyInfo.salutation_lookup,
              text: this.partyInfo.salutation,
            };
            this.getPartyCategory(this.partyType.value);
            this.partyCategory = {
              value: this.partyInfo.party_cat_vset,
              text: this.partyInfo.party_cat,
            };
            this.ppId = this.partyInfo.party_id;
            this.$emit("getDetails", this.partyInfo);
          }
        })
        .then(() => {
          this.getUpdatedPartyDetails();
        });
    },
    getUpdatedPartyDetails() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getUpdatedPartyDetails")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            if (response.data.data !== null) {
              this.showUpdateButton = false;
              this.updatedDetails = response.data.data;
              this.updatedDetails.dob = this.updatedDetails.dob
                ? new Date(this.updatedDetails.dob)
                : null;
              this.updatePartyType = {
                value: this.updatedDetails.party_type_vset,
                text: this.updatedDetails.party_type_vset_meaning,
              };
              this.updateSalutation = {
                value: this.updatedDetails.salutation_lookup,
                text: this.updatedDetails.salutation_lookup_meaning,
              };
              this.getUpdatePartyCategory(this.updatePartyType.value);
              this.updatePartyCategory = {
                value: this.updatedDetails.party_cat_vset,
                text: this.updatedDetails.party_cat_vset_meaning,
              };
            } else {
              this.updatedDetails = {
                salutation: null,
                first_name: null,
                middle_name: null,
                last_name: null,
                salutation_lookup: null,
                salutation_lookup_meaning: null,
                party_type_vset_meaning: null,
                party_cat_vset_meaning: null,
                pp_rejected_reason: null,
                pp_posting_status: null,
                approval_status: null,
                date_of_incorporation: null,
                party_name: null,
                pan_no: null,
                party_cat: null,
                pp_party_id: null,
                pp_approval_date: null,
                employee_name: null,
                aadhar_no: null,
                short_name: null,
                dob: null,
                is_foreign: false,
                cin_no: null,
                party_lud: null,
                latest_lud: null,
                vendor_lud: null,
                customer_lud: null,
              };
              this.showUpdateButton = true;
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    addEditPartyDetails() {
      const payload = {
        pp_party_id: null,
        party_id: this.partyInfo.party_id,
        party_type_vset: this.partyType.value,
        date_of_birth: commonHelper.formattedDate(this.partyInfo.date_of_birth),
        party_cat_vset: this.partyCategory.value,
        // short_name: this.partyInfo.short_name,
        pan_no: this.partyInfo.pan_no,
        aadhar_no: this.partyInfo.aadhar_no,
        cin_no: this.partyInfo.cin_no,
        party_name: this.partyInfo.party_name,
        salutation_lookup: this.existSalutation.value,
        first_name: this.partyInfo.first_name,
        middle_name: this.partyInfo.middle_name,
        last_name: this.partyInfo.last_name,
        is_foreign: this.partyInfo.is_foreign,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditPartyDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            if (!this.showSaveButton) {
              this.showUploadButton = true;
            } else {
              this.showSaveButton = false;
            }
            this.showUpdateButton = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.partyInfo = response.data.data.party_details;
            this.getUpdatedPartyDetails();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
            // const uploadEnableFlag = this.showSaveButton ? false : true;
          }
        })
        .then(() => {
          this.getPpIdPartyDetails();
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditUpdatePartyDetails() {
      const payload = {
        pp_party_id: this.updatedDetails.pp_party_id,
        party_id: this.updatedDetails.party_id,
        party_type_vset: this.updatePartyType.value,
        date_of_birth: commonHelper.formattedDate(this.updatedDetails.dob),
        party_cat_vset: this.updatePartyCategory.value,
        // short_name: this.partyInfo.short_name,
        pan_no: this.updatedDetails.pan_no,
        aadhar_no: this.updatedDetails.aadhar_no,
        cin_no: this.updatedDetails.cin_no,
        party_name: this.updatedDetails.party_name,
        salutation_lookup: this.updateSalutation.value,
        first_name: this.updatedDetails.first_name,
        middle_name: this.updatedDetails.middle_name,
        last_name: this.updatedDetails.last_name,
        is_foreign: this.updatedDetails.is_foreign,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditPartyDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            if (!this.showUpdateSaveButton) {
              this.showUpdateUploadButton = true;
            } else {
              this.showUpdateSaveButton = false;
            }
            this.showUpdateDetailButton = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedDetails = response.data.data;
            this.getUpdatedPartyDetails();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .then(() => {
          this.getPpIdPartyDetails();
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getPartyTypeValueSet() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.PARTY_TYPE,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            this.partyTypeData = results;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.partyTypeList = typeList;
          }
        })
        .then(() => {
          this.getPartyDetails();
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedPartyType(event, detailType) {
      this.existPartyType = detailType;
      this.partyType = event;
      this.getPartyCategory(event.value);
    },
    selectedUpdatePartyType(event, detailType) {
      this.existPartyType = detailType;
      this.updatePartyType = event;
      this.getUpdatePartyCategory(event.value);
    },
    getPartyCategory(partyTypeCode) {
      const partyType = this.partyTypeData.filter(
        (key) => key.value_code === partyTypeCode
      );
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.PARTY_CAT,
        params: {
          parent_value_set_id: partyType[0].value_set_dtl_id,
        },
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.partyCategoryList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getUpdatePartyCategory(partyTypeCode) {
      const updatePartyType = this.partyTypeData.filter(
        (key) => key.value_code === partyTypeCode
      );
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.PARTY_CAT,
        params: {
          parent_value_set_id: updatePartyType[0].value_set_dtl_id,
        },
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.updatePartyCategoryList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getSalutationType() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getLovByLookupType", appStrings.LOOKUPTYPE.SALUTATION)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.lookup_code,
                text: type.meaning,
              };
            });
            this.salutationList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    updateButton() {
      this.documentFlag = true;
      this.isRecordSaved = true;
      this.showUpdateButton = false;
      this.addEditPartyDetails();
    },
    saveButton() {
      this.isRecordSaved = false;
      this.addEditPartyDetails();
    },
    uploadButton() {
      this.getOpenKmAuthenticate();
    },
    updatedUpdateButton() {
      this.documentFlag = true;
      this.isRecordSaved = true;
      this.showUpdateDetailButton = false;
      this.addEditUpdatePartyDetails();
    },
    updatedSaveButton() {
      this.isRecordSaved = false;
      this.addEditUpdatePartyDetails();
    },
    updatedUploadButton() {
      this.getOpenKmAuthenticate();
    },
    getOpenKmAuthenticate() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
      this.uploadEnabled = false;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.subMenuId,
        record_id: this.ppId,
      };
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getPpIdPartyDetails() {
      const payload = {
        party_id: this.partyInfo.party_id,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getPpIdPartyDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.ppId = response.data.data.pp_id;
            this.$emit("deletePartyDetailsById", this.ppId);
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_details_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    deletePartyDetailsById(ppId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/deletePartyDetailsById", ppId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.uploadEnabled = false;
            this.showMoal = false;
          }
        })
        .then(() => this.getUpdatedPartyDetails())
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    changeUploadFlag(value) {
      this.uploadStatusFlag = value;
      this.showSaveButton = false;
      this.showUploadButton = false;
      this.showUpdateUploadButton = false;
      this.showUpdateSaveButton = false;
    },
    setPpIdPartyDetails(value) {
      this.ppId = value;
    },
    closeHandle(bvModalEvent) {
      if (this.uploadEnabled) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deletePartyDetailsById(this.ppId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    closeHandleUploadDocument(bvModalEvent) {
      if (this.numberOfUpload === 0) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deletePartyDetailsById(this.ppId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    uploadedDocLists(item) {
      this.numberOfUpload = item.length;
    },
  },
};
</script>
