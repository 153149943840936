<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start" style="margin-right: 10px">
        <md-button class="md-raised md-danger vendor-btn">{{
          $route.name
        }}</md-button>
      </div>
      <div
        v-if="
          $store.state.auth.vendorPortalAdmin == 'PARTY_REQ_ADMIN' &&
            $route.name == 'Dashboard'
        "
        class="md-toolbar-selection-start"
      >
        <md-button
          class="md-raised md-card-header-purple routeClass vendor-btn"
          @click="routeFunction()"
          >Change Vendor</md-button
        >
      </div>
      <!-- <div v-if="$route.name !== 'Admin Page'"> -->
      <div
        v-if="$route.name !== 'Admin Page'"
        style=" margin-top: 5px; margin-left: 20px;"
      >
        <div class="marque-bg">
          <p style="text-align:center;" class="marque-text">
            Support Email :- vendorportal.support@delhivery.com
          </p>
        </div>
      </div>
      <div
        v-if="$route.name !== 'Admin Page'"
        style="margin-top: 5px; margin-left:2rem;"
      >
        <div class="marque-bg">
          <p style="text-align:center;" class="marque-text">
            Welcome :- <b>{{ partyName }}</b>
          </p>
        </div>
      </div>
      <!-- </div> -->
      <a
        target="_blank"
        class="md-layout-item md-small-size-100 md-size-10"
        v-if="$route.name === 'Party Onboarding'"
        :href="downloadDocUrl + '/' + fileName"
      >
        <md-button class="md-raised md-rose search-btn vendor-btn"
          >Process Manual</md-button
        >
      </a>
      <a
        target="_blank"
        class="md-layout-item md-small-size-100 md-size-10"
        v-if="$route.name === 'Vendor Invoice Entry'"
      >
        <md-button
          class="md-raised md-rose search-btn vendor-btn"
          :href="downloadDocUrl + '/' + invoiceFileName"
          >User Manual</md-button
        >
      </a>
      <router-link
        to="/dashboard"
        v-if="$route.name === 'Vendor Invoice Entry'"
      >
        <div
          class="letsTalk"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          title="Return to Dashboard"
        >
          <div id="telephoneIcon">
            <md-icon style="color: black">home</md-icon>
          </div>
        </div>
      </router-link>
      <div class="md-toolbar-section-end">
        <div class="md-collapse">
          <md-list>
            <md-list-item
              :style="
                $route.name == 'Admin Page' ? 'background-color:white;' : ''
              "
            >
              <router-link style="" to="/settings/change-password">
                <i class="material-icons" title="Change Password">lock</i>
              </router-link>
            </md-list-item>
            <md-list-item
              :style="
                $route.name == 'Admin Page' ? 'background-color:white;' : ''
              "
              @click="logOutUser"
            >
              <i class="material-icons" title="Logout">logout</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import URL_UTILITY from "../../../utility/url.utility";
import appStrings from "../../../utility/string.utility";
export default {
  components: {},
  watch: {},
  data() {
    return {
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
      documentationLink:
        "https://vue-material-dashboard-laravel.creative-tim.com/documentation/",
      downloadUrl:
        "https://www.creative-tim.com/product/vue-material-dashboard-laravel",
      unsubscribe: null,
      loader: false,
      legalEntity: [],
      financialYearList: [],
      finYear: "All",
      leId: "All",
      currentFinYear: null,
      partyName: null,
      showManualModal: false,
      downloadDocUrl: URL_UTILITY.getManualDocumentUrl,
      fileName: appStrings.MANUALFILENAME,
      invoiceFileName: appStrings.INVOICEFILENAME,
    };
  },
  created() {
    this.eventBus.$on("vendorName", (vendorName) => {
      this.partyName = vendorName;
    });
  },
  mounted() {
    if (this.$store.state.auth.partyId) {
      this.partyName = this.getName();
    }
  },
  methods: {
    routeFunction() {
      this.$router.push({ name: "Admin Page" });
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goToNotifications() {
      this.$router.push({ name: "Notifications" });
    },
    logOutUser() {
      this.$store.dispatch("logout");
    },
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.partyName = result.name;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
  beforeDestroy() {
    this.eventBus.$off("vendorName");
  },
};
</script>
<style>
.selected-menu {
  background-color: #fea01c;
  height: 35px !important;
  padding: 10px !important;
  margin-bottom: 5px !important;
  font-weight: 400 !important;
}
.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}
.marque-text {
  color: white;
  margin: 2px;
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
}
.marque-bg {
  background-image: linear-gradient(to right, black, rgb(150, 148, 148));
  width: 25rem !important;
  border-radius: 5px !important;
}
.letsTalk {
  /* width: 15px; */
  /* height: 50px; */
  /* position: fixed; */
  top: 2%;
  /* right: 0; */
  /* background-color: black; */
  z-index: 200;
  border-radius: 8px;
  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
  /* box-shadow: #003145 4px 4px 4px; */
  padding: 5px;
  /* transition: width 0.7s ease; */
}
.routeClass {
  background-color: rgb(80, 80, 158) !important;
}
</style>
