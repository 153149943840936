<template>
  <div class="md-layout"
  >
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-14">
            <md-field>
              <label for="requestType">Module</label>
              <md-select
                v-model="moduleId"
                name="moduleId"
                id="requestType"
                md-dense
              >
                <md-option
                  v-for="(item, index) in moduleList"
                  :key="index"
                  :value="item.module_id"
                  >{{ item.module_name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14">
            <md-field>
              <label>Receipt No.</label>
              <md-input type="text" v-model="receiptNum"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14">
            <md-field>
              <label>Value From</label>
              <md-input type="text" v-model="amountFrom"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14">
            <md-field>
              <label>Value To</label>
              <md-input type="text" v-model="amountTo"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14">
            <md-datepicker md-immediately v-model="dateFrom"
              ><label>From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14">
            <md-datepicker md-immediately v-model="dateTo"
              ><label>To Date</label></md-datepicker
            >
          </div>
          <div class=" md-small-size-100 md-size-20"
          style="margin-left: 10px">
            <div>
              <md-button
                class="md-raised md-success search-btn"
                @click="getReceiptInfo()"
                style="margin-right: 10px "
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
        >
          <div class="card-text profile-card" style="padding: 7px !important">
            <h5 class="title">Receipt</h5>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
          >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import commonHelper from "../../../utility/common.helper.utility";
export default {
  name: "BillInfo",
  watch: {
    currentPage: function () {
      this.getReceiptInfo();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getReceiptInfo();
    },
  },
  data() {
    return {
      moduleId: null,
      receiptNum: null,
      amountFrom: null,
      amountTo: null,
      dateFrom: null,
      dateTo: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      leId: null,
      finYear: null,
      gridData: [],
      gridField: [
        {
          key: "module",
        },
        {
          key: "prj_name",
          label: "Project",
        },
        { key: "receipt_num"},
        { key: "receipt_date"},
        {
          key: "payment_mode_vset_meaning",
          label: "Payment Mode",
        },
        {
          key: "instrument_num",
        },
        {
          key: "currency",
        },
        {
          key: "amount",
        },
        {
          key: "instrument_status_vset_meaning",
          label: "Instrument Status",
        },
        {
          key: "bank_vset_meaning",
          label: "Bank Account",
        }
      ],
      moduleList: [],
      unsubscribe: null,
      currentFinYear: null
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
      this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
      this.currentFinYear = sessionStorage.getItem("currentFinYear")
    ? sessionStorage.getItem("currentFinYear")
    : null;
    this.getModuleList();
    this.getReceiptInfo();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "shared/setLeId" || mutation.type === "shared/setFinYear") {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
        this.getReceiptInfo();
      }
    });
  },
  methods: {
    getModuleList() {
      const payload = {
        _page: 0,
        _limit: 20,
        module_name: "",
        receipt_flag: true,
      };
      this.$store.dispatch("shared/getModuleList", payload).then((resp) => {
        if (resp.status === 200) {
          this.moduleList = resp.data.data.page;
        }
      });
    },
    getReceiptInfo() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.moduleId,
        receipt_no: this.receiptNum,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        amount_from: this.amountFrom,
        amount_to: this.amountTo,
        // date: commonHelper.formattedDate(this.receiptDate),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
      };
      this.gridData = [];
      this.totalRows = 0;
      this.$store.dispatch("shared/setLoader", true);
      this.$store.dispatch("customer/receiptInfoData", payload).then((resp) => {
        this.$store.dispatch("shared/setLoader", false);
        if (resp.status === 200) {
          this.gridData = resp.data.data.page;
          this.totalRows = resp.data.data.total_elements;
        }
      });
    },
    clearSearch() {
      this.moduleId = null;
      this.receiptNum = null;
      this.amountFrom = null;
      this.amountTo = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.gridData = [];
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>