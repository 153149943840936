<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Trx No.</label>
              <md-input type="text" v-model="trx_no"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Value From</label>
              <md-input type="text" v-model="trx_amount_from"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Value To</label>
              <md-input type="text" v-model="trx_amount_to"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="trx_date_from"
              ><label>From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="trx_date_to"
              ><label>To Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-success search-btn"
                @click="getCustomerInvoiceDetails()"
                style="margin-right: 10px"
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-green"
        >
          <div class="card-text profile-card" style="padding: 5px !important">
            <h6 class="title">O/S Info</h6>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
          >
            <template #cell(le_short_name)="data">
              <span :title="data.item.le_full_name">{{
                data.item.le_short_name
              }}</span></template
            >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import commonHelper from "../../../utility/common.helper.utility";
export default {
  name: "CustomerOutstandingInfo",
  watch: {
    currentPage: function () {
      this.getCustomerInvoiceDetails();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getCustomerInvoiceDetails();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      trx_no: null,
      trx_amount_from: null,
      trx_amount_to: null,
      trx_date_from: null,
      trx_date_to: null,
      gridData: [],
      gridField: [
        {
          key: "le_short_name",
          label: "LE",
        },
        {
          key: "trx_no",
          label: "Trx Number",
        },

        { key: "trx_type"},
        { key: "trx_date"},
        { key: "currency"},
        { key: "trx_amount"},
        {
          key: "receipt_amount",
          label: "Receipt",
        },
        {
          key: "tds_receipt_amount",
          label: "TDS"
        },
        {
          key: "adjusment",
        },
        {
          key: "bal",
          label: "Balance",
        },
      ],
      leId: null,
      finYear: null,
      unsubscribe: null,
      currentFinYear: null
      
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
      this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.currentFinYear = sessionStorage.getItem("currentFinYear")
    ? sessionStorage.getItem("currentFinYear")
    : null;
    this.getCustomerInvoiceDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "shared/setLeId" || mutation.type === "shared/setFinYear") {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
        this.getCustomerInvoiceDetails();
      }
    });
  },
  methods: {
    getCustomerInvoiceDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        trx_amount_from: this.trx_amount_from,
        trx_amount_to: this.trx_amount_to,
        trx_date_from: commonHelper.formattedDate(this.trx_date_from),
        trx_date_to: commonHelper.formattedDate(this.trx_date_to),
        trx_no: this.trx_no,
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
      };
      this.gridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getCustomerInvoiceDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        });
    },
    clearSearch() {
      this.trx_no = null;
      this.trx_amount_from = null;
      this.trx_amount_to = null;
      this.trx_date_from = null;
      this.trx_date_to = null;
      this.getCustomerInvoiceDetails();
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style>
.md-size-14 {
  min-width: 14% !important;
  max-width: 10% !important;
  flex: 0 1 10%;
}
.search-icon {
  margin: 0 5px !important;
}
.search-btn > div {
  padding: 7px 20px !important;
}
</style>