import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  loader: false,
  vendorOsFlag: false
};

const mutations = {
  setLoader(state, payload) {
    state.loader = payload.loader;
  },
  setLeId(state, payload) {
    state.leId = payload.leId;
  },
  setFinYear(state, payload) {
    state.finYear = payload.finYear;
  },
  setCurrentFinYear(state, payload){
    state.currentFinYear = payload.currentFinYear;
  },
  setVendorOsFlag(state, payload) {
    state.vendorOsFlag = payload.vendorOsFlag;
  },
};

const actions = {
  setLoader(context, value) {
    context.commit("setLoader", {
      loader: value,
    });
  },
  getModuleList(context, payload) {
    return http.getApi(URL_UTILITY.getModuleListUrl, payload);
  },
  getLegalEntity(context, payload) {
    return http.getApi(URL_UTILITY.getLegalEntityListUrl, payload);
  },
  setLeId(context, id) {
    context.commit("setLeId", {
      leId: id,
    });
  },
  setFinYear(context, year) {
    context.commit("setFinYear", {
      finYear: year,
    });
  },
  setCurrentFinYear(context, currentYear) {
    context.commit("setCurrentFinYear", {
      currentFinYear: currentYear,
    });
  },
  getFinancialYear() {
    return http.getApi(URL_UTILITY.getFinancialYearUrl);
  },
  setVendorOsFlag(context, value) {
    context.commit("setVendorOsFlag", {
      vendorOsFlag: value,
    });
  },
};

const getters = {};

const shared = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default shared;
