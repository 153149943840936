<template>
    <md-card-content>
    <b-alert
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
      <div class="md-layout">
        <div class="md-layout-item md-size-33">
          <md-field>
            <label class="model-select-label">Salutation</label>
            <model-select
              :options="salutationList"
              v-model="salutation"
            ></model-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label>First Name</label>
            <md-input type="text" v-model="firstName"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label>Middle Name</label>
            <md-input type="text" v-model="middleName"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label>Last Name</label>
            <md-input type="text" v-model="lastName"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-field>
            <label class="model-select-label">Contact Purpose</label>
            <model-select
              :options="contactPurposeList"
              v-model="contactPurpose"
            ></model-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-33">
          <md-checkbox v-model="active" :value="true" :unchecked-value="false"
            >Active</md-checkbox
          >
        </div>
        <div class="md-layout-item md-small-size-100 md-size-33"
        style="margin-top: 5px">
          <md-button
            class="md-raised md-info search-btn"
            @click="addEditContact()"
            >Save</md-button
          >
        </div>
      </div>
    </md-card-content>
  <!-- </md-card> -->
</template>

<script>
import { ModelSelect } from "vue-search-select";
import appStrings from "../../../../utility/string.utility";
import store from "../../../../store";
import moment from 'moment';
export default {
  components: {
    ModelSelect,
  },
  name: "addContact",
  props: ["contactDetails"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      salutation: {
        value: null,
        text: null,
      },
      salutationList: [],
      firstName: null,
      middleName: null,
      lastName: null,
      contactPurpose: {
        value: null,
        text: null,
      },
      startDate: null,
      contactPurposeList: [],
      ppPartyContactPersonId: 0,
      active: false
    };
  },
  mounted() {
    if (this.contactDetails) {
      this.fillContactDetails(this.contactDetails);
    };
    this.getSalutationType();
    this.getContactPurposeValueSet();
  },
  methods: {
    fillContactDetails(item) {
      this.salutation = {
        value: item.salutation_lookup,
        text: item.salutation,
      };
      this.salutationList = [
        {
          value: item.salutation_lookup,
          text: item.salutation,
        },
      ];
      this.firstName = item.first_name;
      this.middleName = item.middle_name;
      this.lastName = item.last_name;
      this.contactPurpose = {
        value: item.contact_purpose_vset,
        text: item.contact_purpose,
      };
      this.contactPurposeList = [
        {
          value: item.contact_purpose_vset,
          text: item.contact_purpose,
        },
      ];
      this.ppPartyContactPersonId = item.pp_party_contact_person_id;
      this.partyContactPersonId =item.party_contact_person_id;
      this.active = item.active;
    },
    addEditContact() {
      const payload = {
        pp_party_contact_person_id: this.ppPartyContactPersonId, 
        party_contact_person_id: this.partyContactPersonId, 
        salutation_lookup: this.salutation.value, 
        first_name: this.firstName, 
        middle_name: this.middleName, 
        last_name: this.lastName, 
        contact_purpose_vset: this.contactPurpose.value, 
        start_date: moment(new Date()).format(appStrings.DATEFORMAT),
        end_date: null, 
        party_id: store.state.auth.partyId,
        active: this.active
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditContact", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.$emit("updatedContactResponse");
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getSalutationType() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getLovByLookupType", appStrings.LOOKUPTYPE.SALUTATION)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.lookup_code,
                text: type.meaning,
              };
            });
            this.salutationList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getContactPurposeValueSet() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.CONTACT_PURPOSE,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.contactPurposeList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
