<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Customer</label>
              <md-input type="text" v-model="customer"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Value From</label>
              <md-input type="text" v-model="valueFrom"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Value To</label>
              <md-input type="text" v-model="valueTo"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="fromDate"
              ><label>From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="toDate"
              ><label>To Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-success search-btn"
                @click="getCustomerDetails()"
                style="margin-right: 10px"
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="!queryForm"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-rose"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Customer Query</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card" @click="showQueryForm(true)">
              <h6 class="title">Add Query</h6>
            </div>
          </md-card-header>
        </div>
          <div class="md-layout-item" style="margin-top: 3px !important">
            <b-table
              striped
              hover
              outlined
              sticky-header
              :items="gridData"
              :fields="gridField"
              show-empty
              class="bg-white"
            >
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="queryForm"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-rose"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Customer Query</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card" @click="showQueryForm(false)">
              <h6 class="title">Hide Add Query</h6>
            </div>
          </md-card-header>
        </div>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Query Number</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Legal Entity</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Module</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Category</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Sub-Category</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Sub-Sub-Category</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Source</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Priority</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Status</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Email ID</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Contact Number</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Raised By</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Appointment Date</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>From Time - To Time</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>TAT</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>TAT UOM</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Entity Type</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Sector</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Pocket</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Project</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Tower</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Floor</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Unit</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Sub Unit</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Additional Contact Number</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>Subject</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>Comment</label>
                <md-textarea></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-button class="md-raised md-primary">Submit</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import commonHelper from "../../../utility/common.helper.utility";
export default {
  watch: {
    currentPage: function () {
      this.getCustomerDetails();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getCustomerDetails();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      queryForm: false,
      customer: null,
      valueFrom: null,
      valueTo: null,
      fromDate: null,
      toDate: null,
      leId: null,
      finYear: null,
      unsubscribe: null,
      gridData: [],
      gridField: [
        {
          key: "legal_entity",
        },
        {
          key: "sub_category",
        },
        {
          key: "sub_sub_category",
        },
        {
          key: "entity_type",
        },
        {
          key: "entity_name",
        },
        {
          key: "customer",
        },
        {
          key: "contact_no",
        },
        {
          key: "additional",
        },
        {
          key: "source",
        },
        {
          key: "priority",
        },
        {
          key: "status",
        },
        {
          key: "currency",
        },
        {
          key: "created_by",
        },
        {
          key: "creation_date",
        },
        {
          key: "closure_date",
        },
      ],
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.getCustomerDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
        this.getCustomerDetails();
      }
    });
  },
  methods: {
    getCustomerDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        trx_amount_from: this.valueFrom,
        trx_amount_to: this.valueTo,
        trx_date_from: commonHelper.formattedDate(this.fromDate),
        trx_date_to: commonHelper.formattedDate(this.toDate),
        trx_no: this.customer,
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : null,
      };
      this.gridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getCustomerDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    clearSearch() {
      this.customer = null;
      this.valueFrom = null;
      this.valueTo = null;
      this.fromDate = null;
      this.toDate = null;
      this.gridData = [];
    },
    showQueryForm(flag) {
      this.queryForm = flag;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>