<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- For Create Invoice With PO -->
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Header Details</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 7px !important"
          >
            <div
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="getOpenKmAuthenticate()"
              v-if="venInvoiceId"
            >
              <!-- <h6 class="title">Upload Document</h6> -->
              <md-icon title="Upload Document" style="width:15px; height:15px;"
                >file_upload</md-icon
              >
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="showViewInvoiceForm(false)"
            >
              <h6 class="title">View Invoice</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-switch
              v-model="radio"
              @change="clearSwitch(radio)"
              :disabled="
                poHeaderDetails.approval_status === 'INPROCESS' ||
                  invoiceHeader.approval_status === 'INPROCESS'
              "
              ><b style="color:black;">
                <span v-if="!radio">Click here for Invoice without PO</span
                ><span v-else>Click here for Invoice with PO</span>
              </b>
            </md-switch>
            <!-- <md-radio v-model="radio" value="accent"
              ><b> Invoice with Agreement</b>
            </md-radio>
            <md-radio v-model="radio" value="primary" class="md-primary"
              ><b>Invoice without Agreement</b></md-radio
            > -->
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-15"
            v-if="!radio"
          >
            <md-field md-clearable @md-clear="clearPoValues()">
              <label for="font">PO Number</label>
              <md-input
                v-model="poHeaderDetails.po_number"
                :title="poHeaderDetails.po_number"
                @click="showHidePoModal(true)"
                :disabled="
                  poHeaderDetails.approval_status === 'INPROCESS' ||
                    venInvoiceId > 0
                "
                readonly
              ></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-60"
            v-if="poHeaderDetails.rejection_remarks_comments"
          >
            <md-field>
              <label for="font">Rejection Remarks</label>
              <md-input
                v-model="poHeaderDetails.rejection_remarks_comments"
                :title="poHeaderDetails.rejection_remarks_comments"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-70"
            v-if="invoiceHeader.rejection_remarks_comments"
          >
            <md-field>
              <label for="font">Rejection Remarks</label>
              <md-input
                v-model="invoiceHeader.rejection_remarks_comments"
                :title="invoiceHeader.rejection_remarks_comments"
                disabled
              ></md-input>
            </md-field>
          </div>
        </div>
        <!-- </md-card>
      <md-card> -->
        <!-- <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
          style="margin: 8px"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; font-weight:bold; width: 120px; padding: 3px !important"
          >
            <h5 class="title">Header Details</h5>
          </div>
        </md-card-header> -->
        <div class="md-layout" v-if="!radio">
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Invoice Number</label>
              <md-input
                v-model="poHeaderDetails.inv_number"
                :title="poHeaderDetails.inv_number"
                :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
                @input="
                  upperCaseFunction(poHeaderDetails.inv_number, 'with_po')
                "
                @blur="isInvNumValid(poHeaderDetails.inv_number, 'with_po')"
                maxlength="16"
              >
              </md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.inv_number.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Total Invoice Amount</label>
              <md-input
                v-model="poHeaderDetails.inv_amount"
                @blur="clearDeliveryQty()"
                v-numericOnly
                :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.inv_amount.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Third Party GSTN</label>
              <md-input
                v-if="radio"
                v-model="poHeaderDetails.third_party_gstn"
                :title="poHeaderDetails.third_party_gstn"
                maxlength="15"
              ></md-input>
              <md-input
                v-else-if="!radio"
                v-model="poHeaderDetails.third_party_gstn"
                :title="poHeaderDetails.third_party_gstn"
                disabled
              ></md-input>
            </md-field>
            <!-- <div
              class="error"
              v-if="$v.poHeaderDetails.third_party_gstn.$error"
            >
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Company GSTN</label>
              <md-input
                v-if="radio"
                v-model="poHeaderDetails.first_party_gstn"
                :title="poHeaderDetails.first_party_gstn"
                maxlength="15"
              ></md-input>
              <md-input
                v-if="!radio"
                v-model="poHeaderDetails.first_party_gstn"
                :title="poHeaderDetails.first_party_gstn"
                disabled
              ></md-input>
            </md-field>
            <!-- <div
              class="error"
              v-if="$v.poHeaderDetails.first_party_gstn.$error"
            >
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <!-- :disabled="poHeaderDetails.po_number ? true : false" -->
            <md-field>
              <label for="text" class="mandatory">Currency</label>
              <md-input
                v-model="poHeaderDetails.currency"
                :title="poHeaderDetails.currency"
                :disabled="!radio"
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.currency.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Status</label>
              <md-input
                v-model="poHeaderDetails.approval_status"
                :title="poHeaderDetails.approval_status"
                disabled
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.approval_status.$error">
              This field is required
            </div> -->
          </div>
          <!--  -->
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label>Diff Amount</label>
              <md-input
                v-model="poHeaderDetails.dif_amount"
                disabled
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Payment Term</label>
              <md-input
                v-model="poHeaderDetails.payment_term"
                :title="poHeaderDetails.payment_term"
                disabled
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.payment_term.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text">Vendor Site Name</label>
              <md-input
                v-model="poHeaderDetails.vendor_site_name"
                :title="poHeaderDetails.vendor_site_name"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text">Buyer Name</label>
              <md-input
                v-model="poHeaderDetails.buyer_name"
                :title="poHeaderDetails.buyer_name"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text">Buyer Email ID</label>
              <md-input
                v-model="poHeaderDetails.buyer_email_id"
                :title="poHeaderDetails.buyer_email_id"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15 DateFormate"
          >
            <md-field>
              <label
                v-if="poHeaderDetails.inv_date"
                style="margin-top:-25%;"
                for="text"
                >Invoice Date</label
              >
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="Invoice Date"
                v-model="poHeaderDetails.inv_date"
                style="margin-top:-0.5rem;"
                :disabled-date="disabledDates"
                :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
              ></date-picker>
            </md-field>
          </div>
        </div>
        <div class="md-layout" v-if="radio">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field
              md-clearable
              @md-clear="clearFieldsWithoutPo('legal_entity')"
            >
              <label for="text" class="mandatory">Legal Entity</label>
              <md-input
                v-model="invoiceHeader.le_name"
                :title="invoiceHeader.le_name"
                readonly
                @click="showHideInvoiceCommonModal(true, 'legal_entity')"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field md-clearable @md-clear="clearFieldsWithoutPo('currency')">
              <label for="text" class="mandatory">Currency</label>
              <md-input
                v-model="invoiceHeader.currency"
                :title="invoiceHeader.currency"
                readonly
                @click="showHideInvoiceCommonModal(true, 'currency_code')"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="text" class="mandatory">Total Invoice Amount</label>
              <md-input
                v-model="invoiceHeader.inv_amount"
                :title="invoiceHeader.inv_amount"
                v-numericOnly
                @blur="clearDeliveryQtyWithoutPo()"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate">
            <md-field>
              <label
                v-if="invoiceHeader.inv_date"
                class="mandatory"
                style="margin-top:-25%;"
                for="text"
                >Invoice Date</label
              >
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="Invoice Date"
                v-model="invoiceHeader.inv_date"
                style="margin-top:-0.5rem;"
                :disabled-date="disabledDates"
                @clear="clearInvoiceDate()"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></date-picker>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field
              md-clearable
              @md-clear="clearFieldsWithoutPo('company_gstn')"
            >
              <label for="text" class="mandatory">Company GSTN</label>
              <md-input
                v-model="invoiceHeader.company_gstn_name"
                :title="invoiceHeader.company_gstn_name"
                readonly
                @click="gstnBasedOnLeInvDate('compGstn')"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
                <!-- @click="showHideInvoiceCommonModal(true, 'company_gstn')" -->
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field
              md-clearable
              @md-clear="clearFieldsWithoutPo('vendor_gstn')"
            >
              <label for="text" class="mandatory">Vendor GSTN</label>
              <md-input
                v-model="invoiceHeader.vendor_gstn_name"
                :title="invoiceHeader.vendor_gstn_name"
                readonly
                @click="gstnBasedOnLeInvDate('vendorGstn')"
                :disabled="
                  invoiceHeader.approval_status === 'INPROCESS' ||
                    invoiceHeader.vendor_gstn_flag
                "
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <md-checkbox
              v-model="invoiceHeader.vendor_gstn_flag"
              :true-value="true"
              :false-value="false"
              @change="isGstAvail(invoiceHeader.vendor_gstn_flag)"
              :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >Vendor GST NA
            </md-checkbox>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="text" class="mandatory">Status</label>
              <md-input
                v-model="invoiceHeader.approval_status"
                :title="invoiceHeader.approval_status"
                disabled
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="text" class="mandatory">Diff Amount</label>
              <md-input v-model="invoiceHeader.dif_amount" disabled> </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="text" class="mandatory">Invoice Number</label>
              <md-input
                v-model="invoiceHeader.inv_number"
                :title="invoiceHeader.inv_number"
                @input="
                  upperCaseFunction(invoiceHeader.inv_number, 'without_po')
                "
                @blur="isInvNumValid(invoiceHeader.inv_number, 'without_po')"
                maxlength="16"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="text" class="mandatory">Note</label>
              <md-input
                v-model="invoiceHeader.note"
                :title="invoiceHeader.note"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field
              md-clearable
              @md-clear="clearFieldsWithoutPo('select_employee')"
            >
              <label for="text" class="mandatory">Employee Email ID</label>
              <!-- readonly
                @click="openValueSetModal('EMPL_INV_DEL')" -->
              <md-input
                v-model="invoiceHeader.manual_employee_name"
                :title="invoiceHeader.manual_employee_name"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-checkbox
              v-model="invoiceHeader.round_off"
              :true-value="true"
              :false-value="false"
              @change="isRoundApplicable(invoiceHeader.round_off)"
              :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              >Round-Off
            </md-checkbox>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice && !radio"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">PO Line Details</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div
              v-if="
                poLineDetailsData.length &&
                  poHeaderDetails.approval_status !== 'INPROCESS'
              "
              class="card-text profile-card"
              style="cursor:pointer; margin-right:1rem;"
              @click="applyDefaultTax()"
            >
              <h6 class="title">Apply Tax</h6>
            </div>
            <div
              v-if="
                poLineDetailsData.length &&
                  poHeaderDetails.approval_status !== 'INPROCESS'
              "
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="addMultiRow()"
            >
              <h6 class="title">Add</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout-item">
          <template>
            <b-card>
              <b-table
                striped
                hover
                outlined
                :items="poLineDetailsData"
                :fields="poLineDetailsFields"
                show-empty
                class="bg-white"
                sticky-header
              >
                <template #head(po_number)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_line_num)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_release_num)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_shipment_line_num)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(item_code)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(item_description)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_price)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(uom)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(quantity)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(billed_pending)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(pending_qty)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(deliver_qty)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(amount)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(hsn_sac)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(NOTE_TO_VENDOR)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(shp_to_loc)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(release_line_from_date)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(release_line_to_date)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(item_type)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(tax_category_name)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(tax_details)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(remarks)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #cell(select)="data">
                  <b-form-checkbox
                    style="margin-left:0.5rem;"
                    v-model="data.item.select"
                    :value="true"
                    :unchecked-value="false"
                  ></b-form-checkbox>
                  <!-- @change="
                      selectBoxChecked(
                        $event,
                        data.index,
                        data.item,
                        data.item.po_line_id
                      )
                    " -->
                </template>
                <template #cell(po_price)="data">
                  <span>{{ formattedCurrency(+data.item.po_price) }}</span>
                </template>
                <template #cell(deliver_qty)="data">
                  <b-form-input
                    v-model="data.item.deliver_qty"
                    style="width:10rem; text-align: center;"
                    @input="
                      validateFunction(
                        data.item.deliver_qty,
                        data.index,
                        'with_po',
                        data.item
                      )
                    "
                    @blur="validateDeliverQty(data.item, data.index)"
                    v-numericOnly
                  ></b-form-input>
                </template>

                <template #cell(amount)="data">
                  <b-form-input
                    v-model="data.item.amount"
                    style="width:10rem; text-align: center;"
                    @input="
                      validateFunctionAmount(
                        data.item.amount,
                        data.index,
                        'amount',
                        data.item
                      )
                    "
                    @blur="validateAmountCalculation(data.item, data.index)"
                    v-numericOnly
                  ></b-form-input>
                </template>
                <template #cell(hsn_sac)="data">
                  <b-form-input
                    v-model="data.item.hsn_sac"
                    style="width:5rem; text-align: center;"
                    @blur="validateHsnSac(data.item)"
                    maxLength="8"
                    v-numericOnly
                  ></b-form-input>
                </template>
                <template #cell(tax_details)="data">
                  <span
                    style="cursor:pointer; color:#007bff"
                    @click="showHideTaxModal(true, data.item, data.index)"
                    >Tax Details</span
                  >
                </template>

                <template #cell(remarks)="data">
                  <b-form-input
                    v-model="data.item.remarks"
                    :title="data.item.remarks"
                  ></b-form-input>
                </template>

                <template #cell(NOTE_TO_VENDOR)="data">
                  <span
                    style="cursor:context-menu;"
                    :title="data.item.NOTE_TO_VENDOR"
                    >{{ truncateComment(data.item.NOTE_TO_VENDOR, 80) }}</span
                  >
                </template>
              </b-table>
            </b-card>
          </template>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice && !radio"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">New Invoice Details</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div
              v-if="
                newInvoiceDetailsData.length &&
                  poHeaderDetails.approval_status !== 'INPROCESS'
              "
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="removeMultiRow()"
            >
              <h6 class="title">Remove</h6>
            </div>
          </md-card-header>
        </div>

        <div class="md-layout-item">
          <template>
            <b-card>
              <b-table
                striped
                hover
                outlined
                :items="newInvoiceDetailsData"
                :fields="newInvoiceDetailsFields"
                show-empty
                class="bg-white"
                sticky-header
              >
                <template #head(po_number)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_line_num)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_release_num)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_shipment_line_num)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(item_code)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(item_description)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(po_price)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(uom)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(deliver_qty)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(hsn_sac)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(NOTE_TO_VENDOR)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(shp_to_loc)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(release_line_from_date)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(release_line_to_date)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(item_type)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(tax_category_name)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(tax_amount)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(tax_details)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #head(remarks)="data">
                  <HeaderSummary
                    :headerLabel="data.label"
                    :menuId="menuIdSummary"
                  />
                </template>
                <template #cell(select)="data">
                  <b-form-checkbox
                    v-model="data.item.select"
                    :value="true"
                    :unchecked-value="false"
                  ></b-form-checkbox>
                </template>
                <template #cell(po_price)="data">
                  <span>{{ formattedCurrency(+data.item.po_price) }}</span>
                </template>
                <template #cell(tax_amount)="data">
                  <!-- <span>{{ parseFloat(data.item.tax_amount).toFixed(2) }}</span> -->
                  <span>{{ formattedCurrency(+data.item.tax_amount) }}</span>
                </template>
                <template #cell(tax_details)="data">
                  <span
                    style="cursor:pointer; color:#007bff"
                    @click="showHidePreviewTaxModal(true, data.item)"
                    >Tax Details</span
                  >
                </template>
                <template #cell(remarks)="data">
                  <span
                    style="cursor:context-menu;"
                    :title="data.item.remarks"
                    >{{ truncateComment(data.item.remarks, 20) }}</span
                  >
                </template>
                <template #cell(NOTE_TO_VENDOR)="data">
                  <span
                    style="cursor:context-menu;"
                    :title="data.item.NOTE_TO_VENDOR"
                    >{{ truncateComment(data.item.NOTE_TO_VENDOR, 80) }}</span
                  >
                </template>
              </b-table>
            </b-card>
          </template>
        </div>
      </md-card>
      <!-- v-if="poHeaderDetails.po_number" -->
      <md-button
        v-if="
          newInvoiceDetailsData.length &&
            (poHeaderDetails.approval_status === 'DRAFT' ||
              poHeaderDetails.approval_status === 'REJECTED')
        "
        style="float:right;"
        class="md-raised md-rose search-btn"
        @click="validateInvoiceDetails()"
        >Save</md-button
      >
      <md-button
        style="float:right;"
        class="md-raised md-rose search-btn"
        v-if="venInvoiceId && poHeaderDetails.approval_status == 'DRAFT'"
        @click="validateDocument()"
        >Submit</md-button
      >
      <!-- :disabled="isApprovalSent" -->
    </div>
    <!-- For Create Invoice With PO -->

    <!-- For Create Invoice Without PO -->
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice && radio"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Invoice Details</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout-item">
          <b-table
            striped
            style="max-height:400px;"
            hover
            outlined
            :items="invoiceWithoutPoData"
            :fields="invoiceWithoutPoFields"
            show-empty
            class="bg-white"
            sticky-header
          >
            <template #head(item_description)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(po_price)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(deliver_qty)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(total_price)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(hsn_sac)="data">
              <span :class="invoiceHeader.vendor_gstn_flag ? '' : 'mandatory'">
                {{ data.label }}
                <!-- <HeaderSummary
                  :headerLabel="data.label"
                  :menuId="menuIdSummary"
                /> -->
              </span>
            </template>
            <template #head(tax_amount)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(tax_details)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(location)="data">
              <!-- <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              /> -->
              <div style="text-align:center !important;">
                <HeaderSummary
                  :headerLabel="data.label"
                  :menuId="menuIdSummary"
                />
              </div>
            </template>
            <template #head(expense_type)="data">
              <div style="text-align:center !important;">
                <HeaderSummary
                  :headerLabel="data.label"
                  :menuId="menuIdSummary"
                />
              </div>
            </template>
            <template #cell(location)="data">
              <b-form-input
                v-model="data.item.location"
                :title="data.item.location"
                @click="locationBasedonCompanyGstn('location', data.index)"
                style="width:13rem !important;"
                readonly
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></b-form-input>
            </template>
            <template #cell(expense_type)="data">
              <b-form-input
                v-model="data.item.expense_type"
                :title="data.item.expense_type"
                @click="locationBasedonCompanyGstn('expense_type', data.index)"
                style="width:13rem !important;"
                readonly
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></b-form-input>
            </template>
            <template #cell(item_description)="data">
              <b-form-input
                v-model="data.item.item_description"
                :title="data.item.item_description"
                style="width:13rem !important;"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
                maxlength="240"
              ></b-form-input>
            </template>
            <template #cell(po_price)="data">
              <b-form-input
                v-model="data.item.po_price"
                v-numericNegative
                @change="isInvAmtAvail(data.item, 'price', data.index)"
                style="width:6rem !important;"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></b-form-input>
              <!-- @input="
                  validateDeliveryQty(data.item.po_price, data.index, data.item)
                " -->
            </template>
            <template #cell(deliver_qty)="data">
              <b-form-input
                v-model="data.item.deliver_qty"
                @input="
                  validateFunction(
                    data.item.deliver_qty,
                    data.index,
                    'without_po'
                  )
                "
                @change="calculateTotalAmount(data.item, 'deliver_qty')"
                v-numericOnly
                style="width:7rem !important;"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></b-form-input>
            </template>
            <!-- <template #head(total_price)="data">
              <div style="text-align:center !important;">
                {{ data.label }}
              </div>
            </template> -->
            <template #cell(total_price)="data">
              <b-form-input
                v-model="data.item.total_price"
                style="width:7rem !important;"
                @input="
                  validateAmountWithoutPo(data.item.total_price, data.index)
                "
                @change="calculateUnitPrice(data.item)"
                v-numericOnly
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></b-form-input>
            </template>
            <!-- <template #cell(total_price)="data">
              <span style="width:13rem !important;">{{
                data.item.total_price !== 0
                  ? formattedCurrency(data.item.total_price)
                  : data.item.total_price
              }}</span>
            </template> -->
            <template #cell(hsn_sac)="data">
              <b-form-input
                v-model="data.item.hsn_sac"
                maxlength="8"
                v-numericOnly
                @blur="validateHsnSac(data.item)"
                style="width:5rem; text-align: center; !important;"
                :disabled="invoiceHeader.approval_status === 'INPROCESS'"
              ></b-form-input>
            </template>
            <template #cell(tax_amount)="data">
              <span>{{
                data.item.tax_amount !== 0
                  ? formattedCurrency(data.item.tax_amount)
                  : data.item.tax_amount
              }}</span>
            </template>
            <!-- <template #cell(tax_category_name)="data">
              <b-form-input
                v-model="data.item.tax_category_name"
                :title="data.item.tax_category_name"
              ></b-form-input>
            </template> -->
            <template #cell(tax_details)="data">
              <span
                v-if="
                  invoiceHeader.erp_le_id &&
                    data.item.item_description !== 'Round-off' && (+(data.item.total_price)>0)
                "
                style="cursor:pointer; color:#007bff"
                @click="showHideTaxWithoutPoModal(true, data.item, data.index)"
                >Details</span
              >
            </template>
            <template #cell(add)="">
              <div @click="addVendorInvoiceLine()">
                <md-icon>add_circle</md-icon>
              </div>
            </template>
            <template #cell(remove)="data">
              <div
                v-if="
                  data.index > 0 && data.item.item_description !== 'Round-off'
                "
                @click="removeVendorInvoiceLine(data.index, data.item)"
              >
                <md-icon>delete</md-icon>
              </div>
            </template>
          </b-table>
          <md-button
            v-if="
              invoiceHeader.approval_status === 'DRAFT' ||
                invoiceHeader.approval_status === 'REJECTED'
            "
            style="float:right;"
            class="md-raised md-rose search-btn"
            @click="validateInvoiceDetailsWithoutPo()"
            >Save</md-button
          >
          <md-button
            style="float:right;"
            class="md-raised md-rose search-btn"
            v-if="venInvoiceId && invoiceHeader.approval_status !== 'INPROCESS'"
            :disabled="isApprovalSent"
            @click="validateDocument()"
            >Submit</md-button
          >
        </div>
      </md-card>
    </div>
    <!-- For Create Invoice Without PO -->

    <!-- For View Grid Invoice -->
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="!viewInvoice"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <!-- <div class="card-text profile-card">
              <h6 class="title">Search Filter</h6>
            </div> -->
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 10px !important"
          >
            <div
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="showViewInvoiceForm(true)"
            >
              <h6 class="title">Add Invoice</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="text">PO Number</label>
              <md-input
                v-model="searchFilters.poNumber"
                :title="searchFilters.poNumber"
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%; margin-left:-10px"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Invoice No.</label>
              <md-input
                type="text"
                v-model="searchFilters.invNumber"
                :title="searchFilters.invNumber"
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%; margin-left:-10px"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="text">Amount From</label>
              <md-input
                v-model="searchFilters.invAmountFrom"
                :title="searchFilters.invAmountFrom"
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%; margin-left:-10px"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="text">Amount To</label>
              <md-input
                v-model="searchFilters.invAmountTo"
                :title="searchFilters.invAmountTo"
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%; margin-left:-10px"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field md-clearable>
              <label for="text">Status</label>
              <md-input
                v-model="searchFilters.status.text"
                :title="searchFilters.status.text"
                readonly
                @click="openValueSetModal('PARTY_REQ_APPROVAL_STATUS_VSET')"
              ></md-input>
            </md-field>
          </div>
          <div
            style="max-width:12%;"
            class="md-layout-item md-small-size-100 md-size-12 DateFormate"
          >
            <date-picker
              v-model="searchFilters.fromDate"
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="From Date"
              @change="toDate = null"
            ></date-picker>
          </div>
          <div
            style="max-width:12%;"
            class="md-layout-item md-small-size-100 md-size-12 DateFormate"
          >
            <date-picker
              v-model="searchFilters.toDate"
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="To Date"
              :disabled="!searchFilters.fromDate"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-16">
            <div style="margin-top: 10px;">
              <md-button
                class="md-raised md-success search-btn"
                @click="getVendorInvoiceDetails()"
                style="margin-right: 10px"
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                style="margin-right: 10px"
                @click="clearFilters()"
                >Clear</md-button
              >
              <md-button
                v-if="invoiceDetailsData.length"
                style="height:28px; margin-right: 10px;"
                class="md-warning md-just-icon"
                @click="downloadCustomExcel()"
                title="Download Excel"
                ><md-icon>file_download</md-icon></md-button
              >
              <md-button
                v-if="invoiceDetailsData.length"
                style="height:28px; background-color:green !important"
                class="md-rose md-just-icon"
                @click="scrapeInvoice()"
                title="Scrap Invoice"
                ><md-icon>delete_sweep</md-icon></md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="!viewInvoice"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-rose"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Invoice Details</h6>
            </div>
          </md-card-header>
          <!-- <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card" @click="showQueryForm(true)">
              <h6 class="title">Add Query</h6>
            </div>
          </md-card-header> -->
        </div>
        <div class="md-layout-item" style="margin-top: 3px !important">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="invoiceDetailsData"
            :fields="invoiceDetailsField"
            show-empty
            class="bg-white"
            @row-clicked="rowSelectedGrid"
          >
            <template #head(select)="data">
              <div>
                <b-form-checkbox
                  v-model="selectAllCheckBox"
                  :value="true"
                  :unchecked-value="false"
                  style="display: inline"
                >
                </b-form-checkbox>
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #cell(select)="data">
              <b-form-checkbox
                v-model="data.item.select"
                :value="true"
                :unchecked-value="false"
                :disabled="
                  data.item.approval_status !== 'REJECTED' &&
                    data.item.approval_status !== 'DRAFT'
                "
              ></b-form-checkbox>
            </template>
            <template #cell(inv_amt)="data">
              <span class="text-fix-invamt">{{
                data.item.inv_amt !== 0
                  ? formattedCurrency(data.item.inv_amt)
                  : data.item.inv_amt
              }}</span>
            </template>
            <template #cell(uploaded_document)="data">
              <a href="#" @click="getOpenKmAuthenticate()">{{
                data.item.uploaded_document
              }}</a>
            </template>
            <template #head(le_name)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(po_num)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(inv_num)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(inv_amt)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(first_party_gstn)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(third_party_gstn)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(currency)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(approval_status)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
            <template #head(inv_date)="data">
              <HeaderSummary
                :headerLabel="data.label"
                :menuId="menuIdSummary"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPageGrid"
            :total-rows="totalRowsGrid"
            :per-page="perPageGrid"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>

          <div class="md-layout pagination-count">
            <div style="width:18%;">
              <div
                v-if="invoiceDetailsData.length && totalRowsGrid <= perPageGrid"
              >
                <p>
                  Showing
                  {{
                    totalRowsGrid == 0
                      ? totalRowsGrid
                      : perPageGrid * (currentPageGrid - 1) + 1
                  }}
                  to {{ totalRowsGrid }} of {{ totalRowsGrid }} entries
                </p>
              </div>
              <div v-else-if="invoiceDetailsData.length">
                <p>
                  Showing {{ perPageGrid * (currentPageGrid - 1) + 1 }} to
                  {{
                    perPageGrid * currentPageGrid >= totalRowsGrid
                      ? totalRowsGrid
                      : perPageGrid * currentPageGrid
                  }}
                  of {{ totalRowsGrid }}
                  entries
                </p>
              </div>
            </div>
            <div
              v-if="invoiceDetailsData.length"
              style="margin-top:-0.5%; width:7%"
            >
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="perPageGrid">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div style="color:red; margin-left:50%;">
              <span>Click on the Field Header for more information</span>
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <!-- For View Grid Invoice -->

    <b-modal
      v-model="showTaxModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="custom-modal"
      title="Tax Details"
      @hidden="showHideTaxModal(false, null, null)"
      ><b-table
        striped
        hover
        outlined
        :items="taxDetailsData"
        :fields="taxDetailsFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
      >
        <template #cell(tax_type)="data">
          <b-form-input
            v-if="!data.item.tax_type_id"
            v-model="data.item.tax_type"
            :title="data.item.tax_type"
            @click="showHideTaxTypeModal(true, data.index)"
            readonly
          ></b-form-input>
          <span v-else>{{ data.item.tax_type }}</span>
        </template>
        <template #cell(taxable_amount)="data">
          <b-form-input
            style="width:60%;"
            v-if="
              data.item.tax_type &&
                (data.item.tax_type.includes('FREIGHT') ||
                  data.item.tax_type.includes('ADHOC_AMOUNT'))
            "
            v-model="data.item.taxable_amount"
            @input="
              validateFunctionAmount(
                data.item.taxable_amount,
                data.index,
                'taxable_amount'
              )
            "
            @blur="validateTaxableAmount(data.item.taxable_amount, data.index)"
          ></b-form-input>
          <span v-else
            >{{
              data.item.taxable_amount
                ? formattedCurrency(+data.item.taxable_amount)
                : null
            }}
          </span>
        </template>
        <template #cell(tax_amt)="data">
          <!-- <b-form-input
            style="width:60%;"
            v-if="data.item.manual == 'Y'"
            v-model="data.item.tax_amt"
          ></b-form-input> -->
          <span
            >{{
              data.item.tax_amt ? formattedCurrency(+data.item.tax_amt) : null
            }}
          </span>
        </template>
        <template #cell(tax_category_name)="data">
          <b-form-input
            style="width:100%;"
            v-if="data.item.manual == 'Y'"
            v-model="data.item.tax_category_name"
            :title="data.item.tax_category_name"
            @click="
              data.item.tax_type.includes('TCS')
                ? openValueSetModal('PARTY_REQ_TCS_TYPE', data.index)
                : showHideTaxCategoryModal(true, data.index)
            "
            readonly
          >
            <!-- v-if="
              data.item.manual == 'Y' &&
                data.item.tax_type &&
                !data.item.tax_type.includes('TCS')
            " -->
          </b-form-input>
        </template>
        <template #cell(rcm_flag)="data">
          <span style="color:black;">{{
            data.item.rcm_flag == "N"
              ? "No"
              : data.item.rcm_flag == "Y"
              ? "Yes"
              : null
          }}</span>
        </template>
        <template #cell(add)="">
          <div @click="addTaxRow()">
            <md-icon>add_circle</md-icon>
          </div>
        </template>
        <template #cell(remove)="data">
          <div v-if="data.item.manual == 'Y'" @click="removeTaxRow(data.index)">
            <md-icon>delete</md-icon>
          </div>
        </template>
      </b-table>
      <div
        class="md-layout-item md-small-size-100 md-size-25"
        style="margin-top: 30%; float:right;"
      >
        <md-button
          class="md-raised md-success search-btn"
          style="margin-right:5%;"
          @click="addTaxRow()"
          >Add Row</md-button
        >
        <md-button
          class="md-raised md-success search-btn"
          @click="addEditTaxDetails()"
          >Save</md-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showPreviewTaxModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Tax Details"
      @hidden="showHidePreviewTaxModal(false, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="previewTaxData"
        :fields="previewTaxFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
      >
        <template #cell(taxable_amount)="data">
          <span>{{
            data.item.taxable_amount
              ? formattedCurrency(+data.item.taxable_amount)
              : null
          }}</span>
        </template>
        <template #cell(amount)="data">
          <span>{{
            data.item.amount ? formattedCurrency(+data.item.amount) : null
          }}</span>
        </template>
        <template #cell(rcm_flag)="data">
          <span>{{
            data.item.rcm_flag == "N"
              ? "No"
              : data.item.rcm_flag == "Y"
              ? "Yes"
              : null
          }}</span>
        </template>
      </b-table>
    </b-modal>
    <!-- Email Modal Starting -->
    <b-modal
      v-model="showEmailModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="New Email"
      @hidden="showHideEmailModal(false)"
      ><emailModal />
    </b-modal>
    <!-- Email Modal Ending -->
    <b-modal
      v-model="showTaxTypeModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Tax Type"
      @hidden="showHideTaxTypeModal(false, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="taxTypeData"
        :fields="taxTypeFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
        @row-clicked="rowSelectedTaxType"
      >
      </b-table>
    </b-modal>
    <b-modal
      v-model="showTaxCategoryModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Tax Category"
      @hidden="showHideTaxCategoryModal(false, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="taxCategoryData"
        :fields="taxCategoryFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
        @row-clicked="rowSelectedTaxCategory"
      >
      </b-table>
    </b-modal>
    <b-modal
      v-model="showPoModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="PO Number"
      @hidden="showHidePoModal(false)"
    >
      <div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <md-field>
              <label for="font">Search By PO Number</label>
              <md-input v-model="poNumberById" v-numericOnly> </md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-10"
            style="margin-top: 10px"
          >
            <md-button
              class="md-raised md-success search-btn"
              @click="getPoNumberDetailsById()"
              >Search</md-button
            >
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-10"
            style="margin-top: 10px"
          >
            <md-button
              class="md-raised md-info search-btn"
              @click="clearSearch()"
              >Clear</md-button
            >
          </div>
        </div>
      </div>
      <b-table
        striped
        hover
        outlined
        :items="poNumberData"
        :fields="poNumberFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
        @row-clicked="rowSelectedPoNumber"
      >
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
      <div
        class="pagination-count1"
        v-if="poNumberData.length && totalRows <= perPage"
      >
        <p>
          Showing
          {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
          to {{ totalRows }} of {{ totalRows }} entries
        </p>
      </div>
      <div class="pagination-count1" v-else-if="poNumberData.length">
        <p>
          Showing {{ perPage * (currentPage - 1) + 1 }} to
          {{
            perPageGrid * currentPage >= totalRows
              ? totalRows
              : perPage * currentPage
          }}
          of {{ totalRows }}
          entries
        </p>
      </div>
    </b-modal>
    <b-modal
      v-model="showDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideDocumentModal(false)"
    >
      <uploadDocument
        :wfHdrId="venInvoiceId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuId"
        :PropSubMenuId="subMenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="invoice_entry"
      />
    </b-modal>

    <!-- b-modal for Invoice Without PO -->
    <b-modal
      v-model="showInvoiceCommonModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="
        modalType === 'legal_entity'
          ? 'md'
          : modalType === 'company_gstn'
          ? 'md'
          : modalType === 'vendor_gstn'
          ? 'md'
          : modalType === 'currency_code'
          ? 'md'
          : ''
      "
      :title="
        modalType === 'legal_entity'
          ? 'Legal Entity'
          : modalType === 'company_gstn'
          ? 'Company GSTN'
          : modalType === 'vendor_gstn'
          ? 'Vendor GSTN'
          : modalType === 'currency_code'
          ? 'Currency'
          : modalType === 'location'
          ? 'Location'
          : modalType === 'expense_type'
          ? 'Expense Type'
          : ''
      "
      @hidden="showHideInvoiceCommonModal(false, null)"
    >
      <div v-if="modalType === 'legal_entity'">
        <div class="md-layout font-fix">
          <label for="filter">Legal Entity Name:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="legalEntitySearch"
                :title="legalEntitySearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getLegalEntityVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="legalEntityData"
          :fields="legalEntityFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedLegalEntity"
          style="max-height:calc(100vh - 250px)"
        >
        </b-table>
        <b-pagination
          v-model="currentPageLegalEntity"
          :total-rows="totalRowsLegalEntity"
          :per-page="perPageLegalEntity"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'company_gstn'">
        <div class="md-layout font-fix">
          <label for="filter">Company GSTN:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="companyGstnSearch"
                :title="companyGstnSearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getCompanyGstnVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="companyGstnData"
          :fields="companyGstnFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedCompanyGstn"
        >
          <template #head(registration_number)="">
            <span>Company GST Number</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageCompanyGstn"
          :total-rows="totalRowsCompanyGstn"
          :per-page="perPageCompanyGstn"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'vendor_gstn'">
        <div class="md-layout font-fix">
          <label for="filter">Vendor GSTN:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="vendorGstnSearch"
                :title="vendorGstnSearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getVendorGstnVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="vendorGstnData"
          :fields="vendorGstnFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedVendorGstn"
          style="max-height:calc(100vh - 300px)"
        >
          <template #head(registration_number)="">
            <span>Vendor GST Number</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageVendorGstn"
          :total-rows="totalRowsVendorGstn"
          :per-page="perPageVendorGstn"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'currency_code'">
        <div class="md-layout font-fix">
          <label for="filter">Currency:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="currencySearch"
                :title="currencySearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getCurrencyVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="currencyData"
          :fields="currencyFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedCurrency"
          style="max-height:calc(100vh - 300px)"
        >
          <template #head(registration_number)="">
            <span>Currency</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageCurrency"
          :total-rows="totalRowsCurrency"
          :per-page="perPageCurrency"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'location'">
        <div class="md-layout font-fix">
          <label for="filter">Location:</label>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="locationSearch"
                :title="locationSearch"
              ></md-input>
            </md-field>
          </div>
          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getLocationVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="locationData"
          :fields="locationFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedLocation"
          style="max-height:calc(100vh - 300px)"
        >
        </b-table>
        <b-pagination
          v-model="currentPageLocation"
          :total-rows="totalRowsLocation"
          :per-page="perPageLocation"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'expense_type'">
        <div class="md-layout font-fix">
          <label for="filter">Expense Type:</label>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="expenseTypeSearch"
                :title="expenseTypeSearch"
              ></md-input>
            </md-field>
          </div>
          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getExpenseTypeVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="expenseTypeData"
          :fields="expenseTypeFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedExpenseType"
          style="max-height:calc(100vh - 300px)"
        >
        </b-table>
        <b-pagination
          v-model="currentPageExpenseType"
          :total-rows="totalRowsExpenseType"
          :per-page="perPageExpenseType"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-modal>
    <b-modal
      v-model="showTaxWithoutPoModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Tax Details"
      @hidden="showHideTaxWithoutPoModal(false, null, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="taxWithoutPoData"
        :fields="taxWithoutPoFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
      >
        <template #cell(tax_type)="data">
          <b-form-input
            v-model="data.item.tax_type"
            :title="data.item.tax_type"
            @click="showHideTaxCommonModal(true, 'taxType', data.index)"
            readonly
          ></b-form-input>
        </template>
        <template #cell(taxable_amount)="data">
          <b-form-input
            v-if="data.item.tax_type && data.item.tax_type.includes('FREIGHT')"
            v-model="data.item.taxable_amount"
            @blur="clearTaxTypeAndTaxAmount(data.item)"
            @input="
              validateFunctionAmount(
                data.item.taxable_amount,
                data.index,
                'taxable_amt'
              )
            "
          >
          </b-form-input>
          <span v-else
            >{{
              data.item.taxable_amount
                ? formattedCurrency(+data.item.taxable_amount)
                : null
            }}
          </span>
        </template>
        <template #cell(tax_rate_type)="data">
          <b-form-input
            v-model="data.item.tax_rate_type"
            :title="data.item.tax_rate_type"
            @click="showHideTaxCommonModal(true, 'taxRate', data.index)"
            readonly
          ></b-form-input>
        </template>
        <template #cell(add)="data">
          <div @click="addTaxWithoutPoRow(data.index)">
            <md-icon>add_circle</md-icon>
          </div>
        </template>
        <template #cell(remove)="data">
          <div v-if="data.index > 0" @click="removeTaxWithoutPoRow(data.index)">
            <md-icon>delete</md-icon>
          </div>
        </template>
      </b-table>
      <div
        v-if="invoiceHeader.approval_status === 'DRAFT'"
        class="md-layout-item md-small-size-100 md-size-30"
        style="margin-top: 30%; float:right;"
      >
        <md-button
          class="md-raised md-success search-btn"
          style="margin-right:5%;"
          @click="addTaxWithoutPoRow()"
          >Add Row</md-button
        >
        <md-button class="md-raised md-success search-btn" @click="isTaxValid()"
          >Save</md-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showTaxCommonModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      :title="
        modalType === 'taxType'
          ? 'Tax Type'
          : modalType === 'taxRate'
          ? 'Tax Rate'
          : ''
      "
      @hidden="showHideTaxCommonModal(false, null, null)"
    >
      <div v-if="modalType === 'taxType'">
        <b-table
          striped
          hover
          outlined
          :items="taxTypeWithoutPoData"
          :fields="taxTypeWithoutPoFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedTaxTypeWithoutPo"
          style="max-height:calc(100vh - 200px)"
        >
        </b-table>
      </div>
      <div v-if="modalType === 'taxRate'">
        <b-table
          striped
          hover
          outlined
          :items="taxRateWithoutPoData"
          :fields="taxRateWithoutPoFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedTaxRateWithoutPo"
          style="max-height:calc(100vh - 100px)"
        >
          <template #head(tax_rate_percentage)="">
            <span>Tax Rate Percentage</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageTaxRateWithoutPo"
          :total-rows="totalRowsTaxRateWithoutPo"
          :per-page="perPageTaxRateWithoutPo"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-modal>
    <!-- b-modal for Invoice Without PO -->
    <b-modal
      v-model="showRemoveModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Remove"
      @hidden="showHideTaxRemoveModal(false)"
    >
      <template>
        <div style="margin: auto; margin-top: 100px; text-align: center;">
          <div>
            <p>Are you sure you want to remove these items?</p>
          </div>
          <div>
            <md-button
              class="md-raised md-warning search-btn"
              @click="removeNewDetails('Yes')"
              >YES</md-button
            >
            <md-button
              class="md-raised md-info search-btn"
              @click="removeNewDetails('No')"
              >NO</md-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <valueSetModal
      v-if="showValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      @closeValueSetModal="closeValueSetModal"
    ></valueSetModal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import commonHelper from "../../../utility/common.helper.utility";
import uploadDocument from "../../uploadDocument.vue";
import appStrings from "../../../utility/string.utility";
import emailModal from "./VendorInvoiceEmail.vue";
import HeaderSummary from "../HeaderSummary.vue";
export default {
  components: {
    DatePicker,
    uploadDocument,
    emailModal,
    HeaderSummary,
  },
  name: "VendorInvoiceEntry",
  watch: {
    currentPage: function() {
      this.getPoNumberDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPoNumberDetailsById();
    },
    currentPageGrid: function() {
      this.getVendorInvoiceDetails();
    },
    perPageGrid: function() {
      this.currentPageGrid = 1;
      this.getVendorInvoiceDetails();
    },
    currentPageLegalEntity: function() {
      this.getLegalEntityVendorInvoice();
    },
    perPageLegalEntity: function() {
      this.currentPageLegalEntity = 1;
      this.getLegalEntityVendorInvoice();
    },
    currentPageVendorGstn: function() {
      this.getVendorGstnVendorInvoice();
    },
    perPageVendorGstn: function() {
      this.currentPageVendorGstn = 1;
      this.getVendorGstnVendorInvoice();
    },
    currentPageCompanyGstn: function() {
      this.getCompanyGstnVendorInvoice();
    },
    perPageCompanyGstn: function() {
      this.currentPageCompanyGstn = 1;
      this.getCompanyGstnVendorInvoice();
    },
    currentPageCurrency: function() {
      this.getCurrencyVendorInvoice();
    },
    perPageCurrency: function() {
      this.currentPageCurrency = 1;
      this.getCurrencyVendorInvoice();
    },
    currentPageLocation: function() {
      this.getLocationVendorInvoice();
    },
    perPageLocation: function() {
      this.currentPageLocation = 1;
      this.getLocationVendorInvoice();
    },
    currentPageExpenseType: function() {
      this.getExpenseTypeVendorInvoice();
    },
    perPageExpenseType: function() {
      this.currentPageExpenseType = 1;
      this.getExpenseTypeVendorInvoice();
    },
    currentPageTaxRateWithoutPo: function() {
      this.getTaxRateVendorInvoice();
    },
    perPageTaxRateWithoutPo: function() {
      this.currentPageTaxRateWithoutPo = 1;
      this.getTaxRateVendorInvoice();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
  },
  data() {
    return {
      // Common Variables for Invoice Starting
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: commonHelper.getPageOption(),
      },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      vendorId: null,
      vendorName: null,
      venInvoiceId: 0,
      radio: false,
      showEmailModal: false,
      showDocumentModal: false,
      openKmAuth: null,
      menuId: 408,
      subMenuId: 84,
      approvalDetails: [],
      isApprovalSent: false,
      totalPayload: null,
      menuIdSummary: null,
      selectAllCheckBox: false,
      // Common Variables for Invoice Ending

      // View Vendor Invoice Variables Starting
      perPageGrid: commonHelper.perPageRecord,
      pageOptionsGrid: commonHelper.getPageOption(),
      currentPageGrid: 1,
      totalRowsGrid: null,
      searchFilters: {
        poNumber: null,
        invNumber: null,
        invAmountFrom: null,
        invAmountTo: null,
        fromDate: null,
        toDate: null,
        status: { text: null, value: null },
      },
      showValueSetModal: false,
      invoiceDetailsData: [],
      invoiceDetailsField: [
        // {
        //   key: "le_name",
        //   label: "Legal Entity",
        // },
        {
          key: "select",
          class: "stickyColumn col-sm-2",
        },
        {
          key: "po_num",
          label: "PO Number",
        },
        {
          key: "inv_num",
          label: "Invoice No.",
        },
        {
          key: "inv_amt",
          label: "Invoice Amount",
          class: "text-fix-invamt",
        },
        {
          key: "first_party_gstn",
          label: "Company GSTN",
        },
        {
          key: "third_party_gstn",
          label: "Third Party GSTN",
        },
        { key: "currency" },
        {
          key: "approval_status",
        },
        {
          key: "inv_date",
          label: "Invoice Date",
        },
      ],
      // View Vendor Invoice Variables Ending

      // Variables for Invoice With PO Starting
      poHeaderDetails: {
        po_header_id: null,
        po_number: null,
        inv_number: null,
        inv_amount: null,
        first_party_gstn: null,
        third_party_gstn: null,
        currency: null,
        approval_status: "DRAFT",
        dif_amount: null,
        payment_term: null,
        vendor_site_id: null,
        vendor_site_name: null,
        buyer_name: null,
        buyer_email_id: null,
        buyer_emp_id: null,
        inv_date: null,
        po_creation_date: null,
        org_id: null,
        po_status: null,
        grn_creator_name: null,
        grn_creator_email_id: null,
        grn_creator_id: null,
        revision_number: null,
        po_release_id: null,
      },
      poLineDetailsData: [],
      poLineDetailsFields: [
        {
          key: "select",
          class: "item-col",
          stickyColumn: true,
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        { key: "po_line_num", label: "PO Line No." },
        {
          key: "po_release_num",
          label: "PO Release",
        },
        {
          key: "po_shipment_line_num",
          label: "Shipment Line No.",
        },
        {
          key: "item_code",
        },
        {
          key: "item_description",
          label: "Description",
          class: "width-fix-des",
        },
        {
          key: "po_price",
          label: "Unit Price",
        },
        {
          key: "uom",
          label: "UOM",
        },
        {
          key: "quantity",
          label: "Total Qty.",
        },
        {
          key: "billed_pending",
        },
        {
          key: "pending_qty",
          label: "Pending Qty.",
        },
        {
          key: "deliver_qty",
          label: "Qty to be Delivered",
          class: "width-fix-qty",
        },
        {
          key: "amount",
          class: "width-fix-qty",
        },
        {
          key: "hsn_sac",
          label: "HSN/SAC",
        },
        {
          key: "NOTE_TO_VENDOR",
          label: "Note To Vendor",
          class: "width-fix-des",
        },
        {
          key: "shp_to_loc",
          label: "Ship To Loc",
        },
        {
          key: "release_line_from_date",
          label: "Release Start Date",
        },
        {
          key: "release_line_to_date",
          label: "Release End Date",
        },
        {
          key: "item_type",
        },
        {
          key: "tax_category_name",
          label: "Tax Category",
        },
        {
          key: "tax_details",
        },
        {
          key: "remarks",
        },
      ],
      newInvoiceDetailsData: [],
      newInvoiceDetailsFields: [
        {
          key: "select",
          class: "item-col",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        { key: "po_line_num", label: "PO Line No." },
        {
          key: "po_release_num",
          label: "PO Release",
        },
        {
          key: "po_shipment_line_num",
          label: "Shipment Line No.",
        },
        {
          key: "item_code",
        },
        {
          key: "item_description",
          label: "Description",
          class: "width-fix-des",
        },
        {
          key: "po_price",
          label: "Unit Price",
        },
        {
          key: "uom",
          label: "UOM",
        },
        {
          key: "deliver_qty",
          label: "Qty to be Delivered",
        },
        {
          key: "amount",
        },
        {
          key: "hsn_sac",
          label: "HSN/SAC",
        },
        {
          key: "NOTE_TO_VENDOR",
          label: "Note To Vendor",
          class: "width-fix-des",
        },
        {
          key: "shp_to_loc",
          label: "Ship To Loc",
        },
        {
          key: "release_line_from_date",
          label: "Release Start Date",
        },
        {
          key: "release_line_to_date",
          label: "Release End Date",
        },
        {
          key: "item_type",
        },
        {
          key: "tax_category_name",
          label: "Tax Category",
        },
        {
          key: "tax_amount",
        },
        {
          key: "tax_details",
        },
        {
          key: "remarks",
        },
      ],
      showPoModal: false,
      poNumberById: null,
      poNumberFields: [
        {
          key: "po_number",
          label: "PO Number",
          class: "poItem-col text-left",
        },
        {
          key: "po_status",
          label: "PO Status",
        },
        {
          key: "first_party_gstn",
          label: "Delhivery GSTN",
        },
        {
          key: "third_party_gstn",
          label: "Vendor GSTN",
        },
        {
          key: "payment_term",
        },
        {
          key: "vendor_site_name",
        },
        {
          key: "currency",
        },
        {
          key: "po_creation_date",
          label: "PO Creation Date",
        },
        {
          key: "period_from_date",
          label: "PO Rel. Start Date",
        },
        {
          key: "period_to_date",
          label: "PO Rel. End Date",
        },
      ],
      poNumberData: [],
      viewInvoice: true,
      taxDetailsData: [
        {
          tax_type: null,
          tax_type_id: null,
          tax_rate_id: null,
          tax_rate_type: null,
          taxable_amount: null,
          tax_rate_percentage: null,
          tax_amt: null,
          tax_category_id: null,
          tax_category_name: null,
          adhoc_tax_rate: null,
        },
      ],
      taxDetailsFields: [
        { key: "tax_type" },
        { key: "taxable_amount" },
        {
          key: "tax_rate_percentage",
          label: "Tax Rate",
          class: "tax-width-fix",
        },
        { key: "tax_amt", label: "Tax Amount", class: "tax-amt-fix" },
        {
          key: "tax_category_name",
          label: "Tax Category",
          class: "tax-cat-fix",
        },
        { key: "rcm_flag", label: "RCM", stickyColumn: true },
        { key: "remove", stickyColumn: true },
      ],
      showTaxModal: false,
      totalTaxableAmount: null,
      showTaxTypeModal: false,
      taxTypeData: [],
      taxTypeFields: [
        { key: "tax_type_id" },
        { key: "tax_rate_name", label: "Tax Type" },
      ],
      showTaxCategoryModal: false,
      taxCategoryData: [],
      taxCategoryFields: [
        { key: "tax_category_name", label: "Tax Category" },
        { key: "adhoc_tax_rate", label: "Tax Rate" },
      ],
      specIndex: null,
      previewTaxData: [],
      selectedRow: [],
      selectedRecords: 0,
      previewTaxFields: [
        { key: "tax_type" },
        { key: "taxable_amount" },
        { key: "tax_rate" },
        { key: "amount", label: "Tax Amount" },
        { key: "tax_category_name", label: "Tax Category" },
        { key: "rcm_flag", label: "RCM" },
      ],
      showPreviewTaxModal: false,
      sumIndex: null,
      lineAmount: null,
      qty_amt: null,
      taxType: { tax_type_1: null, tax_type_2: null },
      // Variables for Invoice With PO Ending

      // Variables for Invoice Without PO Starting
      invoiceWithoutPoData: [
        {
          // location: null,
          // expense_code: null,
          // expense_type: null,
          item_description: null,
          po_price: null,
          deliver_qty: 1,
          total_price: null,
          hsn_sac: null,
          tax_details: null,
          tax_amount: null,
        },
      ],
      invoiceWithoutPoFields: [
        {
          key: "add",
          stickyColumn: true,
        },
        { key: "remove", stickyColumn: true },
        // {
        //   key: "location",
        // },
        // {
        //   key: "expense_type",
        // },
        {
          key: "item_description",
          label: "Description",
        },
        {
          key: "po_price",
          label: "Unit Price",
        },
        {
          key: "deliver_qty",
          label: "Qty to be Delivered",
        },
        {
          key: "total_price",
        },
        {
          key: "hsn_sac",
          label: "HSN/SAC",
        },
        {
          key: "tax_amount",
        },
        {
          key: "tax_details",
        },
      ],
      invoiceHeader: {
        le_id: null,
        erp_le_id: null,
        le_name: null,
        currency: null,
        inv_amount: null,
        inv_date: null,
        company_gstn_name: null,
        company_state_code: null,
        vendor_gstn_name: null,
        vendor_state_code: null,
        approval_status: "DRAFT",
        dif_amount: null,
        inv_number: null,
        note: null,
        manual_employee_id: null,
        manual_employee_name: null,
        vendor_gstn_flag: null,
        round_off: false,
      },
      showInvoiceCommonModal: false,
      modalType: null,
      legalEntitySearch: "",
      legalEntityData: [],
      legalEntityFields: [
        {
          key: "name",
          label: "Legal Entity",
        },
      ],
      perPageLegalEntity: commonHelper.perPageRecord,
      currentPageLegalEntity: 1,
      totalRowsLegalEntity: null,
      companyGstnSearch: "",
      companyGstnData: [],
      companyGstnFields: [
        {
          key: "registration_number",
          label: "Company GST Number",
          class: "align-item-text",
        },
        {
          key: "state_code",
        },
      ],
      perPageCompanyGstn: commonHelper.perPageRecord,
      currentPageCompanyGstn: 1,
      totalRowsCompanyGstn: null,
      vendorGstnSearch: "",
      vendorGstnData: [],
      vendorGstnFields: [
        {
          key: "registration_number",
          label: "Vendor GST Number",
          class: "align-item-text",
        },
        {
          key: "state_code",
        },
      ],
      perPageVendorGstn: commonHelper.perPageRecord,
      currentPageVendorGstn: 1,
      totalRowsVendorGstn: null,
      currencySearch: "",
      currencyData: [],
      currencyFields: [
        {
          key: "currency_code",
          label: "Currency",
          class: "align-item-text",
        },
      ],
      perPageCurrency: commonHelper.perPageRecord,
      currentPageCurrency: 1,
      totalRowsCurrency: null,
      locationSearch: "",
      locationData: [],
      locationFields: [
        {
          key: "description",
          label: "Location",
          class: "align-item-text",
        },
      ],
      perPageLocation: commonHelper.perPageRecord,
      currentPageLocation: 1,
      totalRowsLocation: null,
      expenseTypeSearch: "",
      expenseTypeData: [],
      expenseTypeFields: [
        {
          key: "flex_value",
          label: "Expense Code",
        },
        {
          key: "description",
          label: "Expense Type",
          class: "align-item-text",
        },
      ],
      perPageExpenseType: commonHelper.perPageRecord,
      currentPageExpenseType: 1,
      totalRowsExpenseType: null,
      showTaxWithoutPoModal: false,
      taxWithoutPoData: [
        {
          tax_type_id: null,
          tax_type: null,
          taxable_amount: null,
          tax_rate_type: null,
          tax_amount: null,
        },
      ],
      taxWithoutPoFields: [
        { key: "tax_type" },
        { key: "taxable_amount" },
        { key: "tax_rate_type", label: "Tax Rate" },
        { key: "tax_amount" },
        { key: "remove", stickyColumn: true },
      ],
      taxTypeWithoutPoData: [],
      taxTypeWithoutPoFields: [
        { key: "tax_type_id" },
        { key: "tax_type_code", label: "Tax Type" },
      ],
      taxRateWithoutPoData: [],
      taxRateWithoutPoFields: [
        { key: "tax_rate_percentage", class: "align-item-text" },
      ],
      perPageTaxRateWithoutPo: commonHelper.perPageRecord,
      totalRowsTaxRateWithoutPo: null,
      currentPageTaxRateWithoutPo: 1,
      showTaxCommonModal: false,
      tempTaxData: [],
      finalResult: null,
      withoutPoIndex: null,
      userId: null,
      roundOffId: null,
      showRemoveModal: false,
      // Variables for Invoice Without PO Ending
    };
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.$route.query.params == "grid") {
  //     next();
  //   } else {
  //     if (this.poHeaderDetails.po_number) {
  //       const answer = window.confirm(
  //         "Do you really want to leave? you have unsaved changes!"
  //       );
  //       if (!answer) {
  //         return false;
  //       } else {
  //         next();
  //       }
  //     } else {
  //       next();
  //     }
  //   }
  // },
  mounted() {
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    this.vendorName = JSON.parse(sessionStorage.getItem("VendorName"));
    const menuItem = JSON.parse(sessionStorage.getItem("InvoiceNotBooked"));
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    if (this.$route.query.params && this.$route.query.params == "grid") {
      this.showViewInvoiceForm(false);
    }
    if (menuItem) {
      this.menuIdSummary = menuItem.menu_id;
    }
  },
  methods: {
    // isHeaderAvail() {
    //   const keysWithoutValue = {};
    //   const tempArr = [];
    //   for (const key in this.poHeaderDetails) {
    //     if (
    //       this.poHeaderDetails.hasOwnProperty(key) &&
    //       this.poHeaderDetails[key] === null
    //     ) {
    //       keysWithoutValue[key] = null;
    //       tempArr.push(key);
    //     }
    //   }
    // },
    // Invoice With PO Function Starting
    isHeaderAvail() {
      const tempArr = ["invNum", "invAmt", "invDate"];
      let msg = null;
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i] === "invNum" && !this.poHeaderDetails.inv_number) {
          msg = "Please Select Invoice Number";
        } else if (
          tempArr[i] === "invAmt" &&
          !this.poHeaderDetails.inv_amount
        ) {
          if (msg) {
            msg = msg + ", Invoice Amount";
          } else {
            msg = "Please Select Invoice Amount";
          }
        } else if (tempArr[i] === "invDate" && !this.poHeaderDetails.inv_date) {
          if (msg) {
            msg = msg + " and Invoice Date";
          } else {
            msg = "Please Select Invoice Date";
          }
        }
      }
      if (msg) {
        alert(msg);
      } else {
        return true;
      }
    },
    validateInvoiceDetails() {
      if (this.isHeaderAvail()) {
        if (this.poHeaderDetails.dif_amount == 0) {
          if (this.venInvoiceId > 0) {
            this.addEditVendorInvoiceDetails();
          } else {
            this.invoiceNumberValidation("with_po");
          }
        } else {
          alert("Invoice Difference Amount should be 0.");
        }
      }
    },
    addEditVendorInvoiceDetails() {
      if (this.isHeaderAvail()) {
        if (this.poHeaderDetails.dif_amount == 0) {
          const filterInvoiceDetails = this.newInvoiceDetailsData.map(
            (elem) => {
              return {
                vendor_inv_dtl_id: elem.vendor_inv_dtl_id
                  ? elem.vendor_inv_dtl_id
                  : 0,
                po_line_id: elem.po_line_id,
                po_line_num: elem.po_line_num,
                po_line_location_id: elem.po_line_location_id,
                po_release_id: elem.po_release_id,
                po_release_line_id: elem.po_release_line_id, //
                po_rel_num: elem.po_release_num,
                po_shipment_line_num: elem.po_shipment_line_num,
                item_id: elem.item_id,
                item_code: elem.item_code,
                po_item_price: elem.po_price,
                uom: elem.uom,
                po_item_qty: elem.quantity,
                deliver_item_qty: +elem.deliver_qty,
                pending_qty: elem.pending_qty,
                amount: elem.amount,
                hsn_sac: elem.hsn_sac,
                tax_cat_id: elem.tax_category_id,
                shp_to_loc: elem.shp_to_loc,
                release_line_from_date: elem.release_line_from_date,
                release_line_to_date: elem.release_line_to_date,
                item_type: elem.item_type,
                taxable_value: elem.taxable_value,
                tax_rate: elem.tax_rate,
                item_description: elem.item_description,
                po_distribution_id: elem.po_distribution_id,
                tax_category_name: elem.tax_category_name,
                tax_amount: Number(elem.tax_amount),
                tax_dtls: elem.tax_dtls,
                remarks: elem.remarks,
                manual_invoice: this.radio,
                note_to_vendor: elem.NOTE_TO_VENDOR,
                need_by_date: elem.need_by_date,
                days_early_receipt_allowed: elem.days_early_receipt_allowed,
                days_late_receipt_allowed: elem.days_late_receipt_allowed,
                receipt_days_exception_code: elem.receipt_days_exception_code,
                expected_early_delivery_date: elem.expected_early_delivery_date,
                expected_late_delivery_date: elem.expected_late_delivery_date,
                qty_amt: elem.qty_amt,
                code_combination_id: elem.code_combination_id,
                concatenated_segments: elem.concatenated_segments,
                period_from_date: elem.period_from_date,
                period_to_date: elem.period_to_date,
              };
            }
          );
          if (filterInvoiceDetails.length) {
            const payload = {
              vendor_id: this.vendorId,
              vendor_name: this.vendorName,
              vendor_invoice_id: this.venInvoiceId,
              po_header_id: this.poHeaderDetails.po_header_id,
              po_num: this.poHeaderDetails.po_number,
              inv_num: this.poHeaderDetails.inv_number,
              inv_amt: this.poHeaderDetails.inv_amount,
              inv_date: this.poHeaderDetails.inv_date,
              first_party_gstn: this.poHeaderDetails.first_party_gstn,
              third_party_gstn: this.poHeaderDetails.third_party_gstn,
              po_creation_date: this.poHeaderDetails.po_creation_date,
              currency: this.poHeaderDetails.currency,
              payment_term: this.poHeaderDetails.payment_term,
              inv_agreement: this.radio,
              approval_status: this.poHeaderDetails.approval_status,
              dif_amount: Number(this.poHeaderDetails.dif_amount),
              ou_id: this.poHeaderDetails.org_id,
              vendor_site_id: this.poHeaderDetails.vendor_site_id,
              vendor_site_name: this.poHeaderDetails.vendor_site_name,
              buyer_name: this.poHeaderDetails.buyer_name,
              buyer_email_id: this.poHeaderDetails.buyer_email_id,
              buyer_emp_id: this.poHeaderDetails.buyer_emp_id,
              grn_creator_name: this.poHeaderDetails.grn_creator_name,
              grn_creator_email_id: this.poHeaderDetails.grn_creator_email_id,
              grn_creator_id: this.poHeaderDetails.grn_creator_id,
              po_status: this.poHeaderDetails.po_status,
              revision_number: this.poHeaderDetails.revision_number,
              po_release_id: this.poHeaderDetails.po_release_id,
              vendor_invoice_dtls: filterInvoiceDetails
                ? filterInvoiceDetails
                : null,
            };
            this.loader = true;
            this.$store
              .dispatch("vendor/addEditVendorInvoiceDetails", payload)
              .then((response) => {
                this.loader = false;
                if (response.status === 200) {
                  const result = response.data.data;
                  if (result) {
                    if (this.venInvoiceId == 0) {
                      alert("Invoice Created Successfully");
                    } else {
                      alert("Invoice Modify Successfully");
                    }
                    this.venInvoiceId = result.vendor_inv_id;
                    this.poHeaderDetails.po_number = result.po_num;
                    this.testingFunction("invoiceRowDtls", this.venInvoiceId);
                  }
                } else {
                  alert(response.data.message);
                }
              })
              .catch(() => {
                alert(response.data.message);
              });
          } else {
            alert("Please select any invoice.");
          }
        } else {
          alert("Invoice Difference Amount should be 0.");
        }
      }
    },
    getPoNumberDetailsById() {
      const payload = {
        vendor_id: this.vendorId,
        po_number: this.poNumberById,
        _page: this.currentPage - 1,
        _limit: this.perPage,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getPoNumberDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.poNumberData = result;
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // addMultiRow() {
    //   const filterRow = this.poLineDetailsData.filter((data) => data.select);
    //   const unfilterRow = this.poLineDetailsData.filter((data) => !data.select);
    //   if (filterRow.length) {
    //     const filterDeliveryQty = filterRow.filter((elem) => !elem.deliver_qty);
    //     if (filterDeliveryQty.length) {
    //       alert("Please Enter Deliver Qty for Selected Invoice.");
    //     } else {
    //       const isTaxDtlsAvail = filterRow.filter(
    //         (ele) => ele.tax_dtls && ele.tax_dtls == null
    //       );
    //       if (isTaxDtlsAvail.length) {
    //         this.poLineDetailsData = unfilterRow;
    //         const tempData = filterRow.map((elem) => {
    //           elem.select = false;
    //           // elem.taxable_value = elem.deliver_qty * elem.po_price;
    //           // elem.igst = (elem.taxable_value * elem.tax_rate) / 100;
    //           // elem.sgst = elem.igst / 2;
    //           // elem.cgst = elem.igst / 2;
    //           return elem;
    //         });
    //         this.newInvoiceDetailsData.push(...tempData);
    //       } else {
    //         const isTaxAvail = filterRow.filter((ele) => !ele.tax_amount);
    //         if (isTaxAvail.length) {
    //           alert("Please Select Tax Details for Selected Invoice.");
    //         } else {
    //           const checkHsn = filterRow.filter(
    //             (ele) => ele.hsn_sac.length < 6 || ele.hsn_sac.length > 8
    //           );
    //           if (checkHsn.length) {
    //             alert("HSN Code Should between 6 to 8 digits.");
    //           } else {
    //             this.poLineDetailsData = unfilterRow;
    //             const tempData = filterRow.map((elem) => {
    //               elem.select = false;
    //               // elem.taxable_value = elem.deliver_qty * elem.po_price;
    //               // elem.igst = (elem.taxable_value * elem.tax_rate) / 100;
    //               // elem.sgst = elem.igst / 2;
    //               // elem.cgst = elem.igst / 2;
    //               return elem;
    //             });
    //             this.newInvoiceDetailsData.push(...tempData);
    //             this.calculateInvoiceAmount();
    //           }
    //         }
    //       }
    //     }
    //   } else {
    //     alert("Please Select PO Line Details");
    //   }
    // },
    addMultiRow() {
      const selectedRows = this.poLineDetailsData.filter((data) => data.select);
      if (selectedRows.length === 0) {
        alert("Please Select PO Line Details");
        return;
      }
      const missingDeliveryQty = selectedRows.some((elem) => !elem.deliver_qty);
      if (missingDeliveryQty) {
        alert("Please Enter Deliver Qty for Selected Invoice.");
        return;
      }
      for (let i = 0; i < selectedRows.length; i++) {
        if (!selectedRows[i].tax_amt_not_avail) {
          const missingTaxDetails = selectedRows.some((ele) => !ele.tax_amount);
          if (missingTaxDetails) {
            alert("Please Apply Tax Details for Selected Invoice.");
            return;
          }
        }
      }
      const invalidHsn = selectedRows.some(
        (ele) => ele.hsn_sac.length < 6 || ele.hsn_sac.length > 8
      );
      if (invalidHsn) {
        alert("HSN Code Should be between 6 to 8 digits.");
        return;
      }
      const unselectedRows = this.poLineDetailsData.filter(
        (data) => !data.select
      );
      this.poLineDetailsData = unselectedRows;
      const deselectedRows = selectedRows.map((elem) => {
        elem.select = false;
        return elem;
      });
      this.newInvoiceDetailsData.push(...deselectedRows);
      this.calculateInvoiceAmount();
    },
    applyDefaultTaxes(payload, unselectedRows, selectedRows, index) {
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result.length) {
              this.poLineDetailsData[index].tax_dtls = result;
            } else {
              this.poLineDetailsData[index].tax_dtls = null;
            }
            this.poLineDetailsData = unselectedRows;
            const deselectedRows = selectedRows.map((elem) => {
              elem.select = false;
              return elem;
            });
            this.newInvoiceDetailsData.push(...deselectedRows);
            // this.taxDetailsData = result;
            // this.taxDetailsData = result.map((elem) => {
            //   elem.taxable_amount = item.amount;
            //   return elem;
            // });
            // if (flag) {
            //   this.taxDetailsApplyDefault(result, item.index);
            // }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    //
    addMultiRows() {
      // First old function not using this function
      const selectedRows = this.poLineDetailsData.filter((data) => data.select);
      if (selectedRows.length === 0) {
        alert("Please Select PO Line Details");
        return;
      }
      const missingDeliveryQty = selectedRows.some((elem) => !elem.deliver_qty);
      if (missingDeliveryQty) {
        alert("Please Enter Deliver Qty for Selected Invoice.");
        return;
      }
      for (let i = 0; i < selectedRows.length; i++) {
        if (!selectedRows[i].tax_amt_not_avail) {
          const missingTaxDetails = selectedRows.some((ele) => !ele.tax_amount);
          if (missingTaxDetails) {
            alert("Please Select Tax Details for Selected Invoice.");
            return;
          }
        }
      }
      const invalidHsn = selectedRows.some(
        (ele) => ele.hsn_sac.length < 6 || ele.hsn_sac.length > 8
      );
      if (invalidHsn) {
        alert("HSN Code Should be between 6 to 8 digits.");
        return;
      }
      const unselectedRows = this.poLineDetailsData.filter(
        (data) => !data.select
      );
      // const deselectedRows = selectedRows.map((elem) => {
      //   elem.select = false;
      //   return elem;
      // });
      const errorMsg = [];
      for (var i = 0; i < selectedRows.length; i++) {
        if (
          this.isCurrentDateInRange(
            selectedRows[i].expected_early_delivery_date,
            selectedRows[i].expected_late_delivery_date
          )
        ) {
          this.newInvoiceDetailsData.push(selectedRows[i]);
          this.poLineDetailsData = unselectedRows;
          this.calculateInvoiceAmount();
        } else {
          errorMsg.push(
            `Kindly, contact to the buyer for change the need date in the PO Line ID :- ${selectedRows[i].po_line_id}`
          );
        }
      }
      if (errorMsg.length) {
        let errorMsg1 =
          "Kindly, contact to the buyer for change the need date in the PO";
        // errorMsg.forEach((item) => {
        //   errorMsg1 += item + "\n";
        // });
        alert(errorMsg1);
      }
      this.newInvoiceDetailsData.map((elem) => {
        elem.select = false;
        return elem;
      });
      // this.newInvoiceDetailsData.push(...deselectedRows);
    },
    isCurrentDateInRange(earlyDate, lateDate) {
      var currentDate = new Date();
      var earlyDeliveryDate = new Date(earlyDate);
      var lateDeliveryDate = new Date(lateDate);
      return (
        currentDate >= earlyDeliveryDate && currentDate <= lateDeliveryDate
      );
    },
    addMultiRowss() {
      // Second old function not using this function
      const filterRow = this.poLineDetailsData.filter((data) => data.select);
      const unfilterRow = this.poLineDetailsData.filter((data) => !data.select);
      if (filterRow.length) {
        const filterDeliveryQty = filterRow.filter((elem) => !elem.deliver_qty);
        if (filterDeliveryQty.length) {
          alert("Please Enter Deliver Qty for Selected Invoice.");
        } else {
          const isTaxAvail = filterRow.filter((ele) => !ele.tax_amount);
          if (isTaxAvail.length) {
            alert("Please Select Tax Details for Selected Invoice.");
          } else {
            const checkHsn = filterRow.filter(
              (ele) => ele.hsn_sac.length < 6 || ele.hsn_sac.length > 8
            );
            if (checkHsn.length) {
              alert("HSN Code Should between 6 to 8 digits.");
            } else {
              this.poLineDetailsData = unfilterRow;
              const tempData = filterRow.map((elem) => {
                elem.select = false;
                // elem.taxable_value = elem.deliver_qty * elem.po_price;
                // elem.igst = (elem.taxable_value * elem.tax_rate) / 100;
                // elem.sgst = elem.igst / 2;
                // elem.cgst = elem.igst / 2;
                return elem;
              });
              this.newInvoiceDetailsData.push(...tempData);
              this.calculateInvoiceAmount();
            }
          }
        }
      } else {
        alert("Please Select PO Line Details");
      }
    },
    removeMultiRow() {
      const filterRow = this.newInvoiceDetailsData.filter(
        (data) => data.select
      );
      const unfilterRow = this.newInvoiceDetailsData.filter(
        (data) => !data.select
      );
      if (filterRow.length) {
        // this.newInvoiceDetailsData = unfilterRow;
        // const tempData = filterRow.map((elem) => {
        //   elem.select = false;
        //   return elem;
        // });
        // this.poLineDetailsData.push(...tempData);
        this.showHideTaxRemoveModal(true, filterRow, unfilterRow);
      } else {
        alert("Please Select Invoice");
      }
    },
    validateDeliverQty(item, index) {
      if (this.poHeaderDetails.inv_amount) {
        if (+item.deliver_qty > +item.pending_qty) {
          alert("Deliver Qty. should be Less than & Equal To Pending Qty.");
          item.deliver_qty = null;
        } else {
          if (item.deliver_qty) {
            if (item.deliver_qty > 0) {
              item.amount = +item.deliver_qty * +item.po_price;
              item.amount = item.amount.toFixed(2);
              item.select = true;
              item.index = index;
              this.poLineDetailsData = JSON.parse(
                JSON.stringify(this.poLineDetailsData)
              );
            } else {
              alert("Deliver Qty. should be greater than 0");
            }
          } else {
            item.amount = null;
            item.select = false;
          }
        }
      } else {
        item.deliver_qty = null;
        alert("Please Enter Invoice Amount First.");
      }
    },
    validateAmountCalculation(item, index) {
      if (this.poHeaderDetails.inv_amount) {
        if (item.amount) {
          const itemQty = item.amount / item.po_price;
          if (+itemQty.toFixed(10) > +item.pending_qty) {
            alert("Invalid Amount. Kindly enter amount as per Pending Qty.");
            item.amount = null;
          } else {
            if (item.amount > 0) {
              item.select = true;
              item.index = index;
              item.deliver_qty = itemQty.toFixed(10);
              this.poLineDetailsData = JSON.parse(
                JSON.stringify(this.poLineDetailsData)
              );
            } else {
              item.amount = null;
              alert("Amount should be greater than 0");
            }
          }
        } else {
          item.deliver_qty = null;
          item.select = false;
        }
      } else {
        item.amount = null;
        alert("Please Enter Invoice Amount First.");
      }
    },
    showHidePoModal(flag) {
      this.showPoModal = flag;
      if (flag) {
        this.poNumberById = null;
        this.getPoNumberDetailsById();
      }
    },
    selectBoxChecked(flag, index, item, poLineId) {
      if (flag) {
        if (item.deliver_qty) {
          item.index = index;
          this.selectedRow.push(item);
          this.selectedRecords = this.selectedRecords + 1;
        } else {
          alert("Please Enter Delivery Qty.");
          item.select = false;
        }
      } else {
        this.selectedRow = this.selectedRow.filter(
          (el) => el.po_line_id !== poLineId
        );
        this.selectedRecords = this.selectedRecords - 1;
      }
    },
    applyDefaultTax() {
      const filterData = this.poLineDetailsData.filter((ele) => ele.select);
      if (filterData.length) {
        let xflag = false;
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].deliver_qty) {
            this.getTaxInvoiceDetails(filterData[i], true);
          } else {
            xflag = true;
            break;
          }
        }
        if (xflag) {
          alert("Please Enter Deliver Qty.");
          xflag = false;
        }
      } else {
        alert("Kindly Select Any Invoice");
      }
    },
    taxDetailsApplyDefault(array, index) {
      if (array.length) {
        const filterTaxAmt = array.filter(
          (ele) => ele.tax_amt !== undefined && ele.tax_amt !== null
        );
        const result = filterTaxAmt.map((elem) => elem.tax_amt);
        const total = commonHelper.calculateTotal(result);
        const filterAdhocTaxRate = array.filter(
          (data) => data.tax_rate_type == "ADHOC_AMOUNT"
        );
        const adhocRateTotal = filterAdhocTaxRate
          .map((data) => data.taxable_amount)
          .filter((amount) => amount !== undefined && amount !== null);
        const adhocTotal = adhocRateTotal.length
          ? commonHelper.calculateTotal(adhocRateTotal)
          : 0;
        if (total) {
          const totalAdhoc = total + +adhocTotal;
          // this.poLineDetailsData[index].tax_amount = parseFloat(
          //   totalAdhoc
          // ).toFixed(10);
          this.poLineDetailsData[index].tax_amount = parseFloat(
            totalAdhoc
          ).toFixed(2);
        } else {
          this.poLineDetailsData[index].tax_amount = parseFloat(0).toFixed(2);
        }
        const taxDetails = array.map((elem) => {
          return {
            vendor_inv_tax_id: elem.vendor_inv_tax_id
              ? elem.vendor_inv_tax_id
              : elem.vnd_inv_tax_id
              ? elem.vnd_inv_tax_id
              : 0,
            tax_rate_id: elem.tax_rate_id,
            tax_rate: elem.tax_rate_percentage,
            tax_type_id: elem.tax_type_id,
            tax_type: elem.tax_type,
            tax_rate_type: elem.tax_rate_type,
            rcm_flag: elem.rcm_flag,
            tax_category_id: elem.tax_category_id,
            tax_category_name: elem.tax_category_name,
            amount: parseFloat(elem.tax_amt).toFixed(10),
            taxable_amount: parseFloat(elem.taxable_amount).toFixed(10),
            manual: elem.manual == "Y" ? "Y" : elem.manual == "N" ? "N" : null,
          };
        });
        if (taxDetails.length) {
          this.poLineDetailsData[index].tax_dtls = taxDetails;
        }
      } else {
        this.poLineDetailsData[index].tax_dtls = [];
        this.poLineDetailsData[index].tax_amt_not_avail = true;
      }
    },
    rowSelectedPoNumber(item) {
      if (item.po_status === "NOT APPROVED") {
        alert("PO is not approved. Please select Approved PO");
      } else {
        this.poHeaderDetails.po_header_id = item.po_header_id;
        this.poHeaderDetails.po_number = item.po_number;
        this.poHeaderDetails.first_party_gstn = item.first_party_gstn;
        this.poHeaderDetails.third_party_gstn = item.third_party_gstn;
        this.poHeaderDetails.currency = item.currency;
        this.poHeaderDetails.payment_term = item.payment_term;
        this.poHeaderDetails.po_creation_date = item.po_creation_date;
        this.poHeaderDetails.org_id = item.org_id;
        this.poHeaderDetails.vendor_site_id = item.vendor_site_id;
        this.poHeaderDetails.vendor_site_name = item.vendor_site_name;
        this.poHeaderDetails.buyer_name = item.buyer_name;
        this.poHeaderDetails.buyer_email_id = item.buyer_email_id;
        this.poHeaderDetails.buyer_emp_id = item.buyer_emp_id;
        this.poHeaderDetails.po_status = item.po_status;
        this.poHeaderDetails.grn_creator_name = item.grn_creator_name;
        this.poHeaderDetails.grn_creator_email_id = item.grn_creator_email_id;
        this.poHeaderDetails.grn_creator_id = item.grn_creator_id;
        this.poHeaderDetails.revision_number = item.revision_number;
        this.poHeaderDetails.po_release_id = item.po_release_id;
        this.poHeaderDetails.inv_number = null;
        this.poHeaderDetails.inv_amount = null;
        this.poHeaderDetails.dif_amount = null;
        this.poHeaderDetails.inv_date = null;
        this.poHeaderDetails.inv_amount = "0";
        this.selectedRow = [];
        this.showPoModal = false;
        this.testingFunction("poRowDtls");
      }
    },
    clearSearch() {
      this.poNumberById = null;
      this.getPoNumberDetailsById();
    },
    clearPoValues() {
      this.clearFieldsSwitch();
    },
    clearSwitch(type) {
      if (type) {
        this.clearFieldsSwitch();
      } else {
        this.clearWithoutPoFields();
      }
    },
    disabledDates(date) {
      const restrictedFromDate = new Date(
        this.poHeaderDetails.po_creation_date
      );
      restrictedFromDate.setHours(0, 0, 0, 0);
      const restrictedToDate = new Date();
      restrictedToDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate || date > restrictedToDate;
    },
    showViewInvoiceForm(flag) {
      this.viewInvoice = flag;
      if (!flag) {
        this.getVendorInvoiceDetails();
      }
      this.clearFieldsSwitch();
    },
    getVendorInvoiceDetails() {
      const payload = {
        _page: this.currentPageGrid - 1,
        _limit: this.perPageGrid,
        po_num: this.searchFilters.poNumber,
        inv_num: this.searchFilters.invNumber,
        inv_amt_from: this.searchFilters.invAmountFrom,
        inv_amt_to: this.searchFilters.invAmountTo,
        approval_status: this.searchFilters.status.value,
        vendor_id: this.vendorId,
        from_date: this.searchFilters.fromDate,
        to_date: this.searchFilters.toDate,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.invoiceDetailsData = result;
            this.totalRowsGrid = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRowsGrid;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceDetails", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.page.map((elem) => {
            elem.inv_amt =
              elem.inv_amt !== 0
                ? commonHelper.formatAmount(elem.inv_amt)
                : elem.inv_amt;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.le_name,
            po_number: obj.po_num,
            invoice_no: obj.inv_num,
            invoice_amount: obj.inv_amt,
            company_gstn: obj.first_party_gstn,
            third_party_gstn: obj.third_party_gstn,
            currency: obj.currency,
            approval_status: obj.approval_status,
            invoice_date: obj.inv_date,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "pending-invoice");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      this.searchFilters = {
        poNumber: null,
        invNumber: null,
        invAmountFrom: null,
        invAmountTo: null,
        status: { text: null, value: null },
        fromDate: null,
        toDate: null,
      };
      this.getVendorInvoiceDetails();
    },
    getVendorInvoiceDetailsWithoutPo(invHdrId) {
      const payload = {
        invHdrId: invHdrId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceLineDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.venInvoiceId = result.vendor_inv_id;
            this.invoiceHeader.le_name = result.le_name;
            // this.invoiceHeader.le_id = result.le_id;
            this.invoiceHeader.erp_le_id = result.erp_le_id;
            this.invoiceHeader.currency = result.currency;
            this.invoiceHeader.inv_amount = result.inv_amt;
            this.invoiceHeader.inv_date = result.inv_date;
            this.invoiceHeader.company_gstn_name = result.first_party_gstn;
            this.invoiceHeader.vendor_gstn_name = result.third_party_gstn;
            this.invoiceHeader.company_state_code = result.state_code;
            this.invoiceHeader.vendor_state_code = result.vendor_state_code;
            this.invoiceHeader.approval_status = result.approval_status;
            this.invoiceHeader.dif_amount = result.dif_amount;
            this.invoiceHeader.inv_number = result.inv_num;
            this.invoiceHeader.note = result.note;
            this.invoiceHeader.manual_employee_id = result.manual_employee_id;
            this.invoiceHeader.vendor_gstn_flag = result.vendor_gstn_flag;
            this.invoiceHeader.manual_employee_name = result.manual_emp_name;
            this.invoiceHeader.rejection_remarks_comments =
              result.rejection_remarks_comments;
            this.invoiceWithoutPoData = result.invoice_dtls.map((elem) => {
              elem.total_price = elem.po_price * elem.deliver_qty;
              elem.total_price = parseFloat(elem.total_price).toFixed(3);
              elem.total_price = Math.round(elem.total_price*100)/100;
              return elem;
            });
            const isRoundOffAvail = this.invoiceWithoutPoData.some(
              (item) =>
                item.item_description &&
                item.item_description.includes("Round-off")
            );
            if (isRoundOffAvail) {
              this.invoiceHeader.round_off = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorInvoiceLineDetails(invHdrId, poLineData) {
      const payload = {
        invHdrId: invHdrId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceLineDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.poHeaderDetails.po_header_id = result.po_header_id;
            this.poHeaderDetails.po_number = result.po_num;
            this.poHeaderDetails.inv_number = result.inv_num;
            this.poHeaderDetails.inv_amount = result.inv_amt;
            this.poHeaderDetails.first_party_gstn = result.first_party_gstn;
            this.poHeaderDetails.third_party_gstn = result.third_party_gstn;
            this.poHeaderDetails.currency = result.currency;
            this.poHeaderDetails.approval_status = result.approval_status;
            this.poHeaderDetails.payment_term = result.payment_term;
            this.poHeaderDetails.inv_date = result.inv_date;
            this.poHeaderDetails.po_creation_date = result.po_creation_date;
            this.poHeaderDetails.org_id = result.ou_id;
            this.poHeaderDetails.dif_amount = result.dif_amount;
            this.poHeaderDetails.vendor_site_id = result.vendor_site_id;
            this.poHeaderDetails.vendor_site_name = result.vendor_site_name;
            this.poHeaderDetails.buyer_name = result.buyer_name;
            this.poHeaderDetails.buyer_email_id = result.buyer_email_id;
            this.poHeaderDetails.buyer_emp_id = result.buyer_emp_id;
            this.poHeaderDetails.grn_creator_name = result.grn_creator_name;
            this.poHeaderDetails.revision_number = result.revision_number;
            this.poHeaderDetails.po_release_id = result.po_release_id;
            this.poHeaderDetails.grn_creator_email_id =
              result.grn_creator_email_id;
            this.poHeaderDetails.grn_creator_id = result.grn_creator_id;
            this.poHeaderDetails.po_status = result.po_status;
            this.poHeaderDetails.rejection_remarks_comments =
              result.rejection_remarks_comments;
            this.venInvoiceId = result.vendor_inv_id;
            const invoiceLineDetailsTesting = result.invoice_dtls;
            this.mapingPoInvoiceDtlsData(invoiceLineDetailsTesting, poLineData);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mapingPoInvoiceDtlsData(invLineData, poLineData) {
      const invLineMapData = invLineData.map((ele) => ele.po_line_id);
      const invLineIdsSet = new Set(invLineMapData);
      const filteredArray = poLineData.filter(
        (ele) => !invLineIdsSet.has(ele.po_line_id)
      );
      this.newInvoiceDetailsData = invLineData;
      this.poLineDetailsData = filteredArray;
    },
    clearFieldsSwitch() {
      this.venInvoiceId = 0;
      this.poHeaderDetails = {
        po_header_id: null,
        po_number: null,
        inv_number: null,
        inv_amount: null,
        first_party_gstn: null,
        third_party_gstn: null,
        currency: null,
        approval_status: "DRAFT",
        dif_amount: null,
        payment_term: null,
        inv_date: null,
        po_creation_date: null,
        org_id: null,
        vendor_site_id: null,
        vendor_site_name: null,
        buyer_name: null,
        buyer_email_id: null,
        buyer_emp_id: null,
        grn_creator_name: null,
        grn_creator_id: null,
        grn_creator_email_id: null,
        po_status: null,
        revision_number: null,
        po_release_id: null,
      };
      this.selectedRow = [];
      this.poLineDetailsData = [];
      this.newInvoiceDetailsData = [];
    },
    showHideTaxModal(flag, item, index) {
      if (flag) {
        if (item.deliver_qty) {
          this.showTaxModal = flag;
          this.sumIndex = index;
          if (item.vendor_inv_dtl_id) {
            this.taxDetailsData = item.tax_details;
            this.taxDetailsData = item.tax_details.map((elem) => {
              if (elem.manual !== "Y") {
                elem.taxable_amount = (
                  item.deliver_qty * item.po_price
                ).toFixed(2);
                elem.tax_rate_percentage = elem.tax_rate;
              } else {
                elem.taxable_amount = null;
              }
              elem.tax_amt = elem.amount;
              // elem.tax_amt =
              //   (elem.taxable_amount * elem.tax_rate_percentage) / 100;
              return elem;
            });
          } else {
            this.getTaxInvoiceDetails(item);
          }
        } else {
          alert("Please Enter Deliver Qty.");
        }
      }
    },
    getTaxInvoiceDetails(item, flag) {
      this.lineAmount = item.amount;
      const payload = {
        po_line_id: item.po_line_id,
        line_amount: item.amount,
        po_release_id: item.po_release_id,
        line_location_id: item.po_line_location_id
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.taxDetailsData = result;
            this.taxDetailsData = result.map((elem) => {
              if (elem.tax_rate_type === "ADHOC_AMOUNT") {
                if (elem.adhoc_amount) {
                  elem.taxable_amount = elem.adhoc_amount;
                } else {
                  elem.taxable_amount = 0;
                }
              } else {
                elem.taxable_amount = item.amount;
              }
              // elem.taxable_amount = item.deliver_qty * item.po_price;
              // elem.taxable_amount = elem.taxable_amount.toFixed(2);
              // elem.tax_amt =
              //   (elem.taxable_amount * elem.tax_rate_percentage) / 100;
              // elem.tax_amt = elem.tax_amt.toFixed(2);
              return elem;
            });
            const hasIGST = this.taxDetailsData.some(
              (item) => item.tax_type === "IGST"
            );
            const hasCGSTandSGST =
              this.taxDetailsData.some((item) => item.tax_type === "CGST") &&
              this.taxDetailsData.some((item) => item.tax_type === "SGST");
            const hasCGSTandUGST =
              this.taxDetailsData.some((item) => item.tax_type === "CGST") &&
              this.taxDetailsData.some((item) => item.tax_type === "UGST");
            if (hasIGST && !hasCGSTandSGST && !hasCGSTandUGST) {
              this.taxType = { tax_type_1: "IGST", tax_type_2: null };
            } else if (!hasIGST && hasCGSTandSGST && !hasCGSTandUGST) {
              this.taxType = { tax_type_1: "CGST", tax_type_2: "SGST" };
            } else if (!hasIGST && !hasCGSTandSGST && hasCGSTandUGST) {
              this.taxType = { tax_type_1: "CGST", tax_type_2: "UGST" };
            }
            if (flag) {
              this.taxDetailsApplyDefault(result, item.index);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addTaxRow() {
      this.taxDetailsData.push({
        tax_type: null,
        tax_type_id: null,
        tax_rate_id: null,
        taxable_amount: null,
        tax_rate_percentage: null,
        tax_amt: null,
        tax_rate_type: null,
        manual: "Y",
      });
    },
    removeTaxRow(index) {
      this.taxDetailsData.splice(index, 1);
    },
    addEditTaxDetails() {
      const filterTaxAmt = this.taxDetailsData.filter(
        (ele) => ele.tax_amt !== undefined && ele.tax_amt !== null
      );
      const result = filterTaxAmt.map((elem) => elem.tax_amt);
      const total = commonHelper.calculateTotal(result);
      const filterAdhocTaxRate = this.taxDetailsData.filter(
        (data) => data.tax_rate_type == "ADHOC_AMOUNT"
      );
      const adhocRateTotal = filterAdhocTaxRate
        .map((data) => data.taxable_amount)
        .filter((amount) => amount !== undefined && amount !== null);
      const adhocTotal = adhocRateTotal.length
        ? commonHelper.calculateTotal(adhocRateTotal)
        : 0;
      if (total) {
        const totalAdhoc = +total + +adhocTotal;
        // this.poLineDetailsData[this.sumIndex].tax_amount = parseFloat(
        //   totalAdhoc
        // ).toFixed(10);
        this.poLineDetailsData[this.sumIndex].tax_amount = parseFloat(
          totalAdhoc
        ).toFixed(2);
      } else {
        this.poLineDetailsData[this.sumIndex].tax_amount = parseFloat(
          +adhocTotal
        ).toFixed(2);
      }
      const filterManualTax = this.taxDetailsData.filter((ele) => {
        if (
          ele.manual === "Y" &&
          (ele.tax_type == null ||
            ele.tax_rate_percentage == null ||
            ele.tax_amt == null)
        ) {
          return ele;
        }
      });
      if (filterManualTax.length) {
        alert("Tax Type, Tax Rate and Tax Amount Mandatory");
      } else {
        const taxDetails = this.taxDetailsData.map((elem) => {
          return {
            vendor_inv_tax_id: elem.vendor_inv_tax_id
              ? elem.vendor_inv_tax_id
              : elem.vnd_inv_tax_id
              ? elem.vnd_inv_tax_id
              : 0,
            tax_rate_id: elem.tax_rate_id,
            tax_rate: elem.tax_rate_percentage,
            tax_type_id: elem.tax_type_id,
            tax_type: elem.tax_type,
            tax_rate_type: elem.tax_rate_type,
            rcm_flag: elem.rcm_flag,
            tax_category_id: elem.tax_category_id,
            tax_category_name: elem.tax_category_name,
            amount: elem.tax_amt
              ? parseFloat(elem.tax_amt).toFixed(2)
              : elem.tax_amt,
            taxable_amount: elem.taxable_amount
              ? parseFloat(elem.taxable_amount).toFixed(2)
              : elem.taxable_amount,
            // amount: parseFloat(elem.tax_amt).toFixed(10),
            // taxable_amount: parseFloat(elem.taxable_amount).toFixed(10),
            manual: elem.manual == "Y" ? "Y" : elem.manual == "N" ? "N" : null,
          };
        });
        if (taxDetails.length) {
          this.poLineDetailsData[this.sumIndex].tax_dtls = taxDetails;
          this.showTaxModal = false;
        }
      }
    },
    showHideTaxTypeModal(flag, index) {
      this.showTaxTypeModal = flag;
      this.specIndex = index;
      if (flag) {
        this.getTaxTypeValueSet();
      }
    },
    showHideTaxCategoryModal(flag, index) {
      this.showTaxCategoryModal = flag;
      this.specIndex = index;
      if (flag) {
        this.getAdhocTaxDtls();
      }
    },
    getAdhocTaxDtls() {
      const payload = {
        _page: 0,
        _limit: 50,
        org_id: this.poHeaderDetails.org_id,
        tax_type_1: this.taxType.tax_type_1,
        tax_type_2: this.taxType.tax_type_2,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdhocTaxDtls", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.taxCategoryData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTaxTypeValueSet() {
      const payload = {
        org_id: this.poHeaderDetails.org_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxTypeValueSet", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.taxTypeData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedTaxType(item) {
      // const isTcsDuplicate = this.taxDetailsData.filter(
      //   (ele) => ele.tax_rate_type === "TCS"
      // );
      // if (isTcsDuplicate.length > 0) {
      //   alert("TCS already applied");
      // } else {
      this.taxDetailsData[this.specIndex].tax_type = item.tax_rate_name;
      this.taxDetailsData[this.specIndex].tax_rate_id = item.tax_rate_id;
      this.taxDetailsData[this.specIndex].tax_type_id = item.tax_type_id;
      this.taxDetailsData[this.specIndex].tax_rate_type = item.tax_rate_type;

      if (item.tax_rate_type === "TCS") {
        this.taxDetailsData[this.specIndex].tax_rate_percentage =
          item.tax_rate_percentage;
      }
      // if (item.tax_rate_type === "TCS") {
      //   const filterTaxableAmt = this.taxDetailsData
      //     .filter((ele) => {
      //       if (
      //         ele.tax_type !== "CGST" &&
      //         ele.tax_type !== "SGST" &&
      //         ele.tax_type !== "UGST" &&
      //         ele.tax_type !== "IGST"
      //       ) {
      //         return ele.taxable_amount;
      //       }
      //     })
      //     .map((data) => data.taxable_amount);
      //   const totalTaxableAmt = filterTaxableAmt.length
      //     ? commonHelper.calculateTotal(filterTaxableAmt)
      //     : 0;
      //   const filterTaxAmt = this.taxDetailsData
      //     .filter((ele) => ele.tax_amt)
      //     .map((data) => data.tax_amt);
      //   const totalTaxAmount = filterTaxAmt.length
      //     ? commonHelper.calculateTotal(filterTaxAmt)
      //     : 0;
      //   const finalTotalAmt =
      //     +totalTaxableAmt + totalTaxAmount + +this.lineAmount;
      //   this.taxDetailsData[this.specIndex].taxable_amount = finalTotalAmt;
      //   this.taxDetailsData[this.specIndex].tax_rate_percentage =
      //     item.tax_rate_percentage;
      //   this.taxDetailsData[this.specIndex].tax_amt =
      //     (this.taxDetailsData[this.specIndex].taxable_amount *
      //       this.taxDetailsData[this.specIndex].tax_rate_percentage) /
      //     100;
      //   this.taxDetailsData[this.specIndex].tax_amt = parseFloat(
      //     this.taxDetailsData[this.specIndex].tax_amt
      //   ).toFixed(2);
      // }
      // }
      this.showHideTaxTypeModal(false, null);
    },
    validateTaxableAmount(taxableAmt, index) {
      if (this.taxDetailsData[index].adhoc_amount > 0) {
        if (taxableAmt > this.taxDetailsData[index].adhoc_amount) {
          alert("Amount cannot greater than defined adhoc amount");
          setTimeout(() => {
            this.taxDetailsData[index].taxable_amount = this.taxDetailsData[
              index
            ].adhoc_amount;
          }, 100);
        }
      }
      this.taxDetailsData = JSON.parse(JSON.stringify(this.taxDetailsData));
    },
    rowSelectedTaxCategory(item) {
      const taxData = this.taxDetailsData[this.specIndex];
      if (taxData.tax_type.includes("FREIGHT") && !taxData.taxable_amount) {
        alert("Please Enter Taxable Amount First");
        this.showHideTaxCategoryModal(false, null);
        return;
      }
      taxData.tax_category_name = item.tax_category_name;
      taxData.tax_category_id = item.tax_category_id;
      taxData.tax_rate_percentage = item.adhoc_tax_rate;
      taxData.adhoc_tax_rate =
        (taxData.taxable_amount * item.adhoc_tax_rate) / 100;
      taxData.tax_amt = taxData.adhoc_tax_rate.toFixed(2);

      this.taxDetailsData = [...this.taxDetailsData]; // Deep clone the array
      this.showHideTaxCategoryModal(false, null);
    },
    // rowSelectedTaxCategory(item) {
    //   if (this.taxDetailsData[this.specIndex].tax_type.includes("FREIGHT")) {
    //     if (this.taxDetailsData[this.specIndex].taxable_amount) {
    //       this.taxDetailsData[this.specIndex].tax_category_name =
    //         item.tax_category_name;
    //       this.taxDetailsData[this.specIndex].tax_category_id =
    //         item.tax_category_id;
    //       this.taxDetailsData[this.specIndex].tax_rate_percentage =
    //         item.adhoc_tax_rate;
    //       this.taxDetailsData[this.specIndex].adhoc_tax_rate =
    //         (this.taxDetailsData[this.specIndex].taxable_amount *
    //           item.adhoc_tax_rate) /
    //         100;
    //       this.taxDetailsData[this.specIndex].tax_amt = this.taxDetailsData[
    //         this.specIndex
    //       ].adhoc_tax_rate.toFixed(2);
    //       this.taxDetailsData = JSON.parse(JSON.stringify(this.taxDetailsData));
    //     } else {
    //       alert("Please Enter Taxable Amount First");
    //     }
    //   } else {
    //     this.taxDetailsData[this.specIndex].tax_category_name =
    //       item.tax_category_name;
    //     this.taxDetailsData[this.specIndex].tax_category_id =
    //       item.tax_category_id;
    //     this.taxDetailsData[this.specIndex].tax_rate_percentage =
    //       item.adhoc_tax_rate;
    //     this.taxDetailsData[this.specIndex].adhoc_tax_rate =
    //       (this.taxDetailsData[this.specIndex].taxable_amount *
    //         item.adhoc_tax_rate) /
    //       100;
    //     this.taxDetailsData[this.specIndex].tax_amt = this.taxDetailsData[
    //       this.specIndex
    //     ].adhoc_tax_rate.toFixed(2);
    //     this.taxDetailsData = JSON.parse(JSON.stringify(this.taxDetailsData));
    //   }
    //   this.showHideTaxCategoryModal(false, null);
    // },
    testingFunction(selected, vendorInvoiceId) {
      if (selected == "poRowDtls") {
        const payload = {
          _page: 0,
          _limit: 500,
          po_num: this.poHeaderDetails.po_number,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/getPoLineDetailsById", payload)
          .then((resp) => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.data.page;
              this.poLineDetailsData = result.map((data) => {
                data.tax_details = "Details";
                return data;
              });
              this.newInvoiceDetailsData = [];
              this.getPendingQtyforInvoice();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else if (selected == "invoiceRowDtls" && vendorInvoiceId) {
        const payload = {
          _page: 0,
          _limit: 500,
          po_num: this.poHeaderDetails.po_number,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/getPoLineDetailsById", payload)
          .then((resp) => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.data.page;
              const poLineDetailsTesting = result.map((data) => {
                data.tax_details = "Details";
                return data;
              });
              this.getVendorInvoiceLineDetails(
                vendorInvoiceId,
                poLineDetailsTesting
              );
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    showHidePreviewTaxModal(flag, item) {
      this.showPreviewTaxModal = flag;
      if (flag) {
        this.previewTaxData = item.tax_dtls ? item.tax_dtls : item.tax_details;
        // this.previewTaxData.map((ele) => {
        //   if (ele.manual !== "Y") {
        //     ele.taxable_amount = item.deliver_qty * item.po_price;
        //     ele.taxable_amount = ele.taxable_amount.toFixed(2);
        //   }
        // });
      }
    },
    calculateInvoiceAmount() {
      const filterAmount = this.newInvoiceDetailsData
        .filter((elem) => elem.amount)
        .map((data) => data.amount);
      const totalTaxableAmount = commonHelper.calculateTotal(filterAmount);
      const filterTaxAmount = this.newInvoiceDetailsData
        .filter((elem) => elem.tax_amount)
        .map((data) => data.tax_amount);
      const totalTaxAmount = filterTaxAmount.length
        ? commonHelper.calculateTotal(filterTaxAmount)
        : 0;
      this.poHeaderDetails.dif_amount =
        this.poHeaderDetails.inv_amount -
        (+totalTaxableAmount + +totalTaxAmount);
      this.poHeaderDetails.dif_amount = parseFloat(
        this.poHeaderDetails.dif_amount
      ).toFixed(2);

      // this.poHeaderDetails.dif_amount =
      //   this.poHeaderDetails.inv_amount -
      //   (Number(totalTaxableAmount) + Number(totalTaxAmount)).toFixed(2);
    },
    // calculateInvoiceAmount() {
    //   const filterDeliverQty = this.newInvoiceDetailsData.filter(
    //     (elem) => elem.deliver_qty
    //   );
    //   const taxableAmount = filterDeliverQty.map(
    //     (data) => data.deliver_qty * data.po_price
    //   );
    //   const totalTaxableAmount = commonHelper.calculateTotal(taxableAmount);
    //   const filterTaxAmount = this.newInvoiceDetailsData.filter(
    //     (elem) => elem.tax_amount
    //   );
    //   const taxAmount = filterTaxAmount.map((data) => data.tax_amount);
    //   const totalTaxAmount = taxAmount.length
    //     ? commonHelper.calculateTotal(taxAmount)
    //     : 0;
    //   this.poHeaderDetails.dif_amount =
    //     this.poHeaderDetails.inv_amount -
    //     (Number(totalTaxableAmount) + Number(totalTaxAmount)).toFixed(2);
    //   // this.poHeaderDetails.dif_amount = this.poHeaderDetails.dif_amount.toFixed(
    //   //   2
    //   // );
    // },
    clearDeliveryQty() {
      if (this.newInvoiceDetailsData && this.newInvoiceDetailsData.length) {
        this.calculateInvoiceAmount();
      }
      this.poLineDetailsData.map((data) => {
        data.deliver_qty = null;
        return data;
      });
      this.poLineDetailsData = JSON.parse(
        JSON.stringify(this.poLineDetailsData)
      );
    },
    getPendingQtyforInvoice() {
      const payload = {
        po_num: this.poHeaderDetails.po_number,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getPendingQtyforInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            for (let i = 0; i < this.poLineDetailsData.length; i++) {
              let idFound = false;
              for (let j = 0; j < result.length; j++) {
                if (
                  this.poLineDetailsData[i].po_distribution_id ===
                  result[j].po_distribution_id
                ) {
                  this.poLineDetailsData[i].pending_qty =
                    this.poLineDetailsData[i].billed_pending -
                    result[j].realapp_pending_quantity;
                  this.poLineDetailsData[i].pending_qty = parseFloat(
                    this.poLineDetailsData[i].pending_qty
                  ).toFixed(10);
                  idFound = true;
                  break;
                }
              }
              if (!idFound) {
                this.poLineDetailsData[i].pending_qty =
                  this.poLineDetailsData[i].billed_pending - 0;
                this.poLineDetailsData[i].pending_qty = parseFloat(
                  this.poLineDetailsData[i].pending_qty
                ).toFixed(10);
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    // Invoice With PO Function Ending
    // getPendingQtyforInvoice() {
    //   const payload = {
    //     po_num: this.poHeaderDetails.po_number,
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch("vendor/getPendingQtyforInvoice", payload)
    //     .then((resp) => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data;
    //         if (result.length) {
    //           this.poLineDetailsData.forEach((elem, index) => {
    //             const key = result[index];
    //             if (
    //               key &&
    //               elem.po_line_location_id === key.po_line_location_id
    //             ) {
    //               elem.pending_qty = key.realapp_pending_quantity;
    //             } else {
    //               elem.pending_qty = elem.quantity;
    //             }
    //           });
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },

    // Invoice Without PO Function Starting
    clearDeliveryQtyWithoutPo() {
      if (this.invoiceHeader.inv_amount < 0) {
        alert("Total Invoice Amount Cannot less than 0.");
        this.invoiceHeader.inv_amount = 0;
      }
      if (this.invoiceHeader.dif_amount) {
        this.calculateInvoiceAmountWithoutPo();
      }
    },
    // addEditTaxDetails() {
    //   const filterTaxAmt = this.taxDetailsData.filter((ele) => ele.tax_amt);
    //   const result = filterTaxAmt.map((elem) => elem.tax_amt);
    //   const total = commonHelper.calculateTotal(result);

    //   const filterAdhocTaxRate = this.taxDetailsData.filter(
    //     (data) => data.manual == "Y"
    //   );
    //   const adhocRateTotal = filterAdhocTaxRate.map(
    //     (data) => data.taxable_amount
    //   );
    //   const adhocTotal = adhocRateTotal.length
    //     ? commonHelper.calculateTotal(adhocRateTotal)
    //     : 0;
    //   if (total) {
    //     const totalAdhoc = total + +adhocTotal;
    //     this.poLineDetailsData[this.sumIndex].tax_amount = parseFloat(
    //       totalAdhoc
    //     ).toFixed(10);
    //   }
    //   const taxDetails = this.taxDetailsData.map((elem) => {
    //     return {
    //       vendor_inv_tax_id: elem.vendor_inv_tax_id
    //         ? elem.vendor_inv_tax_id
    //         : elem.vnd_inv_tax_id
    //         ? elem.vnd_inv_tax_id
    //         : 0,
    //       tax_rate_id: elem.tax_rate_id,
    //       tax_rate: elem.tax_rate_percentage,
    //       tax_type_id: elem.tax_type_id,
    //       tax_type: elem.tax_type,
    //       tax_rate_type: elem.tax_rate_type,
    //       rcm_flag: elem.rcm_flag,
    //       tax_category_id: elem.tax_category_id,
    //       tax_category_name: elem.tax_category_name,
    //       amount: parseFloat(elem.tax_amt).toFixed(10),
    //       taxable_amount: parseFloat(elem.taxable_amount).toFixed(10),
    //       manual: elem.manual == "Y" ? "Y" : elem.manual == "N" ? "N" : null,
    //     };
    //   });
    //   if (taxDetails.length) {
    //     this.poLineDetailsData[this.sumIndex].tax_dtls = taxDetails;
    //     this.showTaxModal = false;
    //   }
    // },
    isTaxValid() {
      if (this.newFunction()) {
        const taxDetailsWithoutPo = this.taxWithoutPoData.map((elem) => {
          return {
            vendor_inv_tax_id: elem.vendor_inv_tax_id
              ? elem.vendor_inv_tax_id
              : elem.vnd_inv_tax_id
              ? elem.vnd_inv_tax_id
              : 0,
            tax_type_id: elem.tax_type_id,
            tax_type: elem.tax_type,
            taxable_amount: parseFloat(elem.taxable_amount).toFixed(2),
            // taxable_amount: parseFloat(elem.taxable_amount).toFixed(10),
            tax_rate: elem.tax_rate_type,
            amount: parseFloat(elem.tax_amount).toFixed(2),
            // amount: parseFloat(elem.tax_amount).toFixed(10),
          };
        });
        if (taxDetailsWithoutPo.length) {
          const filterTaxAmountWithoutPo = this.taxWithoutPoData.filter(
            (elem) => elem.tax_amount
          );
          const taxAmountWithoutPo = filterTaxAmountWithoutPo.map(
            (data) => data.tax_amount
          );
          const totalTaxAmountWithoutPo = commonHelper.calculateTotal(
            taxAmountWithoutPo
          );

          const filterAdhocTaxAmount = this.taxWithoutPoData.filter((data) =>
            data.tax_type.includes("FREIGHT")
          );
          const adhocTaxTotal = filterAdhocTaxAmount.map(
            (data) => data.taxable_amount
          );
          const adhoctotalTaxAmount = adhocTaxTotal.length
            ? commonHelper.calculateTotal(adhocTaxTotal)
            : 0;

          if (totalTaxAmountWithoutPo) {
            const totalTaxAmountWithAdhoc =
              totalTaxAmountWithoutPo + +adhoctotalTaxAmount;
            // this.invoiceWithoutPoData[
            //   this.withoutPoIndex
            // ].tax_amount = parseFloat(totalTaxAmountWithAdhoc).toFixed(10);
            this.invoiceWithoutPoData[
              this.withoutPoIndex
            ].tax_amount = parseFloat(totalTaxAmountWithAdhoc).toFixed(2);
          }
          this.invoiceWithoutPoData[
            this.withoutPoIndex
          ].tax_dtls = taxDetailsWithoutPo;

          // this.invoiceWithoutPoData[
          //   this.withoutPoIndex
          // ].tax_amount = totalTaxAmountWithoutPo.toFixed(10);

          this.showTaxWithoutPoModal = false;
          this.taxWithoutPoData = [
            {
              tax_type_id: null,
              tax_type: null,
              taxable_amount: null,
              tax_rate_type: null,
              tax_amount: null,
            },
          ];
          this.calculateInvoiceAmountWithoutPo();
        }
      }
    },
    calculateInvoiceAmountWithoutPo() {
      const filterTaxableAmtWithoutPo = this.invoiceWithoutPoData
        .filter((ele) => ele.total_price)
        .map((data) => data.total_price);
      const totalTaxableAmountWithoutPo = commonHelper.calculateTotal(
        filterTaxableAmtWithoutPo
      );
      const filterTaxAmountWithoutPo = this.invoiceWithoutPoData
        .filter((ele) => ele.tax_amount)
        .map((data) => data.tax_amount);
      const totalTaxAmountWithoutPo = filterTaxAmountWithoutPo.length
        ? commonHelper.calculateTotal(filterTaxAmountWithoutPo)
        : 0;
      this.invoiceHeader.dif_amount =
        this.invoiceHeader.inv_amount -
        (+totalTaxableAmountWithoutPo + +totalTaxAmountWithoutPo);
      this.invoiceHeader.dif_amount = parseFloat(
        this.invoiceHeader.dif_amount
      ).toFixed(2);
      // this.poHeaderDetails.dif_amount = this.poHeaderDetails.dif_amount.toFixed(
      //   2
      // );
    },
    newFunction() {
      this.tempTaxData = this.taxWithoutPoData.map((elem) => elem.tax_type);
      if (this.tempTaxData.includes("CGST")) {
        if (
          this.tempTaxData.includes("UGST") &&
          this.tempTaxData.includes("SGST")
        ) {
          if (this.tempTaxData.includes("IGST")) {
            return alert("Invalid Case IGST cannot apply with this case");
          } else {
            return true;
          }
        } else if (
          this.tempTaxData.includes("UGST") ||
          this.tempTaxData.includes("SGST")
        ) {
          if (this.tempTaxData.includes("IGST")) {
            return alert("Invalid Case IGST cannot apply with this case");
          } else {
            return true;
          }
        } else if (this.tempTaxData.includes("IGST")) {
          return alert("Invalid Case CGST cannot apply with IGST");
        } else if (
          !this.tempTaxData.includes("UGST") ||
          !this.tempTaxData.includes("SGST")
        ) {
          return alert("Invalid Case SGST and UGST Mandatory with CGST");
        }
      } else if (this.tempTaxData.includes("IGST")) {
        if (
          this.tempTaxData.includes("UGST") ||
          this.tempTaxData.includes("CGST") ||
          this.tempTaxData.includes("SGST")
        ) {
          return alert(
            "Invalid Case. UGST, CGST and SGST cannot apply with IGST"
          );
        } else {
          return true;
        }
      } else if (
        this.tempTaxData.includes("SGST") ||
        this.tempTaxData.includes("UGST")
      ) {
        return alert("Invalid Case. Please Select CGST with SGST and UGST");
      }
      //
      else if (this.tempTaxData.includes("CGST RCM")) {
        if (
          this.tempTaxData.includes("UGST RCM") &&
          this.tempTaxData.includes("SGST RCM")
        ) {
          if (this.tempTaxData.includes("IGST RCM")) {
            return alert("Invalid Case IGST RCM cannot apply with this case");
          } else {
            return true;
          }
        } else if (
          this.tempTaxData.includes("UGST RCM") ||
          this.tempTaxData.includes("SGST RCM")
        ) {
          if (this.tempTaxData.includes("IGST RCM")) {
            return alert("Invalid Case IGST RCM cannot apply with this case");
          } else {
            return true;
          }
        } else if (this.tempTaxData.includes("IGST RCM")) {
          return alert("Invalid Case CGST RCM cannot apply with IGST RCM");
        } else if (
          !this.tempTaxData.includes("UGST RCM") ||
          !this.tempTaxData.includes("SGST RCM")
        ) {
          return alert(
            "Invalid Case SGST RCM and UGST RCM Mandatory with CGST RCM"
          );
        }
      } else if (this.tempTaxData.includes("IGST RCM")) {
        if (
          this.tempTaxData.includes("UGST RCM") ||
          this.tempTaxData.includes("CGST RCM") ||
          this.tempTaxData.includes("SGST RCM")
        ) {
          return alert(
            "Invalid Case. UGST RCM, CGST RCM and SGST RCM cannot apply with IGST RCM"
          );
        } else {
          return true;
        }
      } else if (
        this.tempTaxData.includes("SGST RCM") ||
        this.tempTaxData.includes("UGST RCM")
      ) {
        return alert(
          "Invalid Case. Please Select CGST RCM with SGST RCM and UGST RCM"
        );
      }
    },
    addVendorInvoiceLine() {
      this.invoiceWithoutPoData.push({
        // location: null,
        // expense_code: null,
        // expense_type: null,
        item_description: null,
        po_price: null,
        deliver_qty: 1,
        total_price: null,
        hsn_sac: null,
        tax_details: null,
        tax_amount: null,
      });
    },
    removeVendorInvoiceLine(index, item) {
      this.invoiceWithoutPoData.splice(index, 1);
      // if (item.tax_amount) {
      this.calculateInvoiceAmountWithoutPo();
      this.deleteNewInvoiceWithoutPo(item)
      // }
    },
    showHideInvoiceCommonModal(flag, type) {
      this.modalType = type;
      if (this.modalType === "legal_entity") {
        this.getLegalEntityVendorInvoice();
      }
      if (this.modalType === "company_gstn") {
        this.getCompanyGstnVendorInvoice();
      }
      if (this.modalType === "vendor_gstn") {
        this.getVendorGstnVendorInvoice();
      }
      if (this.modalType === "currency_code") {
        this.getCurrencyVendorInvoice();
      }
      if (this.modalType === "location") {
        this.getLocationVendorInvoice();
      }
      if (this.modalType === "expense_type") {
        this.getExpenseTypeVendorInvoice();
      }
      this.showInvoiceCommonModal = flag;
      if (!flag) {
        this.legalEntitySearch = "";
        this.companyGstnSearch = "";
        this.vendorGstnSearch = "";
        this.currencySearch = "";
        this.locationSearch = "";
        this.expenseTypeSearch = "";
      }
    },
    rowSelectedLegalEntity(item) {
      this.invoiceHeader.erp_le_id = item.legal_entity_id;
      this.invoiceHeader.le_name = item.name;
      this.showInvoiceCommonModal = false;
      this.perPageLegalEntity = commonHelper.perPageRecord;
      this.currentPageLegalEntity = 1;
      this.totalRowsLegalEntity = null;
      this.validateCurrency();
    },
    rowSelectedCompanyGstn(item) {
      this.invoiceHeader.company_gstn_name = item.registration_number;
      this.invoiceHeader.company_state_code = item.state_code;
      this.showInvoiceCommonModal = false;
      this.perPageCompanyGstn = commonHelper.perPageRecord;
      this.currentPageCompanyGstn = 1;
      this.totalRowsCompanyGstn = null;
    },
    rowSelectedVendorGstn(item) {
      this.invoiceHeader.vendor_gstn_name = item.registration_number;
      this.invoiceHeader.vendor_state_code = item.state_code;
      if(this.invoiceHeader.vendor_state_code === 'NA' || this.invoiceHeader.vendor_gstn_name.includes('XXXXXXXXXX')) {
        this.invoiceHeader.vendor_gstn_flag = true;
      }
      this.showInvoiceCommonModal = false;
      this.perPageVendorGstn = commonHelper.perPageRecord;
      this.currentPageVendorGstn = 1;
      this.totalRowsVendorGstn = null;
    },
    rowSelectedCurrency(item) {
      this.invoiceHeader.currency = item.currency_code;
      this.showInvoiceCommonModal = false;
      this.perPageCurrency = commonHelper.perPageRecord;
      this.currentPageCurrency = 1;
      this.totalRowsCurrency = null;
    },
    rowSelectedLocation(item) {
      this.invoiceWithoutPoData[this.specIndex].location = item.description;
      this.showInvoiceCommonModal = false;
      this.perPageLocation = commonHelper.perPageRecord;
      this.currentPageLocation = 1;
      this.totalRowsLocation = null;
    },
    rowSelectedExpenseType(item) {
      this.invoiceWithoutPoData[this.specIndex].expense_code = item.flex_value;
      this.invoiceWithoutPoData[this.specIndex].expense_type = item.description;
      this.showInvoiceCommonModal = false;
      this.perPageExpenseType = commonHelper.perPageRecord;
      this.currentPageExpenseType = 1;
      this.totalRowsExpenseType = null;
    },
    getLegalEntityVendorInvoice() {
      const payload = {
        _page: this.currentPageLegalEntity - 1,
        _limit: this.perPageLegalEntity,
        le_name: this.legalEntitySearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getLegalEntityVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.legalEntityData = result;
            this.totalRowsLegalEntity = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCompanyGstnVendorInvoice() {
      const payload = {
        _page: this.currentPageCompanyGstn - 1,
        _limit: this.perPageCompanyGstn,
        legal_entity: this.invoiceHeader.erp_le_id,
        invoice_date: this.invoiceHeader.inv_date,
        company_gstn: this.companyGstnSearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getCompanyGstnVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.companyGstnData = result;
            this.totalRowsCompanyGstn = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorGstnVendorInvoice() {
      const payload = {
        _page: this.currentPageVendorGstn - 1,
        _limit: this.perPageVendorGstn,
        le_id: this.invoiceHeader.erp_le_id,
        vendor_id: this.vendorId,
        invoice_date: this.invoiceHeader.inv_date,
        vendor_gstn: this.vendorGstnSearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorGstnVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.vendorGstnData = result;
            this.totalRowsVendorGstn = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCurrencyVendorInvoice() {
      const payload = {
        _page: this.currentPageCurrency - 1,
        _limit: this.perPageCurrency,
        currency: this.currencySearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getCurrencyVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.currencyData = result;
            this.totalRowsCurrency = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLocationVendorInvoice() {
      const payload = {
        _page: this.currentPageLocation - 1,
        _limit: this.perPageLocation,
        location: this.locationSearch,
        vset_type: "SSN_Cost_Centre",
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/vendorLocationExpenseType", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.locationData = result;
            this.totalRowsLocation = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getExpenseTypeVendorInvoice() {
      const payload = {
        _page: this.currentPageExpenseType - 1,
        _limit: this.perPageExpenseType,
        expense_type: this.expenseTypeSearch,
        vset_type: "expense_type",
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/vendorLocationExpenseType", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.expenseTypeData = result;
            this.totalRowsExpenseType = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTaxCommonModal(flag, type, index) {
      this.modalType = type;
      this.specIndex = index;
      if (this.modalType === "taxType") {
        this.getTaxTypeVendorInvoice();
      }
      if (this.modalType === "taxRate") {
        this.getTaxRateVendorInvoice();
      }
      this.showTaxCommonModal = flag;
    },
    getTaxTypeVendorInvoice() {
      const payload = {
        state_match_flag:
          this.invoiceHeader.company_state_code ===
          this.invoiceHeader.vendor_state_code
            ? "1"
            : "0",
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxTypeVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.taxTypeWithoutPoData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTaxRateVendorInvoice() {
      const payload = {
        _page: this.currentPageCurrency - 1,
        _limit: this.perPageCurrency,
        tax_type_id: this.taxWithoutPoData[this.specIndex].tax_type_id,
        legal_entity: this.invoiceHeader.erp_le_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxRateVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.taxRateWithoutPoData = result;
            this.totalRowsTaxRateWithoutPo = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedTaxTypeWithoutPo(item) {
      this.taxWithoutPoData[this.specIndex].tax_type = item.tax_type_code;
      this.taxWithoutPoData[this.specIndex].tax_type_id = item.tax_type_id;
      this.taxWithoutPoData[this.specIndex].tax_rate_type = null;
      this.taxWithoutPoData[this.specIndex].tax_amount = null;
      if (item.tax_type_code.includes("FREIGHT")) {
        this.taxWithoutPoData[this.specIndex].taxable_amount = null;
      } else {
        this.taxWithoutPoData[
          this.specIndex
        ].taxable_amount = this.taxWithoutPoData[0].taxable_amount;
      }
      this.taxWithoutPoData = JSON.parse(JSON.stringify(this.taxWithoutPoData));
      this.showTaxCommonModal = false;
    },
    rowSelectedTaxRateWithoutPo(item) {
      this.taxWithoutPoData[this.specIndex].tax_rate_type =
        item.tax_rate_percentage;
      this.taxWithoutPoData[this.specIndex].tax_amount =
        (this.taxWithoutPoData[this.specIndex].taxable_amount *
          this.taxWithoutPoData[this.specIndex].tax_rate_type) /
        100;
      this.taxWithoutPoData[this.specIndex].tax_amount = parseFloat(
        this.taxWithoutPoData[this.specIndex].tax_amount
      ).toFixed(2);
      this.taxWithoutPoData = JSON.parse(JSON.stringify(this.taxWithoutPoData));
      this.showTaxCommonModal = false;
    },
    isInvAmtAvail(item, type, index) {
      this.validateDeliveryQty(item.po_price, index);
      if (this.invoiceHeader.inv_amount) {
        this.calculateTotalAmount(item, type);
      } else {
        item.po_price = null;
        alert("Please Enter Invoice Amount First.");
      }
    },
    calculateTotalAmount(item, type) {
      item.tax_amount = 0;
      if (type === "price") {
        if (item.po_price) {
          // if (item.po_price > 0) {
          if (item.deliver_qty) {
            item.total_price = item.po_price * item.deliver_qty;
            item.total_price = parseFloat(item.total_price).toFixed(3);
            item.total_price = Math.round(item.total_price*100)/100;
            const tempTotalAmt = this.invoiceWithoutPoData
              .filter((ele) => ele.total_price)
              .map((data) => data.total_price);
            const totalAmount = commonHelper.calculateTotal(tempTotalAmt);
            const tempTaxAmt = this.invoiceWithoutPoData
              .filter((elem) => elem.tax_amount)
              .map((item) => item.tax_amount);
            const totalTaxAmount = tempTaxAmt.length
              ? commonHelper.calculateTotal(tempTaxAmt)
              : 0;
            this.invoiceHeader.dif_amount =
              this.invoiceHeader.inv_amount - (+totalAmount + +totalTaxAmount);
            this.invoiceHeader.dif_amount = parseFloat(
              this.invoiceHeader.dif_amount
            ).toFixed(2);
          } else {
            item.total_price = null;
          }
          // } else {
          //   alert("Item price cannot be 0");
          //   item.po_price = null;
          // }
        }
      } else if (type === "deliver_qty") {
        if (item.deliver_qty) {
          item.total_price = item.po_price * item.deliver_qty;
          item.total_price = parseFloat(item.total_price).toFixed(3);
          item.total_price = Math.round(item.total_price*100)/100;
          const tempTotalAmt = this.invoiceWithoutPoData
            .filter((ele) => ele.total_price)
            .map((data) => data.total_price);
          const totalAmount = commonHelper.calculateTotal(tempTotalAmt);
          const tempTaxAmt = this.invoiceWithoutPoData
            .filter((elem) => elem.tax_amount)
            .map((item) => item.tax_amount);
          const totalTaxAmount = tempTaxAmt.length
            ? commonHelper.calculateTotal(tempTaxAmt)
            : 0;
          this.invoiceHeader.dif_amount =
            this.invoiceHeader.inv_amount - (+totalAmount + +totalTaxAmount);
          this.invoiceHeader.dif_amount = parseFloat(
            this.invoiceHeader.dif_amount
          ).toFixed(2);
        } else {
          item.total_price = null;
        }
      }
    },
    gstnBasedOnLeInvDate(gstnTpe) {
      this.invoiceHeader.inv_date && this.invoiceHeader.le_name
        ? gstnTpe === "compGstn"
          ? this.showHideInvoiceCommonModal(true, "company_gstn")
          : gstnTpe === "vendorGstn"
          ? this.showHideInvoiceCommonModal(true, "vendor_gstn")
          : ""
        : !this.invoiceHeader.inv_date && this.invoiceHeader.le_name
        ? alert("Please Select Invoice Date.")
        : this.invoiceHeader.inv_date && !this.invoiceHeader.le_name
        ? alert("Please Select Legal Entity")
        : alert("Please Select Invoice Date and Legal Entity");
    },
    locationBasedonCompanyGstn(type, index) {
      if (type === "location") {
        if (this.invoiceHeader.company_gstn_name) {
          this.specIndex = index;
          this.showHideInvoiceCommonModal(true, "location");
        } else {
          alert("Please Select Company GSTN");
        }
      }
      if (type === "expense_type") {
        this.specIndex = index;
        this.showHideInvoiceCommonModal(true, "expense_type");
      }
    },
    show1HideTaxModal(flag, item, index) {
      if (flag) {
        if (item.deliver_qty) {
          this.showTaxModal = flag;
          this.sumIndex = index;
          if (item.vendor_inv_dtl_id) {
            this.taxDetailsData = item.tax_details;
            this.taxDetailsData = item.tax_details.map((elem) => {
              if (elem.manual !== "Y") {
                elem.taxable_amount = item.deliver_qty * item.po_price;
                elem.tax_rate_percentage = elem.tax_rate;
              } else {
                elem.taxable_amount = null;
              }
              elem.tax_amt = elem.amount;
              return elem;
            });
          } else {
            this.getTaxInvoiceDetails(item);
          }
        } else {
          alert("Please Enter Deliver Qty.");
        }
      }
    },
    showHideTaxWithoutPoModal(flag, item, index) {
      if (flag) {
        if (item.deliver_qty > 0) {
          this.showTaxWithoutPoModal = flag;
          this.withoutPoIndex = index;
          if (item.tax_dtls && item.tax_dtls.length) {
            this.taxWithoutPoData = item.tax_dtls.map((ele) => {
              ele.tax_rate_type = ele.tax_rate;
              ele.taxable_amount = item.total_price;
              ele.tax_amount = (ele.taxable_amount*ele.tax_rate_type)/100;
              return ele;
            });
          } else if (item.tax_details && item.tax_details.length) {
            this.taxWithoutPoData = item.tax_details.map((ele) => {
              ele.tax_rate_type = ele.tax_rate;
              ele.taxable_amount = item.total_price;
              ele.tax_amount = (ele.taxable_amount*ele.tax_rate_type)/100;
              return ele;
            });
          } else {
            this.taxWithoutPoData = [
              {
                tax_type_id: null,
                tax_type: null,
                taxable_amount: null,
                tax_rate_type: null,
                tax_amount: null,
              },
            ];
            this.taxWithoutPoData.map((elem) => {
              elem.taxable_amount = item.total_price;
              return elem;
            });
          }
        } else {
          if (item.deliver_qty) {
            alert("Deliver Qty. Cannot be 0");
          } else {
            alert("Please Enter Deliver Qty.");
          }
        }
      }
    },
    addTaxWithoutPoRow() {
      this.taxWithoutPoData.push({
        tax_type_id: null,
        tax_type: null,
        taxable_amount: this.taxWithoutPoData[0].taxable_amount,
        tax_rate_type: null,
        tax_amount: null,
      });
    },
    removeTaxWithoutPoRow(index) {
      this.taxWithoutPoData.splice(index, 1);
    },
    clearFieldsWithoutPo(item) {
      if (item === "legal_entity") {
        this.invoiceHeader.erp_le_id = null;
        this.invoiceHeader.le_name = null;
        this.invoiceHeader.currency = null;
        this.invoiceHeader.company_gstn_name = null;
        this.invoiceHeader.vendor_gstn_name = null;
        this.invoiceWithoutPoData.map((elem) => {
          elem.location = null;
          return elem;
        });
      } else if (item === "currency") {
        this.invoiceHeader.currency = null;
      } else if (item === "company_gstn") {
        this.invoiceHeader.company_gstn_name = null;
        this.invoiceWithoutPoData.map((elem) => {
          elem.location = null;
          return elem;
        });
      } else if (item === "vendor_gstn") {
        this.invoiceHeader.vendor_gstn_name = null;
      } else if (item === "select_employee") {
        this.invoiceHeader.employee_name = null;
        this.invoiceHeader.employee_id = null;
      }
    },
    validateCurrency() {
      const payload = {
        legal_entity_id: this.invoiceHeader.erp_le_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/validateCurrencyExchangeRate", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.data;
            if (result) {
              this.invoiceHeader.currency = result.CURRENCY_CODE;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearInvoiceDate() {
      this.invoiceHeader.company_gstn_name = null;
      this.invoiceHeader.vendor_gstn_name = null;
      this.invoiceWithoutPoData.map((elem) => {
        elem.location = null;
        return elem;
      });
    },
    clearWithoutPoFields() {
      this.invoiceHeader = {
        inv_number: null,
        inv_amount: null,
        le_id: null,
        le_name: null,
        company_gstn_name: null,
        vendor_gstn_name: null,
        approval_status: "DRAFT",
        currency: null,
        dif_amount: null,
        inv_date: null,
        manual_employee_name: null,
        vendor_gstn_flag: null,
        round_off: false,
      };
      this.invoiceWithoutPoData = [
        {
          // location: null,
          // expense_code: null,
          // expense_type: null,
          item_description: null,
          po_price: null,
          deliver_qty: 1,
          total_price: null,
          hsn_sac: null,
          tax_details: null,
          tax_amount: null,
        },
      ];
    },
    validateInvoiceDetailsWithoutPo() {
      if (this.isAllDetailsAvail()) {
        if (this.invoiceHeader.dif_amount == 0) {
          if (this.venInvoiceId > 0) {
            this.saveNewFunction();
          } else {
            this.invoiceNumberValidation("without_po");
          }
        } else {
          alert("Invoice Difference Amount should be 0.");
        }
      } else {
        alert("Kindly fill mandatory fields.");
      }
    },
    isAllHeaderAvail() {
      const header = this.invoiceHeader;
      const missingKeys = [];
      const keyMappings = {
        le_name: "Legal Entity",
        currency: "Currency",
        inv_amount: "Invoice Amount",
        inv_date: "Invoice Date",
        company_gstn_name: "Company GSTN",
        vendor_gstn_name: "Vendor GSTN",
        inv_number: "Invoice Number",
        employee_name: "Employee",
      };
      for (const key in header) {
        if (header.hasOwnProperty(key) && header[key] === null) {
          missingKeys.push(key);
        }
      }
      if (missingKeys.length > 0) {
        const tempMsg = missingKeys
          .map((ele) => keyMappings[ele])
          .filter((data) => data);
        const errorMessage1 = `Please Enter:\n ${tempMsg.join(",\n")}`;
        alert(errorMessage1);

        const errorMessage = `Please Enter:\n ${missingKeys
          .map((ele) => keyMappings[ele])
          .join(",\n ")}`;
        // const errorMessage = `Please Enter:\n ${missingKeys.join(",\n ")}`;
        return false;
      } else {
        return true;
      }
    },
    isAllDetailsAvail() {
      if (
        this.invoiceHeader.erp_le_id &&
        this.invoiceHeader.currency &&
        this.invoiceHeader.inv_amount &&
        this.invoiceHeader.inv_date &&
        this.invoiceHeader.company_gstn_name &&
        this.invoiceHeader.vendor_gstn_name &&
        this.invoiceHeader.inv_number &&
        this.invoiceHeader.note &&
        this.invoiceHeader.manual_employee_name
      ) {
        return true;
        // const isTaxApplied = this.invoiceWithoutPoData.filter(
        //   (ele) => !ele.tax_amount
        // );
        // if (isTaxApplied.length) {
        //   alert("Please Enter Tax Details for Invoice.");
        // } else {
        //   return true;
        // }
      } else {
        return false;
      }
    },
    validateEmail() {
      if (this.invoiceHeader.manual_employee_name) {
        if (
          /^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
            this.invoiceHeader.manual_employee_name.toLowerCase()
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isEmployeeAvail() {
      this.loader = true;
      this.$store
        .dispatch("vendor/isEmployeeAvail")
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const result = response.data.data;
            const record = result.find(
              (item) =>
                item.login_email_id ===
                this.invoiceHeader.manual_employee_name.toUpperCase()
            );
            if (record) {
              this.invoiceHeader.manual_employee_id = record.employee_id;
              this.addEditInvoiceWithoutPo();
            } else {
              alert("Employee Email Id is either Inactive or Invalid");
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    addEditInvoiceWithoutPo() {
      if (this.invoiceHeader.vendor_gstn_flag) {
        this.furtherSave();
      } else {
        const invalidHsn = this.invoiceWithoutPoData.filter(
          (ele) => ele.hsn_sac === null || ele.hsn_sac === ""
        );
        if (invalidHsn.length) {
          alert("Please Enter HSN/SAC Code.");
          return;
        } else {
          this.furtherSave();
        }
      }
    },
    furtherSave() {
      const invoiceWithoutPo = this.invoiceWithoutPoData.map((elem) => {
        return {
          vendor_inv_dtl_id: elem.vendor_inv_dtl_id
            ? elem.vendor_inv_dtl_id
            : 0,
          item_description: elem.item_description,
          po_item_price: elem.po_price,
          deliver_item_qty: +elem.deliver_qty,
          hsn_sac: elem.hsn_sac
            ? elem.hsn_sac.replace(/\D/g, "")
            : elem.hsn_sac,
          tax_amount: +elem.tax_amount,
          tax_dtls: elem.tax_dtls,
          amount: +elem.total_price
        };
      });
      if (invoiceWithoutPo.length) {
        const payload = {
          vendor_id: this.vendorId,
          vendor_name: this.vendorName,
          vendor_invoice_id: this.venInvoiceId,
          erp_le_id: this.invoiceHeader.erp_le_id,
          currency: this.invoiceHeader.currency,
          inv_amt: this.invoiceHeader.inv_amount,
          inv_date: this.invoiceHeader.inv_date,
          first_party_gstn: this.invoiceHeader.company_gstn_name,
          third_party_gstn: this.invoiceHeader.vendor_gstn_name,
          approval_status: this.invoiceHeader.approval_status,
          dif_amount: +this.invoiceHeader.dif_amount,
          vendor_site_id: this.poHeaderDetails.vendor_site_id,
          vendor_site_name: this.poHeaderDetails.vendor_site_name,
          inv_num: this.invoiceHeader.inv_number,
          note: this.invoiceHeader.note,
          manual_emp_name: this.invoiceHeader.manual_employee_name,
          manual_employee_id: this.invoiceHeader.manual_employee_id,
          manual_invoice: this.radio,
          state_code: this.invoiceHeader.company_state_code,
          vendor_state_code: this.invoiceHeader.vendor_state_code,
          vendor_gstn_flag: this.invoiceHeader.vendor_gstn_flag,
          vendor_invoice_dtls: invoiceWithoutPo ? invoiceWithoutPo : null,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/addEditVendorInvoiceDetails", payload)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              const result = response.data.data;
              if (result) {
                if (this.venInvoiceId == 0) {
                  alert("Invoice Created Successfully");
                } else {
                  alert("Invoice Modify Successfully");
                }
                this.venInvoiceId = result.vendor_inv_id;
                this.getVendorInvoiceDetailsWithoutPo(this.venInvoiceId);
              }
            } else {
              // alert("Something went wrong");
              this.isSuccess = false;
              this.responseMsg = response.data.message;
              alert(this.responseMsg);
            }
          })
          .catch(() => {
            alert("Something went wrong");
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    saveNewFunction() {
      if (this.validateEmail()) {
        this.isEmployeeAvail();
        // if (this.isEmployeeAvail()) {
        //   const invoiceWithoutPo = this.invoiceWithoutPoData.map((elem) => {
        //     return {
        //       vendor_inv_dtl_id: elem.vendor_inv_dtl_id
        //         ? elem.vendor_inv_dtl_id
        //         : 0,
        //       // location: elem.location,
        //       // expense_code: elem.expense_code,
        //       // expense_type: elem.expense_type,
        //       item_description: elem.item_description,
        //       po_item_price: elem.po_price,
        //       deliver_item_qty: +elem.deliver_qty,
        //       hsn_sac: elem.hsn_sac,
        //       tax_amount: Number(elem.tax_amount),
        //       tax_dtls: elem.tax_dtls,
        //     };
        //   });
        //   if (invoiceWithoutPo.length) {
        //     const payload = {
        //       vendor_id: this.vendorId,
        //       vendor_name: this.vendorName,
        //       vendor_invoice_id: this.venInvoiceId,
        //       le_id: this.invoiceHeader.le_id,
        //       currency: this.invoiceHeader.currency,
        //       inv_amt: this.invoiceHeader.inv_amount,
        //       inv_date: this.invoiceHeader.inv_date,
        //       first_party_gstn: this.invoiceHeader.company_gstn_name,
        //       third_party_gstn: this.invoiceHeader.vendor_gstn_name,
        //       approval_status: this.invoiceHeader.approval_status,
        //       dif_amount: Number(this.invoiceHeader.dif_amount),
        //       vendor_site_id: this.poHeaderDetails.vendor_site_id,
        //       vendor_site_name: this.poHeaderDetails.vendor_site_name,
        //       inv_num: this.invoiceHeader.inv_number,
        //       note: this.invoiceHeader.note,
        //       manual_emp_name: this.invoiceHeader.manual_employee_name,
        //       manual_employee_id: this.invoiceHeader.manual_employee_id,
        //       manual_invoice: this.radio,
        //       vendor_invoice_dtls: invoiceWithoutPo ? invoiceWithoutPo : null,
        //     };
        //     // this.loader = true;
        //     // this.$store
        //     //   .dispatch("vendor/addEditVendorInvoiceDetails", payload)
        //     //   .then((response) => {
        //     //     this.loader = false;
        //     //     this.showAlert = true;
        //     //     if (response.status === 200) {
        //     //       const result = response.data.data;
        //     //       if (result) {
        //     //         alert("Invoice Created Successfully");
        //     //         this.venInvoiceId = result.vendor_inv_id;
        //     //         this.getVendorInvoiceDetailsWithoutPo(this.venInvoiceId);
        //     //       }
        //     //     } else {
        //     //       alert("Something went wrong");
        //     //       this.isSuccess = false;
        //     //       this.responseMsg = response.data.message;
        //     //     }
        //     //   })
        //     //   .catch(() => {
        //     //     alert("Something went wrong");
        //     //     this.loader = false;
        //     //     this.showAlert = true;
        //     //     this.isSuccess = false;
        //     //     this.responseMsg = appStrings.autoFailedMsg;
        //     //   });
        //   }
        // } else {
        //   alert("Invalid Employee Email Id");
        // }
      } else {
        alert("Please Enter Valid Email Id");
      }
    },
    clearTaxTypeAndTaxAmount(item) {
      if (item) {
        item.tax_rate_type = null;
        item.tax_amount = null;
      }
    },
    isRoundApplicable(roundValue) {
      const difAmount = this.invoiceHeader.dif_amount;
      if (difAmount && difAmount >= -1 && difAmount <= 1) {
        if (roundValue) {
          this.invoiceWithoutPoData.push({
            vendor_inv_dtl_id: this.roundOffId ? this.roundOffId : 0,
            item_description: "Round-off",
            po_price: this.invoiceHeader.dif_amount,
            deliver_qty: 1,
            total_price: this.invoiceHeader.dif_amount,
            hsn_sac: this.invoiceWithoutPoData[0].hsn_sac,
            tax_details: null,
            tax_amount: 0,
          });
        } else {
          const index = this.invoiceWithoutPoData.findIndex(
            (item) => item.item_description === "Round-off"
          );
          if (index !== -1) {
            this.roundOffId = this.invoiceWithoutPoData[
              index
            ].vendor_inv_dtl_id;
            this.invoiceWithoutPoData.splice(index, 1);
          }
        }
        this.calculateInvoiceAmountWithoutPo();
      } else {
        alert("Diff Amount for Round-Off +1 / -1");
        // const isRoundOffAdded = this.invoiceWithoutPoData.find(
        //   (ele) => ele.item_description === "Round-off"
        // );
        const isRoundOffAdded = this.invoiceWithoutPoData.some(
          (item) =>
            item.item_description && item.item_description.includes("Round-off")
        );
        if (isRoundOffAdded) {
          this.invoiceHeader.round_off = true;
        } else {
          this.invoiceHeader.round_off = false;
        }
      }
    },
    isGstAvail(gst_flag) {
      if (gst_flag) {
        this.invoiceHeader.vendor_gstn_name = "GSTNOTAVAILABLE";
      } else {
        this.invoiceHeader.vendor_gstn_name = null;
      }
    },
    calculateUnitPrice(item) {
      if (item.deliver_qty) {
        item.po_price = item.total_price / item.deliver_qty;
        item.po_price = parseFloat(item.po_price).toFixed(2);
        this.calculateTotalAmount(item, "price");
      } else {
        item.deliver_qty = 1;
      }
    },
    // Invoice Without PO Function Ending
    //
    // Common Function For Both Invoice Starting
    validateDocument() {
      this.currentPage = 1;
      this.perPage = commonHelper.perPageRecord;
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.menuId,
        sub_menu_id: this.subMenuId,
        record_id: this.venInvoiceId,
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const result = response.data.data.page;
            if (result.length >= 2) {
              this.addSubmissionDate();
            } else {
              alert(
                "Please Upload the Documents:-\nItems Supply :- Need to upload Invoice Copy & Proof Of Delivery (POD)\nService Items :- Invoice Copy and Work Completed Certificate (WCC)"
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideDocumentModal(flag) {
      this.showDocumentModal = flag;
    },
    getOpenKmAuthenticate() {
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.showHideDocumentModal(true);
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    sendWorkflowProcess() {
      const payload = {
        event_id: 34,
        record_id: this.venInvoiceId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/sendWorkflowProcess", payload)
        .then((resp) => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.approvalDetails = resp.data.data;
            this.sendWorkflowApproval();
          } else {
            alert(resp.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
          alert(resp.data.message);
        });
    },
    sendWorkflowApproval() {
      const payload = {
        event_id: 34,
        record_id: this.venInvoiceId,
        approval_details: this.approvalDetails,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/sendWorkflowApproval", payload)
        .then((resp) => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isApprovalSent = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            alert("Approval Sent Successfully.");
            if (this.radio) {
              this.getVendorInvoiceDetailsWithoutPo(this.venInvoiceId);
            } else {
              this.testingFunction("invoiceRowDtls", this.venInvoiceId);
            }
          } else {
            alert(resp.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    upperCaseFunction(invNum, type) {
      if (invNum) {
        if (type === "with_po") {
          this.poHeaderDetails.inv_number = invNum.toUpperCase();
        } else if (type === "without_po") {
          this.invoiceHeader.inv_number = invNum.toUpperCase();
        }
      }
    },
    isInvNumValid(invNumber, type) {
      if (invNumber) {
        if (commonHelper.containsSpecialCharacters(invNumber)) {
          if (type === "with_po") {
            this.poHeaderDetails.inv_number = null;
          } else if (type === "without_po") {
            this.invoiceHeader.inv_number = null;
          }
          alert(
            "Only / - are allowed as Special Characters in Invoice Number\nSpace not allowed in Invoice Number"
          );
        }
      }
    },
    invoiceNumberValidation(type) {
      const payload = {
        vendor_id: this.vendorId,
        invoice_number:
          type === "with_po"
            ? this.poHeaderDetails.inv_number
            : type === "without_po"
            ? this.invoiceHeader.inv_number
            : null,
        invoice_date:
          type === "with_po"
            ? this.poHeaderDetails.inv_date
            : type === "without_po"
            ? this.invoiceHeader.inv_date
            : null,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/invoiceNumberValidation", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result == "false") {
              if (type === "with_po") {
                this.isInvDuplicateOracle(type);
                // this.addEditVendorInvoiceDetails();
              } else if (type === "without_po") {
                this.isInvDuplicateOracle(type);
                // this.saveNewFunction();
              }
            } else {
              alert("Invoice Number already exist");
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    isInvDuplicateOracle(type) {
      const payload = {
        vendor_id: this.vendorId,
        inv_number:
          type === "with_po"
            ? this.poHeaderDetails.inv_number
            : type === "without_po"
            ? this.invoiceHeader.inv_number
            : null,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/isInvDuplicateOracle", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            const isInvExist = response.data.data.data;
            if (!isInvExist) {
              if (type === "with_po") {
                this.addEditVendorInvoiceDetails();
              } else if (type === "without_po") {
                this.saveNewFunction();
              }
            } else if (isInvExist) {
              alert("Invoice Number Already Exist in Oracle.");
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideEmailModal(flag) {
      this.showEmailModal = flag;
    },
    validateAmountWithoutPo(ratio, index) {
      this.invoiceWithoutPoData[index].qty_amt = "A";
      if (ratio) {
        if (ratio.charAt(0) === ".") {
          this.invoiceWithoutPoData[index].total_price = "0.";
        } else {
          var regex = /^\d+(\.\d{0,2})?$/g;
          if (!regex.test(ratio)) {
            alert("Only two decimal places are allowed.");
            const [integerPart, decimalPart] = ratio.split(".");
            const truncatedDecimal = parseFloat(
              `${integerPart}.${decimalPart.slice(0, 2)}`
            );
            setTimeout(() => {
              this.invoiceWithoutPoData[index].total_price = truncatedDecimal;
            }, 0);
          }
        }
      }
    },
    validateDeliveryQty(ratio, index, item) {
      if (item) {
        item.tax_amount = null;
        item.tax_details = null;
        item.tax_dtls = null;
      }
      if (ratio) {
        // var regex = /^\d+(\.\d{0,4})?$/g;
        var regex = /^-?\d+(\.\d{0,4})?$/;
        if (!regex.test(ratio)) {
          alert("Only four decimal places are allowed.");
          const [integerPart, decimalPart] = ratio.split(".");
          const truncatedDecimal = parseFloat(
            `${integerPart}.${decimalPart.slice(0, 4)}`
          );
          setTimeout(() => {
            this.invoiceWithoutPoData[index].po_price = truncatedDecimal;
          }, 0);
        }
      }
    },
    validateFunction(ratio, index, type, item) {
      if (type === "with_po") {
        this.poLineDetailsData[index].qty_amt = "Q";
      } else if (type === "without_po") {
        this.invoiceWithoutPoData[index].qty_amt = "Q";
      }
      if (item) {
        item.tax_amount = null;
      }
      if (ratio) {
        if (ratio.charAt(0) === ".") {
          if (type === "with_po") {
            this.poLineDetailsData[index].deliver_qty = "0.";
          } else if (type === "without_po") {
            this.invoiceWithoutPoData[index].deliver_qty = "0.";
          }
        } else {
          var regex = /^\d+(\.\d{0,10})?$/g;
          if (!regex.test(ratio)) {
            alert("Only ten decimal places are allowed.");
            const [integerPart, decimalPart] = ratio.split(".");
            const truncatedDecimal = parseFloat(
              `${integerPart}.${decimalPart.slice(0, 10)}`
            );
            setTimeout(() => {
              if (type === "with_po") {
                this.poLineDetailsData[index].deliver_qty = truncatedDecimal;
              } else if (type === "without_po") {
                this.invoiceWithoutPoData[index].deliver_qty = truncatedDecimal;
              }
            }, 0);
          }
        }
      }
    },
    validateFunctionAmount(ratio, index, type, item) {
      if (type === "amount") {
        this.poLineDetailsData[index].qty_amt = "A";
      }
      if (item) {
        item.tax_amount = null;
      }
      if (ratio) {
        var regex = /^\d+(\.\d{0,2})?$/g;
        if (!regex.test(ratio)) {
          alert("Only two decimal places are allowed.");
          const [integerPart, decimalPart] = ratio.split(".");
          const truncatedDecimal = parseFloat(
            `${integerPart}.${decimalPart.slice(0, 2)}`
          );
          setTimeout(() => {
            if (type === "amount") {
              this.poLineDetailsData[index].amount = truncatedDecimal;
            } else if (type === "taxable_amount") {
              this.taxDetailsData[index].taxable_amount = truncatedDecimal;
            } else if (type === "taxable_amt") {
              this.taxWithoutPoData[index].taxable_amount = truncatedDecimal;
            }
          }, 0);
        }
      }
    },
    rowSelectedGrid(item) {
      if (item.po_num) {
        this.showViewInvoiceForm(true);
        this.poHeaderDetails.po_number = item.po_num;
        this.testingFunction("invoiceRowDtls", item.vendor_inv_id);
      } else if (!item.po_num) {
        this.radio = true;
        this.showViewInvoiceForm(true);
        setTimeout(() => {
          this.getVendorInvoiceDetailsWithoutPo(item.vendor_inv_id);
        }, 0);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      if (this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_TCS_TYPE) {
        this.taxDetailsData[index].taxable_amount = null;
        this.taxDetailsData[index].tax_amt = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.searchFilters.status.text = item.value_meaning;
        this.searchFilters.status.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_TCS_TYPE) {
        this.taxDetailsData[this.specIndex].tax_category_name =
          item.value_meaning;
        this.taxDetailsData[this.specIndex].tax_category_id = item.value_code;
        if (item.value_code == 0) {
          const finalTotalAmt = +this.lineAmount;
          this.taxDetailsData[this.specIndex].taxable_amount = finalTotalAmt;
        } else if (item.value_code == 1) {
          const filterTaxAmt = this.taxDetailsData
            .filter((ele) => {
              if (
                ele.tax_type !== "ADHOC_AMOUNT" &&
                ele.tax_rate_type !== "ADHOC_AMOUNT"
              ) {
                return ele.tax_amt;
              }
            })
            .map((data) => data.tax_amt);
          const totalTaxAmount = filterTaxAmt.length
            ? commonHelper.calculateTotal(filterTaxAmt)
            : 0;
          const finalTotalAmt = totalTaxAmount + +this.lineAmount;
          this.taxDetailsData[this.specIndex].taxable_amount = finalTotalAmt;
        } else if (item.value_code == 2) {
          const filterTaxableAmt = this.taxDetailsData
            .filter((ele) => {
              if (
                ele.tax_type !== "CGST" &&
                ele.tax_type !== "SGST" &&
                ele.tax_type !== "UGST" &&
                ele.tax_type !== "IGST" &&
                ele.tax_rate_type !== "TCS"
              ) {
                return ele.taxable_amount;
              }
            })
            .map((data) => data.taxable_amount);
          const totalTaxableAmt = filterTaxableAmt.length
            ? commonHelper.calculateTotal(filterTaxableAmt)
            : 0;

          const filterTaxAmt = this.taxDetailsData
            .filter((ele) => ele.tax_amt)
            .map((data) => data.tax_amt);
          const totalTaxAmount = filterTaxAmt.length
            ? commonHelper.calculateTotal(filterTaxAmt)
            : 0;

          const finalTotalAmt =
            +totalTaxableAmt + totalTaxAmount + +this.lineAmount;
          this.taxDetailsData[this.specIndex].taxable_amount = finalTotalAmt;
        }
        this.taxDetailsData[this.specIndex].tax_amt =
          (this.taxDetailsData[this.specIndex].taxable_amount *
            this.taxDetailsData[this.specIndex].tax_rate_percentage) /
          100;
        this.taxDetailsData[this.specIndex].tax_amt = parseFloat(
          this.taxDetailsData[this.specIndex].tax_amt
        ).toFixed(2);
        this.taxDetailsData = JSON.parse(JSON.stringify(this.taxDetailsData));
      }
      // else if (this.vsetCode === appStrings.VALUESETTYPE.EMPL_INV_DEL) {
      //   this.invoiceHeader.manual_employee_name = item.value_code;
      //   this.invoiceHeader.manual_employee_id = item.value_set_dtl_id;
      // }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addSubmissionDate() {
      const payload = {
        vendor_inv_id: this.venInvoiceId,
        submission_date: commonHelper.formattedDate(new Date()),
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/addSubmissionDate", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.finalResult = result;
            this.sendWorkflowProcess();
          } else {
            alert(response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateHsnSac(item) {
      if (item.hsn_sac && item.hsn_sac.length < 6) {
        alert("HSN Code Should between 6 to 8 digits.");
        item.hsn_sac = null;
      }
    },
    scrapeInvoice() {
      const filterInvoice = this.invoiceDetailsData.filter((ele) => ele.select);
      if (!filterInvoice.length) {
        alert("Please Select Any Invoice");
      }
      const selectedInvoice = filterInvoice.map((data) => {
        return {
          vendor_inv_id: data.vendor_inv_id,
        };
      });
      const payload = {
        vendor_id: this.vendorId,
        scrap_invoices: selectedInvoice,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/scrapeInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.data) {
              alert("Invoice Scrap Successfully");
              this.getVendorInvoiceDetails();
            } else {
              alert("Something went wrong. Please try again later.");
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkUncheckAll() {
      this.invoiceDetailsData = this.invoiceDetailsData.map((data) => {
        data.select =
          (data.approval_status === "REJECTED" ||
            data.approval_status === "DRAFT") &&
          this.selectAllCheckBox;
        return data;
      });
    },
    deleteNewInvoiceWithPo(filterRow, unfilterRow) {
      let deletedRows = [];
      for (let i of filterRow) {
        deletedRows.push({
          vendor_inv_dtl_id: i.vendor_inv_dtl_id,
        });
      }
      const payload = {
        type: this.radio ? "with_po" : "without_po",
        vendor_inv_id: this.venInvoiceId,
        vendor_invoice_dtls: deletedRows,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/deleteNewInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            alert("Deleted Successfully");
            this.showRemoveModal = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTaxRemoveModal(flag) {
      this.showRemoveModal = flag;
    },
    removeNewDetails(value) {
      if (value === "No") {
        this.showRemoveModal = false;
      } else {
        const filterRow = this.newInvoiceDetailsData.filter(
          (data) => data.select
        );
        const unfilterRow = this.newInvoiceDetailsData.filter(
          (data) => !data.select
        );
        if (filterRow.length) {
          this.newInvoiceDetailsData = unfilterRow;
          const tempData = filterRow.map((elem) => {
            elem.select = false;
            return elem;
          });
          this.poLineDetailsData.push(...tempData);
          this.deleteNewInvoiceWithPo(filterRow, unfilterRow);
        } else {
          alert("Please Select Invoice");
        }
      }
    },
    deleteNewInvoiceWithoutPo(item) {
      let deletedRows = [];
        deletedRows.push({
          vendor_inv_dtl_id: item.vendor_inv_dtl_id,
        });
      const payload = {
        type: this.radio ? "with_po" : "without_po",
        vendor_inv_id: this.venInvoiceId,
        vendor_invoice_dtls: deletedRows,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/deleteNewInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            alert("Deleted Successfully");
            this.showRemoveModal = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // Common Function For Both Invoice Ending
  },
};
</script>
<style>
.item-col > div {
  width: 40px !important;
  margin-left: 5px !important;
}
.width-fix > div {
  width: 85px !important;
}
.tax-amt-fix > div {
  width: 105px !important;
}
.tax-width-fix > div {
  width: 60px !important;
}
.tax-cat-fix > div {
  width: 300px !important;
}
.poItem-col > div {
  margin-left: 2%;
}
.font-fix {
  font-size: 14px !important;
}
.width-fix-des > div {
  width: 250px !important;
}
.width-fix-qty > div {
  width: 170px !important;
}
.text-fix-invamt {
  margin-right: 25% !important;
  text-align: right !important;
}
.pagination-count1 {
  margin-top: -4%;
  font-size: 0.9rem;
}
.align-item-text {
  text-align: left !important;
}
</style>
